
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './SolvedCaseTab.scss';
import { FiRefreshCw } from 'react-icons/fi';
import { taptalk } from '@taptalk.io/web-sdk';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
// import HelperChat from '../../../../helper/HelperChat';
import HelperDate from '../../../../helper/HelperDate';
import { doToast, generateElipsis, getItemFromArray } from '../../../../helper/HelperGeneral';
import RoomListNoChat from '../../roomListNoChat/RoomListNoChat';
import { connect } from 'react-redux';
import { clearUpdateCaseLabel } from "../../../../redux/actions/reduxActionCaseLabel";
import { clearChangeContactInfo } from "../../../../redux/actions/reduxActionChangeUserAlias";
import { clearStartConversation } from '../../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../../redux/actions/reduxActionStartConversationBa';
import { setCaseListChecked } from "../../../../redux/actions/reduxActionCaseListChecked";
import { setReconnectRoomList } from "../../../../redux/actions/reduxActionReconnectRoomList";
// import { Scrollbars } from 'react-custom-scrollbars';
import { FiRotateCcw, FiCalendar, FiSearch } from 'react-icons/fi';
import CaseService from "../../../../services/chat/caseServices";
// import NewSelect from "../../../reuseableComponent/NewSelect/NewSelect";
// import OtherAgentIcon from "../../../../assets/img/roomlist/icon-other-agent.svg";
import FilterBy from "../modalFilter/filterBy/FilterBy";
import { VariableSizeList as List } from 'react-window';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CaseListComponent from "../caseListComponent/CaseListComponent";
import CaseListAction from "../../roomListTab/caseListAction/CaseListAction";
import iconFilterWhite from "../../../../assets/img/icon-filter-inbox-white.svg";
import iconFilterGrey from "../../../../assets/img/icon-filter-inbox-grey.svg";
import mixpanel from "mixpanel-browser";
import { CASE_TYPES } from '../../../../constants/inbox';
import StartConversation from "../startConversation/StartConversation";

// const SORT_MESSAGE_BY = {
//     LAST_MESSAGE: "last_message",
//     CREATED_TIME: "created_time"
// }

const DEFAULT_CELL_HEIGHT = 92;

const CONNECTING_STATUS = {
    disconnected: 1,
    loading: 2,
    connected: 3
};

const FILTER_MESSAGE = {
    date: "There are no cases for the selected dates. Please select different dates.",
    filter: "There are no cases for the selected filters. Please select different filter options.",
    all: "There are no cases for the selected filters and dates. Please select different filter options and dates."
}

const INITIAL_SOLVED_CASE_PARAM = (reconnect = false) => ({
    topicID: 0,
    agentAccountID: 0,
    search: '',
    startDate: '',
    endDate: '',
    lastID: 0,
    isSearchOrFilter: false,
    isReconnect: reconnect,
    isLoadMore: false,
    label: [],
    junkStatus: ""
});

// var style = {
//     scrollStyle: {
//       position: "relative",
//       backgroundColor: "#ff7d00",
//       right: "-5px",
//       width: "3px",
//       borderRadius: "8px"
//     }
// };

const MESSAGE_TYPE = {
    CASE_CREATED: 3005,
    CASE_CLOSED: 3001,
    CASE_REOPEN: 3002,
    CASE_RATING_BUTTON: 3003,
    CASE_RATING_BUTTON_DISABLED: 3004,
    CASE_UPDATE_AGENT_CHANGE: 3006,
    CASE_UPDATE_DETAIL_UPDATE: 3007,
}

const DEFAULT_FILTER_TOPIC_AGENT = {
    value: 0,
    label: ""
}

// const PREFIX_CASE = 'case#';

let ListResolvedCaseComponent = (props) => {
    let { caseLabel, dataLength, containerHeight, row,
        mainProps, fetchingSolvedCaseIsErrorProps, fetchingSolvedCaseProps,
        onClickRetryMoreCaseProps, onScrollRoomListListenerProps, refProps } = props;

    let arrayOfRowHeight = [];

    let generateRowHeight = () => {
        arrayOfRowHeight = [];

        Object.keys(props.case).map((value, index) => {
            if (props.case[value].labelIDs && props.case[value].labelIDs.length > 0 && caseLabel.length > 0) {
                arrayOfRowHeight.push(128);
            } else {
                arrayOfRowHeight.push(101);
            }

            return null;
        })
    }

    generateRowHeight();

    const getItemSize = (index) => {
        return arrayOfRowHeight[index];
    }

    let arrayOfLabel = []
    const generateLabelCase = () => {
        Object.keys(props.case).map((value, index) => {
            if (props.case[value].labelIDs && caseLabel) {
                if (props.case[value].labelIDs.length > 0) {
                    if (caseLabel.length > 0) {
                        const labels = caseLabel.filter((el) => {
                            return props.case[value].labelIDs.find(val => val === el.id)
                        });

                        const labelCase = [...labels]
                        // console.log('labelCase', labelCase)
                        arrayOfLabel.push([...Array(labelCase.length).fill(false)])
                        // arrayOfLabel.push(labelCase)
                        // setHiddenUnassigned(labelCase)
                    }
                }
            }
            return null
        })
    }

    generateLabelCase()

    return (
        <List
            className="List all-case-list list-resolved-case last-case-no-margin-bottom"
            height={containerHeight}
            itemCount={dataLength}
            itemSize={getItemSize}
            width={"100%"}
            onScroll={onScrollRoomListListenerProps}
            ref={refProps}
            itemData={{
                caseData: props.case,
                caseLabel: caseLabel,
                labelHidden: arrayOfLabel,
                mainProps: mainProps,
                _fetchingSolvedCaseIsErrorProps: fetchingSolvedCaseIsErrorProps,
                _fetchingSolvedCaseProps: fetchingSolvedCaseProps,
                _onClickRetryMoreCaseProps: onClickRetryMoreCaseProps,
                _refProps: refProps
            }}
        >
            {row}
        </List>
    )
};

const getLabelCase = (labelIDs, labelList) => {
    if (labelIDs && labelList) {
        if (labelIDs.length > 0) {
            if (labelList.length > 0) {
                let newLabel = [];

                labelIDs.map((v) => {
                    if (getItemFromArray(labelList, "id", v)) {
                        newLabel.push(getItemFromArray(labelList, "id", v))
                    } else {
                        newLabel.push({ name: "", id: "" })
                    }
                    return null;
                })

                const labelCase = [...newLabel];
                return labelCase;
            }
        }
    }
    return []
}

const LabelItem = ({ label, container, index, isItemHidden, setIsItemHidden }) => {
    const itemRef = useRef();

    // useEffect(() => {
    //     if (!container.current) return;

    //     const { offsetLeft, offsetWidth } = itemRef.current;

    //     if (offsetLeft + offsetWidth > container.current.offsetWidth) {

    //         const copyIsItemHidden = [...isItemHidden];
    //         copyIsItemHidden[index] = true;
    //         setIsItemHidden(copyIsItemHidden);
    //     }
    // }, [index, container, isItemHidden, setIsItemHidden]);

    return (
        <div key={`label-${label.id}`} className="case-tab-label-content" style={{ background: label.backgroundColor, display: isItemHidden[index] ? "none" : "inline-block" }} ref={itemRef} >
            <b>{generateElipsis(label.name, 13)}</b>
        </div>
    );
};

let PrintRowResolvedCaseList = ({ index, style, data }) => {
    let myAgentAccountID = data.mainProps.myAgentData.account.id;
    let caseDetail = data.caseData[Object.keys(data.caseData)[index]];
    let message = data.caseData[Object.keys(data.caseData)[index]].tapTalkRoom;
    let _props = data.mainProps;

    let onClickSolvedCaseRoom = (room, caseData) => {
        _props.clearStartConversation();
        _props.clearStartConversationBa();
        _props.onClickRoomListProps(room, caseData)
    }

    const containerRef = useRef()

    const labelCase = getLabelCase(caseDetail.labelIDs, data.caseLabel)

    const hidden = data.labelHidden[index] ? data.labelHidden[index] : []

    const [isItemHidden, setIsItemHidden] = useState([...hidden])

    // useEffect(() => {
    //     setIsItemHidden(hidden)
    // }, [data.labelHidden])

    // const totalHidden = () => {
    //     let totalHiddenItem = 0;
    //     if (isItemHidden) {
    //         for (let hideItem of isItemHidden) {
    //             if (hideItem === true) totalHiddenItem++;
    //         }
    //     }
    //     return totalHiddenItem;
    // };

    return (
        <div style={style}>
            <CaseListComponent
                caseDetail={caseDetail}
                roomType="solved"
                message={message}
                myAgentAccountID={myAgentAccountID}
                labelCase={labelCase}
                // totalHidden={totalHidden()}
                _containerRef={containerRef}
                LabelItem={LabelItem}
                isItemHidden={isItemHidden}
                setIsItemHidden={setIsItemHidden}
                onClickCase={onClickSolvedCaseRoom}
                checkedType="solved"
                allCase={data.caseData}
            />

            <React.Fragment>
                {data._fetchingSolvedCaseProps &&
                    <div className="fetch-solved-case">
                        <div className="fetch-solved-case-content">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>

                            <b>More Cases</b>
                        </div>
                    </div>
                }

                {data._fetchingSolvedCaseIsErrorProps &&
                    <div className="fetch-solved-case">
                        <div className="fetch-solved-case-content no-fill-button retry-fetch"
                            onClick={() => data._onClickRetryMoreCaseProps()}
                        >
                            <FiRefreshCw />

                            <b>Retry Fetch</b>
                        </div>
                    </div>
                }
            </React.Fragment>
        </div>
    )
}

var SolvedCaseTab = (props) => {
    let [showModalCalendar, setShowModalCalendar] = useState(false);
    let [showModalFilterTopicAgent, setShowModalFilterTopicAgent] = useState(false);

    let [isRoomListLoadingFinish, setIsRoomListLoadingFinish] = useState(false);
    let [chatDataFiltered, setChatDataFiltered] = useState({});

    // let [chatDataFiltered, setChatDataFiltered] = useState({});

    let [fetchingSolvedCase, setFetchingSolvedCase] = useState(false);
    let [fetchingSolvedCaseIsError, setFetchingSolvedCaseIsError] = useState(false);

    let [hasMoreSolvedCase, setHasMoreSolvedCase] = useState(false);

    // let solvedCaseRef = useRef("solvedCaseLists");

    let [filterDate, setFilterDate] = useState({
        from: undefined,
        to: undefined,
    });
    let [filterDateBefore, setFilterDateBefore] = useState({
        from: undefined,
        to: undefined,
    });

    //search and filter
    let [filterSearchValue, setFilterSearchValue] = useState('');
    let [filterTopicValue, setFilterTopicValue] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValue, setFilterAgentValue] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValue, setFilterMediumValue] = useState([]);
    let [filterCaseTypeValue, setFilterCaseTypeValue] = useState(CASE_TYPES.allTypes.value);

    let [filterTopicValueBefore, setFilterTopicValueBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValueBefore, setFilterAgentValueBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValueBefore, setFilterMediumValueBefore] = useState([]);
    let [filterCaseTypeValueBefore, setFilterCaseTypeValueBefore] = useState(CASE_TYPES.allTypes.value);

    let [filterDateValue, setFilterDateValue] = useState('');

    let [searchNotFound, setSearchNotFound] = useState(false);

    let [isLoadingSearchSolvedCaseFinish, setIsLoadingSearchSolvedCaseFinish] = useState(true);
    // let [isLoadingFilterSolvedCaseFinish, setIsLoadingFilterSolvedCaseFinish] = useState(true);

    let [isSearchActive, setIsSearchActive] = useState(false);
    let [isFilterTopicAgentChannelActive, setIsFilterTopicAgentChannelActive] = useState(false);
    let [isFilterDateActive, setIsFilterDateActive] = useState(false);

    let [topicList, setTopicList] = useState([]);
    // let [agentList, setAgentList] = useState(null);
    //search and filter

    let [panelResolvedCaseHeight, setPanelResolvedCaseHeight] = useState(0);
    let [isResetFilter, setIsResetFilter] = useState(false);

    // const [caseLabelList, setCaseLabelList] = useState([])
    // const [isLoadingGetLabelList, setIsLoadingGetLabelList] = useState(false)

    let [filterLabelValue, setFilterLabelValue] = useState([]);
    let [filterLabelValueBefore, setFilterLabelValueBefore] = useState([]);
    let [isShowModalStartConversation, setIsShowModalStartConversation] = useState(false);

    let toggleModalStartConversation = (show) => {
        setIsShowModalStartConversation(show);
    }

    let actionSetPanelCaseResolvedHeight = (activeSelect) => {
        let elSolvedCaseTabWrapper = document.querySelectorAll(".solved-case-tab-wrapper")[0];

        if (elSolvedCaseTabWrapper) {
            let defaultHeight = 0;

            if (activeSelect) {
                defaultHeight = elSolvedCaseTabWrapper.offsetHeight - 60;
            } else {
                defaultHeight = elSolvedCaseTabWrapper.offsetHeight - 34;
            }

            setPanelResolvedCaseHeight(defaultHeight);
        }
    }

    //reconnect
    useEffect(() => {
        let _reconRoomList = props.reconnectRoomList;

        if (_reconRoomList && _reconRoomList > 0) {
            getSolvedCaseList(INITIAL_SOLVED_CASE_PARAM(true));

            props.setReconnectRoomList(0);
        }
    }, [props.reconnectRoomList])
    //reconnect

    useEffect(() => {
        let _changeContactInfo = props.changeContactInfo;
        let _chatDataFiltered = { ...chatDataFiltered };

        if (_changeContactInfo.state) {
            let changeChatFilteredSolved = () => {
                Object.keys(_chatDataFiltered).map((value, index) => {
                    if (_chatDataFiltered[value].userID === _changeContactInfo.userID) {
                        _chatDataFiltered[value].userAlias = _changeContactInfo.alias;
                        _chatDataFiltered[value].userFullName = _changeContactInfo.fullname;
                        _chatDataFiltered[value].userPhone = _changeContactInfo.phone;
                        _chatDataFiltered[value].userEmail = _changeContactInfo.email;
                    }

                    return null;
                })

                setChatDataFiltered(_chatDataFiltered);
            }

            changeChatFilteredSolved();
            props.clearChangeContactInfo();
        }
    }, [props.changeContactInfo])

    useEffect(() => {
        let isRunUpdateCaseLabelProps = props.updateCaseLabel;
        let _chatDataFiltered = { ...chatDataFiltered };

        if (isRunUpdateCaseLabelProps.state) {
            const roomID = isRunUpdateCaseLabelProps.roomID.roomID;

            if (_chatDataFiltered[roomID]) {
                if (_chatDataFiltered[roomID].id === isRunUpdateCaseLabelProps.id) {
                    if (isRunUpdateCaseLabelProps.action === 'add') {
                        _chatDataFiltered[roomID].labelIDs = [..._chatDataFiltered[roomID].labelIDs, ...isRunUpdateCaseLabelProps.labelIDs];
                    } else if (isRunUpdateCaseLabelProps.action === 'remove') {
                        const removeLabelIDs = [...isRunUpdateCaseLabelProps.labelIDs]
                        const currentLabelIDs = [..._chatDataFiltered[roomID].labelIDs]
                        const newLabelIDs = currentLabelIDs.filter(labelID => {
                            return !removeLabelIDs.find(val => val === labelID)
                        })
                        _chatDataFiltered[roomID].labelIDs = [...newLabelIDs]
                    }
                }
                setChatDataFiltered(_chatDataFiltered);
            }
            props.clearUpdateCaseLabel();
        }

    }, [props.updateCaseLabel])

    let solvedCaseReff = useCallback(node => {
        if (node !== null) {
            node.resetAfterIndex(0);
        }
    }, [chatDataFiltered]);

    useEffect(() => {
        let _connectStatus = props.connectingStatusProps;
        let elSolvedCaseTabWrapper = document.querySelectorAll(".solved-case-tab-wrapper")[0];

        if (elSolvedCaseTabWrapper) {
            if (_connectStatus === CONNECTING_STATUS.disconnected || _connectStatus === CONNECTING_STATUS.loading) {
                // elSolvedCaseTabWrapper.style.height = "calc(100vh - 135px)";
                elSolvedCaseTabWrapper.style.height = "calc(100vh - 79px)";
            } else {
                // elSolvedCaseTabWrapper.style.height = "calc(100vh - 109px)";
                elSolvedCaseTabWrapper.style.height = "calc(100vh - 53px)";
            }

            actionSetPanelCaseResolvedHeight(props.caseListChecked["solved"].isShow);
        }
    }, [props.connectingStatusProps])

    useEffect(() => {
        window.addEventListener("resize", () => {
            actionSetPanelCaseResolvedHeight(props.caseListChecked["solved"].isShow);
        })

        actionSetPanelCaseResolvedHeight(props.caseListChecked["solved"].isShow);
    }, [])

    let resetFilterAndSearchValue = () => {
        setIsResetFilter(true);
        // document.getElementById("input-search-form").reset();

        setFilterSearchValue("");
        setIsLoadingSearchSolvedCaseFinish(true);

        //search
        setSearchNotFound(false);
        setFilterSearchValue('');
        setIsSearchActive(false);
        //search

        //fitler topic and agent
        setFilterTopicValue(DEFAULT_FILTER_TOPIC_AGENT);
        setFilterAgentValue(DEFAULT_FILTER_TOPIC_AGENT);
        setFilterTopicValueBefore(DEFAULT_FILTER_TOPIC_AGENT);
        setFilterAgentValueBefore(DEFAULT_FILTER_TOPIC_AGENT);
        setIsFilterTopicAgentChannelActive(false);
        //fitler topic and agent

        //filter date
        setFilterDateValue('');
        setIsFilterDateActive(false);

        setFilterDate({
            from: undefined,
            to: undefined,
        });
        setFilterDateBefore({
            from: undefined,
            to: undefined,
        });
        //filter date

        //medium
        setFilterMediumValue([]);
        setFilterMediumValueBefore([]);
        //medium

        //label
        setFilterLabelValue([])
        setFilterLabelValueBefore([])
        //label

        //case type
        setFilterCaseTypeValue(CASE_TYPES.allTypes.value)
        setFilterCaseTypeValueBefore(CASE_TYPES.allTypes.value)
        //case type

        // getAgentList(0);
        getSolvedCaseList(INITIAL_SOLVED_CASE_PARAM(false));
    }

    let toggleResetFilter = () => {
        setIsResetFilter(false);
    }

    let onSelectTopic = (value) => {
        setFilterTopicValue(value)
    }

    let onSelectAgent = (value) => {
        setFilterAgentValue(value)
    }

    let onSelectMedium = (value) => {
        setFilterMediumValue(value);
    }

    const onSelectLabel = (value) => {
        setFilterLabelValue(value)
    }

    let onSelectCaseType = (value) => {
        setFilterCaseTypeValue(value);
    }

    // let getUpdatedRoomList = (callback = null) => {
    //     tapCoreRoomListManager.getUpdatedRoomList({
    //         onSuccess: (roomLists) => {
    //             props.setMainChatDataActionProps(roomLists);

    //             callback !== null && callback.onSuccessGetRoomList(roomLists);
    //         },
    //         onError: (errorCode, errorMessage) => {
    //             console.log(errorCode, errorMessage);
    //         }
    //     });
    // };

    let actionUpdateRoomList = (message, isUpdate = false) => {
        let _chatDataFiltered = { ...chatDataFiltered };

        if (message && message.room && message.room.roomID) {
            let caseList = _chatDataFiltered[message.room.roomID];
            if (caseList && caseList.updatedTime && caseList.updatedTime > message.created) {
                return;
            }
        }
        // let actionRemoveFromRoomList = () => {
        //     let _data = {...chatDataFiltered};

        //     if(_data[PREFIX_CASE + message.data.id]) {
        //         delete _data[PREFIX_CASE + message.data.id];

        //         if(Object.keys(_data).length === 0) {
        //             setChatDataFiltered({});
        //         }else {
        //             setChatDataFiltered(_data);
        //         }
        //     }
        // }

        if (Object.keys(_chatDataFiltered).length > 0) {
            if ((message.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE || message.type === MESSAGE_TYPE.CASE_UPDATE_DETAIL_UPDATE) && _chatDataFiltered[message.room.xcRoomID]) {
                let currentTaptalkRoom = { ..._chatDataFiltered }[message.room.xcRoomID].tapTalkRoom;
                
                _chatDataFiltered[message.room.xcRoomID] = message.data;

                if(currentTaptalkRoom) {
                    if (!message.isHidden) {
                        _chatDataFiltered[message.room.xcRoomID].tapTalkRoom = {};
                        _chatDataFiltered[message.room.xcRoomID].tapTalkRoom.lastMessage = message;
                        _chatDataFiltered[message.room.xcRoomID].tapTalkRoom.unreadCount = currentTaptalkRoom.unreadCount;
                    } else {
                        _chatDataFiltered[message.room.xcRoomID].tapTalkRoom = currentTaptalkRoom;
                    }
                }

                setChatDataFiltered(_chatDataFiltered);
            }
        }
    }

    useEffect(() => {
        // let onlineCallbackSolvedCase = () => {
        // getUpdatedRoomList({
        //     onSuccessGetRoomList: () => {
        //         getSolvedCaseList({
        //             topicID: 0,
        //             agentAccountID: 0,
        //             search: '',
        //             startDate: '',
        //             endDate: '',
        //             lastID: 0,
        //             isSearchOrFilter: false,
        //             isReconnect: true,
        //             isLoadMore: false
        //         });
        //     }
        // })
        // }

        let _topiclist = [];

        // window.addEventListener('online', onlineCallbackSolvedCase);

        // getUpdatedRoomList({
        //     onSuccessGetRoomList: () => {
        //         getSolvedCaseList(INITIAL_SOLVED_CASE_PARAM(false));
        //     }
        // })

        if (props.isFetchingRoomListFinishProps) {
            getSolvedCaseList(INITIAL_SOLVED_CASE_PARAM(false));
        }

        //set topic list
        Object.keys(props.topicListProps).map(value => {
            _topiclist.push(props.topicListProps[value]);
            return null;
        });

        setTopicList(_topiclist);
        //set topic list

        // setFilterTopicValue(props.topicListProps.length === 0 ? null : props.topicListProps[0]);

        // getAgentList(0);

        // return () => {
        //     window.removeEventListener('online', onlineCallbackSolvedCase);
        // }
    }, [props.isFetchingRoomListFinishProps])

    // useEffect(() => {
    //     if(props.newEmitMessageProps !== null) {
    //         if(isRoomListLoadingFinish) {
    //             actionUpdateRoomList(props.newEmitMessageProps);
    //         }
    //     }
    // }, [props.newEmitMessageProps])

    useEffect(() => {
        if (props.messageListenerNewMessageProps !== null) {
            let newMessage = props.messageListenerNewMessageProps;
            // console.log('new', newMessage);

            if (isRoomListLoadingFinish) {
                actionUpdateRoomList(newMessage);
            }
        }
    }, [props.messageListenerNewMessageProps]);

    useEffect(() => {
        if (props.messageListenerUpdateMessageProps !== null) {
            let updateMessage = props.messageListenerUpdateMessageProps;
            // console.log('updet', updateMessage);

            if (isRoomListLoadingFinish) {
                actionUpdateRoomList(updateMessage, true);
            }
        }
    }, [props.messageListenerUpdateMessageProps])

    // useEffect(() => {
    //     if(Object.keys(chatDataFiltered).length !== 0) {
    //         setupFilteredRoomList(chatDataFiltered, 'xcRoomID');
    //     }else {
    //         setChatDataFiltered({});
    //     }
    // }, [chatDataFiltered])

    //setting up new room list
    // let setupFilteredRoomList = (data, filterBaseOn) => {
    //     let _arrayOfMessage = {};
    //     let _arrayOfMessageIsNull = false;

    //     Object.keys(data).map((value) => {
    //         // for(let j in chatData) {
    //         for(let j in props.mainChatDataProps) {
    //             if(filterBaseOn === 'xcRoomID') {
    //                 if(data[value].tapTalkXCRoomID === props.mainChatDataProps[j].lastMessage.room.xcRoomID) {
    //                     if(!_arrayOfMessageIsNull) {
    //                         _arrayOfMessageIsNull = true;
    //                         _arrayOfMessage = {};
    //                     }

    //                     props.mainChatDataProps[j].caseDetail = data[value];
    //                     _arrayOfMessage[props.mainChatDataProps[j].lastMessage.room.roomID] = props.mainChatDataProps[j];

    //                     break;
    //                 }
    //             }
    //         }
    //     })

    //     setChatDataFiltered(_arrayOfMessage);
    //     setIsRoomListLoadingFinish(true);
    //     setIsLoadingSearchSolvedCaseFinish(true);
    // }
    //setting up new room list

    let getSolvedCaseList = (data) => {
        if (!data.isReconnect) {
            if (data.lastID === 0 && !data.isSearchOrFilter) {
                setIsRoomListLoadingFinish(false);
            }
        }

        let dataLabel = [];
        if (data.label && data.label.length > 0) {
            dataLabel = data.label.map(item => item.id)
        }

        //   let today = new Date().valueOf();
        //   let last30Day = new Date().valueOf() - 2592000000;
        let _data = {
            "topicID": data.topicID,
            "agentAccountID": data.agentAccountID,
            "search": data.search,
            "startDate": data.startDate,
            "endDate": data.endDate,
            "lastID": data.lastID,
            // if page = 1, lastID = 0
            "pageSize": 50,
            "mediums": data.mediums,
            "labelIDs": dataLabel,
            "junkStatus": data.junkStatus
        }

        CaseService.postSolvedCaseList(props.parentProps.match.params.orgID, _data, (response) => {
            setFetchingSolvedCase(false);

            if (response.dataResult.error.code === "") {
                if (data.isSearchOrFilter) {
                    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                            "[Action] Search",
                            {
                                tab: "Resolved",
                                userFullName: props.myAgentData.account.fullName,
                                userID: props.myAgentData.account.id,
                                organizationID: props.parentProps.match.params.orgID.split("-")[0]
                            }
                        )
                    }
                }

                let resultCase = response.dataResult.data.cases;
                let caseData = {};


                for (let i in resultCase) {
                    // let body = resultCase[i].tapTalkRoom.lastMessage.body;
                    // let localID = resultCase[i].tapTalkRoom.lastMessage.localID;
                    // let data = resultCase[i].tapTalkRoom.lastMessage.data;

                    // resultCase[i].tapTalkRoom.lastMessage.body = taptalk.generateBodyAndData(body, localID);

                    // if (data !== "") {
                    //     resultCase[i].tapTalkRoom.lastMessage.data = JSON.parse(taptalk.generateBodyAndData(data, localID));
                    // }

                    // caseData[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = resultCase[i];

                    caseData[resultCase[i].tapTalkXCRoomID] = resultCase[i];
                }

                setHasMoreSolvedCase(response.dataResult.data.hasMore);

                if (data.isSearchOrFilter) {
                    if (resultCase.length === 0) {
                        setSearchNotFound(true);
                    }

                    setIsLoadingSearchSolvedCaseFinish(true);
                    setChatDataFiltered(caseData);
                } else {
                    if (data.isLoadMore) {
                        let currentSolvedCaseList = { ...chatDataFiltered };

                        currentSolvedCaseList = Object.assign(currentSolvedCaseList, caseData);
                        setChatDataFiltered(currentSolvedCaseList);
                    } else {
                        setChatDataFiltered(caseData);
                    }

                    setIsRoomListLoadingFinish(true);
                }
            } else {
                setIsRoomListLoadingFinish(true);
                setIsLoadingSearchSolvedCaseFinish(true);
                doToast(response.dataResult.error.message, 'fail');
                if (data.isLoadMore) {
                    setFetchingSolvedCaseIsError(true);
                }
            }
        })
    }

    // let getAgentList = (topicID) => {
    //     let data = {
    //         topicID: topicID
    //     };

    //     CaseService.postAgentList(props.parentProps.match.params.orgID, data, (response) => {
    //         if (response.dataResult.error.code === "") {
    //             let dataResult = response.dataResult;

    //             for (let i in dataResult.data.agents) {
    //                 dataResult.data.agents[i].value = dataResult.data.agents[i].accountID;
    //                 dataResult.data.agents[i].label = dataResult.data.agents[i].accountName;
    //             }

    //             setAgentList(dataResult.data.agents);
    //         } else {
    //             doToast(response.dataResult.error.message, 'fail');
    //         }
    //     })
    // }

    let toggleModalCalendar = () => {
        //if close
        if (showModalCalendar) {
            if (filterDateBefore.from) {
                setFilterDateValue(filterDateBefore);
            }

            setFilterDate(filterDateBefore);

        }
        //if close

        setShowModalCalendar(!showModalCalendar);
    }

    // let toggleSelectCaseList = (type) => {
    //     let _caseSelect = {...props.caseListChecked};
    //     _caseSelect[type].isShow = !_caseSelect[type].isShow;
    //     _caseSelect[type].data = {};
    //     _caseSelect[type].checkedAll = false;

    //     actionSetPanelCaseResolvedHeight(_caseSelect[type].isShow);

    //     props.setCaseListChecked(_caseSelect);
    // }

    let toggleModalFilter = () => {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Button] Filter",
                {
                    userFullName: props.myAgentData.account.fullName,
                    userID: props.myAgentData.account.id,
                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                }
            );
        }

        //if close
        if (showModalFilterTopicAgent) {
            setFilterTopicValue(filterTopicValueBefore);
            setFilterAgentValue(filterAgentValueBefore);
            setFilterMediumValue(filterMediumValueBefore);
            setFilterLabelValue(filterLabelValueBefore);
            setFilterCaseTypeValue(filterCaseTypeValueBefore);
        }
        //if close

        setShowModalFilterTopicAgent(!showModalFilterTopicAgent);
    }

    let toggleIsActiveFilter = (toggleState) => {
        setIsFilterTopicAgentChannelActive(toggleState)
    }

    let runMoreSolvedCase = () => {
        if (hasMoreSolvedCase && Object.keys(chatDataFiltered).length > 0) {
            setFetchingSolvedCase(true);

            let _chatDataFiltered = { ...chatDataFiltered };

            getSolvedCaseList({
                topicID: filterTopicValue.value === 0 ? 0 : filterTopicValue.value,
                agentAccountID: filterAgentValue.value === 0 ? 0 : filterAgentValue.value,
                mediums: filterMediumValue,
                search: filterSearchValue,
                startDate: filterDateValue === '' ? '' : HelperDate.formatToString(new Date(filterDateValue.from), 'yyyy-MM-dd'),
                endDate: filterDateValue === '' ? '' : HelperDate.formatToString(new Date(filterDateValue.to), 'yyyy-MM-dd'),
                lastID: _chatDataFiltered[Object.keys(_chatDataFiltered)[Object.keys(_chatDataFiltered).length - 1]].id,
                isSearchOrFilter: false,
                isReconnect: false,
                isLoadMore: true,
                label: filterLabelValue,
                junkStatus: filterCaseTypeValue
            })
        }
    }

    let onScrollRoomListListener = () => {
        let el = document.getElementsByClassName("list-resolved-case")[0];
        if (el && !fetchingSolvedCase && !fetchingSolvedCaseIsError) {
            if ((el.scrollHeight - el.scrollTop - DEFAULT_CELL_HEIGHT) <= el.clientHeight) {
                runMoreSolvedCase();
            }
        }
    };

    let onClickRetryMoreCase = () => {
        runMoreSolvedCase();
    }

    let handleDateFilterClick = (day) => {
        let range = DateUtils.addDayToRange(day, filterDate);

        setFilterDate(range);
    }

    let modalFilterCalendar = () => {
        let { from, to } = filterDate;

        let modifiers = { start: from, end: to };

        // let modifiers = {
        //     selected: day => DateUtils.isDayInRange(day, filterDate)
        // }

        let actionFilterDate = () => {
            setShowModalCalendar();
            setIsFilterDateActive(true);
            setIsLoadingSearchSolvedCaseFinish(false);

            setFilterDateValue(filterDate);
            setFilterDateBefore(filterDate);

            getSolvedCaseList({
                topicID: filterTopicValue === 0 ? 0 : filterTopicValue.value,
                agentAccountID: filterAgentValue === 0 ? 0 : filterAgentValue.value,
                mediums: filterMediumValue,
                search: filterSearchValue,
                startDate: filterDate.from ? HelperDate.formatToString(new Date(filterDate.from), 'yyyy-MM-dd') : '',
                endDate: filterDate.to ? HelperDate.formatToString(new Date(filterDate.to), 'yyyy-MM-dd') : '',
                lastID: 0,
                isSearchOrFilter: true,
                isReconnect: false,
                isLoadMore: false,
                label: filterLabelValue,
                junkStatus: filterCaseTypeValue
            });
        }

        // let clearFilterDateValue = () => {
        //     if(!isFilterDateActive) {
        //         setFilterDate({
        //             from: undefined,
        //             to: undefined,
        //         });
        //     }
        // }

        return (
            <div>
                <Modal isOpen={showModalCalendar} toggle={toggleModalCalendar} className="case-modal-calendar">
                    <ModalBody>
                        <p className="modal-date-filter-title"><b>Filter by Resolved Date</b></p>

                        <div id="date-filter-wrapper" className="custom-date-picker">
                            <DayPicker numberOfMonths={2}
                                //    selected={from}
                                selectedDays={[from, { from, to }]}
                                modifiers={modifiers}
                                onDayClick={handleDateFilterClick}
                                className="Selectable"
                            />
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <ButtonWithLoadingOrIcon
                            onClickAction={() => {
                                // clearFilterDateValue();
                                toggleModalCalendar();
                            }}
                            className="no-fill-button main-button-48"
                            text="Cancel"
                        />

                        {(!filterDate.from && !filterDate.to) ?
                            <ButtonWithLoadingOrIcon
                                isDisabled={true}
                                className="orange-button main-button-48"
                                text="Update Filter"
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                onClickAction={() => actionFilterDate()}
                                className="orange-button main-button-48"
                                text="Update Filter"
                            />
                        }
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    let submitSearchSolvedCase = (e) => {
        e.preventDefault();

        if (filterSearchValue !== "") {
            setIsSearchActive(true);
            setIsLoadingSearchSolvedCaseFinish(false);

            getSolvedCaseList({
                topicID: filterTopicValue === 0 ? 0 : filterTopicValue.value,
                agentAccountID: filterTopicValue === 0 ? 0 : filterTopicValue.value,
                mediums: filterMediumValue,
                search: filterSearchValue,
                startDate: filterDateValue === '' ? '' : HelperDate.formatToString(new Date(filterDateValue.from), 'yyyy-MM-dd'),
                endDate: filterDateValue === '' ? '' : HelperDate.formatToString(new Date(filterDateValue.to), 'yyyy-MM-dd'),
                lastID: 0,
                isSearchOrFilter: true,
                isReconnect: false,
                isLoadMore: false,
                label: filterLabelValue,
                junkStatus: filterCaseTypeValue
            });
        }

    }

    let onChangeSearchValue = (e) => {
        if (e.target) {
            setFilterSearchValue(e.target.value);
        }
    }

    let runUpdateFilter = () => {
        setIsLoadingSearchSolvedCaseFinish(false);

        setFilterTopicValueBefore(filterTopicValue);
        setFilterAgentValueBefore(filterAgentValue);
        setFilterMediumValueBefore(filterMediumValue);
        setFilterLabelValueBefore(filterLabelValue)
        setFilterCaseTypeValueBefore(filterCaseTypeValue);

        getSolvedCaseList({
            topicID: filterTopicValue.value === 0 ? 0 : filterTopicValue.value,
            agentAccountID: filterAgentValue.value === 0 ? 0 : filterAgentValue.value,
            search: filterSearchValue,
            startDate: filterDateValue === '' ? '' : HelperDate.formatToString(new Date(filterDateValue.from), 'yyyy-MM-dd'),
            endDate: filterDateValue === '' ? '' : HelperDate.formatToString(new Date(filterDateValue.to), 'yyyy-MM-dd'),
            lastID: 0,
            isSearchOrFilter: true,
            isReconnect: false,
            isLoadMore: false,
            mediums: filterMediumValue,
            label: filterLabelValue,
            junkStatus: filterCaseTypeValue
        });
    }

    return (
        <>

            <div className="solved-case-tab-wrapper main-case-wrapper">
                <StartConversation
                    {...props}
                    isShowModalStartConversationProps={isShowModalStartConversation}
                    toggleModalStartConversationProps={toggleModalStartConversation}
                    orgIDProps={props.parentProps.match.params.orgID}
                    topicListProps={props.topicListProps}
                    onClickRoomListProps={props.onClickRoomListProps}
                    onClickRoomListWithTaptalkRoomProps={props.onClickRoomListWithTaptalkRoomProps}
                // toggleSwitchMineCaseProps={toggleSwitchMineCase}
                />
                {/* {!isLoadingSearchSolvedCaseFinish ?
                    <div className="solved-case-room-wrapper room-list-chat-container">
                        <div className="room-list-loading-wrapper"> 
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                            <b>Searching for Results</b>
                        </div>
                    </div>

                    : */}

                {(!isRoomListLoadingFinish && !searchNotFound) ?
                    <div className="solved-case-room-wrapper room-list-chat-container">
                        <div className="room-list-loading-wrapper">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                            <b>Loading Cases</b>
                        </div>
                    </div>

                    :

                    <React.Fragment>
                        <div className="case-room-search-filter-wrapper">
                            <div className="case-room-search-wrapper">
                                <form id="input-search-form" onSubmit={(e) => submitSearchSolvedCase(e)}>
                                    {/* <FiSearch className="case-search-icon" /> */}
                                    <input type="text"
                                        placeholder="Case ID, full name, alias and phone number"
                                        onChange={(e) => onChangeSearchValue(e)}
                                        value={filterSearchValue}
                                    />
                                    {/* <FiXCircle className="case-search-close-icon" /> */}

                                    <button className="submit-filter-button">
                                        <FiSearch />
                                    </button>
                                </form>
                            </div>

                            <div className="case-room-filter-tag-wrapper">
                                {(isSearchActive || isFilterTopicAgentChannelActive || isFilterDateActive) &&
                                    <div className={`case-room-filter-tag-badge case-room-filter-tag-badge-active`} onClick={() => resetFilterAndSearchValue()}>
                                        <FiRotateCcw />
                                        <b>Reset</b>
                                    </div>
                                }

                                <div className={`case-room-filter-tag-badge ${isFilterTopicAgentChannelActive ? 'case-room-filter-tag-badge-active' : ''}`}
                                    onClick={() => toggleModalFilter()}
                                >
                                    <img src={isFilterTopicAgentChannelActive ? iconFilterWhite : iconFilterGrey} alt="" />
                                    <b>Filters</b>
                                    {/* <span className="case-room-filter-tag-counter">2</span> */}
                                </div>

                                <div className={`case-room-filter-tag-badge ${isFilterDateActive ? 'case-room-filter-tag-badge-active' : ''}`}
                                    onClick={() => toggleModalCalendar()}
                                >
                                    <FiCalendar />
                                    {
                                        filterDateValue !== '' ?
                                        filterDateValue.to && filterDateValue.to.getTime() !== filterDateValue.from.getTime() ?
                                        <b>{HelperDate.formatToString(new Date(filterDateValue.from), "d MMM yyyy") + " - " + HelperDate.formatToString(new Date(filterDateValue.to), "d MMM yyyy")}</b>
                                        :
                                        !filterDateValue.to && filterDateValue.from.getTime() < new Date().getTime() ?
                                        <b>{HelperDate.formatToString(new Date(filterDateValue.from), "d MMM yyyy")} - Current</b>
                                        :
                                        <b>{HelperDate.formatToString(new Date(filterDateValue.from), "d MMM yyyy")}</b>
                                        :
                                        <b>All Dates</b>
                                    }
                                </div>

                                {/* <div 
                                    className={`
                                        case-room-filter-tag-badge 
                                        ${props.caseListChecked["solved"].isShow ? 'case-room-filter-tag-badge-active' : ''}
                                    `}
                                    onClick={() => toggleSelectCaseList("solved")}
                                >
                                    <FiList />
                                    Select
                                </div> */}
                            </div>
                        </div>

                        {props.caseListChecked["solved"].isShow &&
                            <CaseListAction
                                data={chatDataFiltered}
                                type="solved"
                                orgID={props.parentProps.match.params.orgID}
                                caseLength={Object.keys(chatDataFiltered).length}
                            />
                        }

                        {!isLoadingSearchSolvedCaseFinish ?
                            <div className="solved-case-room-wrapper room-list-chat-container">
                                <div className="room-list-loading-wrapper">
                                    <div className="lds-ring">
                                        <div /><div /><div /><div />
                                    </div>
                                    <b>Searching for Results</b>
                                </div>
                            </div>
                            :
                            <div className="solved-case-room-wrapper room-list-chat-container">
                                {/* <Scrollbars autoHide autoHideTimeout={500}
                                            renderThumbVertical={props => <div {...props} style={style.scrollStyle} className="room-list-scrollbars" />}
                                            onScrollFrame={(e) => onScrollRoomListListener(e)}
                                            ref={solvedCaseRef}
                                    > */}
                                {Object.keys(chatDataFiltered).length > 0 ?
                                    <div className="room-list-main-chat-container">
                                        <ListResolvedCaseComponent case={chatDataFiltered}
                                            caseLabel={props.caseLabelList}
                                            row={PrintRowResolvedCaseList}
                                            dataLength={Object.keys(chatDataFiltered).length}
                                            containerHeight={panelResolvedCaseHeight}
                                            mainProps={props}
                                            fetchingSolvedCaseProps={fetchingSolvedCase}
                                            fetchingSolvedCaseIsErrorProps={fetchingSolvedCaseIsError}
                                            onClickRetryMoreCaseProps={onClickRetryMoreCase}
                                            onScrollRoomListListenerProps={onScrollRoomListListener}
                                            refProps={solvedCaseReff}
                                        />
                                    </div>
                                    :
                                    searchNotFound ?
                                        <div className="room-list-no-chat-container">
                                            <b>
                                                No Results Were Found
                                            </b>

                                            {((isFilterDateActive && isFilterTopicAgentChannelActive) || (isFilterDateActive && isSearchActive)) ?
                                                <p>
                                                    {FILTER_MESSAGE.all}
                                                </p>
                                                :
                                                <React.Fragment>
                                                    {((isFilterDateActive && !isFilterTopicAgentChannelActive) || (isFilterDateActive && !isSearchActive)) &&
                                                        <p>
                                                            {FILTER_MESSAGE.date}
                                                        </p>
                                                    }

                                                    {((!isFilterDateActive && isFilterTopicAgentChannelActive) || (!isFilterDateActive && isSearchActive)) &&
                                                        <p>
                                                            {FILTER_MESSAGE.filter}
                                                        </p>
                                                    }
                                                </React.Fragment>
                                            }

                                            {/* <p>
                                                        Please try other filter options, or reset your filter options.
                                                    </p> */}

                                            <p className="no-chat-button" onClick={() => resetFilterAndSearchValue()}>
                                                <FiRotateCcw /> <b>Reset Filter Options</b>
                                            </p>
                                        </div>
                                        :
                                        <RoomListNoChat text="It seems like there aren't any resolved cases at this very moment" />
                                }
                                {/* </Scrollbars> */}
                            </div>
                        }
                    </React.Fragment>
                }

                {modalFilterCalendar()}

                {/* {modalFilterOption()} */}
                <FilterBy
                    filterLabelValueProps={filterLabelValue}
                    onSelectLabelProps={onSelectLabel}
                    filterTopicValueProps={filterTopicValue}
                    filterAgentValueProps={filterAgentValue}
                    filterMediumValueProps={filterMediumValue}
                    filterCaseTypeProps={filterCaseTypeValue}
                    filterCaseTypeBeforeProps={filterCaseTypeValueBefore}
                    isOpenProps={showModalFilterTopicAgent}
                    updateFilterProps={runUpdateFilter}
                    closeModal={() => setShowModalFilterTopicAgent(false)}
                    toggleProps={toggleModalFilter}
                    // agentListProps={agentList}
                    topicListProps={topicList}
                    toggleIsActiveFilterProps={toggleIsActiveFilter}
                    isResetFilterProps={isResetFilter}
                    toggleResetFilterProps={toggleResetFilter}
                    onSelectTopicProps={onSelectTopic}
                    onSelectAgentProps={onSelectAgent}
                    onSelectMediumProps={onSelectMedium}
                    onSelectCaseType={onSelectCaseType}
                    isHideAgentProps={false}
                    orgID={props.parentProps.match.params.orgID}
                    room="resolved"
                />
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    updateCaseLabel: state.updateCaseLabel,
    activeCasePanel: state.activeCasePanel,
    changeContactInfo: state.changeContactInfo,
    caseLabelList: state.caseLabelList,
    caseListChecked: state.caseListChecked,
    reconnectRoomList: state.reconnectRoomList,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    clearUpdateCaseLabel,
    clearChangeContactInfo,
    clearStartConversation,
    clearStartConversationBa,
    setCaseListChecked,
    setReconnectRoomList
}

export default connect(mapStateToProps, mapDispatchToProps)(SolvedCaseTab);