import React from 'react';
import './ChatRoomMessageOutRating.scss';
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import MessageDeleteGrey from "../../../../assets/img/icon-notallowed-grey.svg";
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName, isOtherAgent } from '../../../../helper/HelperGeneral';
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageOutRating = (props) => {
    //   let deleteMessageAction = (message) => {
    //     let messageIDs = [];
    //     messageIDs.push(message.messageID);
    //     tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    //   }
    let {
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        scrollToReply,
        caseData
    } = props;

    // let messageActionView = (message) => {
    //     let onClickReply = () => {
    //         onReplyMessage(message)
    //     }

    //     return (
    //         <div className="message-action-wrapper">
    //             <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
    //                 <FiCopy />
    //             </div>

    //             <div className="message-action-button-wrapper reply-button" title="Reply" onClick={onClickReply}>
    //                 <FaReply />
    //             </div>

    //             {/*<div className="message-action-button-wrapper forward-message" title="Forward">
    //                 <FaReply />
    //             </div> */}


    //             {/* <div className="message-action-button-wrapper" title="Delete" onClick={() => deleteMessageAction(message)}>
    //                 <FiTrash2 />
    //             </div> */}
    //         </div>
    //     )
    // }

    let printBody = (message) => {
        let _split = message.split("\n");

        if (props.caseDetailValProps && props.caseDetailValProps.medium !== "launcher") {
            // if(_split[_split.length - 1].includes("case_rating")) {
            //     message = message.replace(_split[_split.length - 1], "");
            //     message = message.replace("Rate your conversation:", "");
            // }

            _split.splice((_split.length - 2), 2);
        }

        return _split.join("\n");
    }

    return (
        <div className="chat-room-message-out-wrapper" id={`message-${singleChatDataProps.localID}`}>
            {singleChatDataProps.isDeleted ?
                <div className={`message-out-bubble-rating deleted-bubble`}>
                    <React.Fragment>
                        <img src={MessageDeleteGrey} alt="" className="deleted-icon" />
                        This message was deleted.
                    </React.Fragment>
                </div>
                :

                <div className={`message-out-bubble-rating ${isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) ? "message-bubble-blue" : ""} ${singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""}`}>
                    {/* {singleChatDataProps.forwardFrom.localID !== "" &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                        </div>
                    }
                    */}
                    {(isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) || (singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization")) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                    <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                    <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                    <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                }

                                {printLastMessageName(singleChatDataProps, caseData, true)}
                            </b>
                        </p>
                    }

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyOutMessage
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                            scrollToReply={scrollToReply}
                        />
                    }

                    <span className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(printBody(singleChatDataProps.body).trim()) }}></span>

                    <p className="message-info">
                        {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}

                        {(singleChatDataProps.isSending && !singleChatDataProps.isRead) &&
                            <img src={AirplaneDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && !singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDoubleDark} alt="" />
                        }

                        {singleChatDataProps.isRead &&
                            <img src={CheckMarkDoubleWhite} alt="" />
                        }

                        {/* {!singleChatDataProps.isDelivered &&
                            <img src={CheckMarkDark} alt="" />
                        } */}

                    </p>
                    {/* {messageActionView(singleChatDataProps)} */}

                    <MessageAction
                        singleChatDataProps={singleChatDataProps}
                        _className="message-action-dropdown-right"
                        replyAction={() => onReplyMessage(singleChatDataProps)}
                        hideForward
                    />

                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    caseDetailData: state.caseDetailData,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutRating);
