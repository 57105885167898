import React, { useEffect, useState } from 'react';
import './ClockInReportShow.scss';
import ReportService from '../../../../services/newServices/ReportService';
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperDate from '../../../../helper/HelperDate';
import { doToast, checkID } from '../../../../helper/HelperGeneral';
import { FiDownload } from 'react-icons/fi';
import NoReportFound from "../../../../assets/img/no-report-found.svg";
import AscendingDescendingTableHeader from '../../../reuseableComponent/AscendingDescendingTableHeader/AscendingDescendingTableHeader';
import AdvancedDateFilter from '../../../reuseableComponent/advancedDateFilter/AdvancedDateFilter';
import Pagination from "../../../reuseableComponent/pagination/Pagination";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";

const ClockInReport = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchClockInReport, setIsWaitingFetchClockInReport] = useState(true);
  let [printedClockInReportData, setPrintedClockInReportData] = useState([]);
  let [isLoadingExportReport, setIsLoadingExportReport] = useState(false);
  let [retriveDataError, setRetrieveDataError] = React.useState({
    code: false,
    message: false
  });
  let [dateVal, setDateVal] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 30)),
    to: new Date()
  });
  let [filterDateForCalender, setFilterDateForCalender] = useState({
    from: undefined,
    to: undefined
  });
  let [filterBy, setFilterBy] = useState({
    text: "Last 30 Days",
    val: "last_30"
  });
  let [sort, setSort] = useState({
    by: "fullName",
    order: "ASC"
  })
  // const [listPageVal, setListPageVal] = useState({
  //   label: '20',
  //   value: 20,
  // })
  let [pageDisplay, setPageDisplay] = useState(1);
  // const [listPage, setListPage] = useState(50);
  let [totalItems, setTotalItems] = useState(0);
  let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);
  let [showTooltip, setShowTooltip] = useState([false, false, false, false, false, false]);

  const TABLE_HEADER = [
    {
      title: "Agent Name",
      value: "fullName",
      class: "col_1 ",
    },
    {
      title: "Agent Email",
      value: "email",
      class: "col_2 ",
    },
    {
      title: "Clocked-in Duration",
      value: "clockInDuration",
      class: "col_3 ",
      tooltip: "Total duration the agent was clocked-in including away."
    },
    {
      title: "Active Duration",
      value: "activeDuration",
      class: "col_4 ",
      tooltip: "Total duration which the agent was clocked-in excluding away."
    },
    {
      title: "Away Duration",
      value: "awayDuration",
      class: "col_6 ",
      tooltip: "Total duration which the agent’s status was away."
    },
    {
      title: "Away Count",
      value: "awayCount",
      class: "col_6 ",
      tooltip: "How many times the agent switched to away."
    }
  ]

  useEffect(() => {
    if (orgID) {
      setPageDisplay(1);
      getClockInReport(false, 1);
    }
  }, [dateVal])

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Agent Reporting",
        {
          userFullName: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  let toogleTooltipClock = (i) => {
    let _tooltip = { ...showTooltip };
    _tooltip[i] = !_tooltip[i];

    setShowTooltip(_tooltip);
  }

  const doMount = async () => {
    //filter
    setDateVal(props.filterData.dateVal);
    setFilterDateForCalender(props.filterData.filterDateForCalender);
    setFilterBy(props.filterData.filterBy);
    //filter

    const paramID = checkID(props.match);

    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/members`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  const downloadReport = (data) => {
    // const id = orgID.split('-');
    // const timestamp = + new Date();
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `clock-in-report-data-from-${HelperDate.formatToString(new Date(dateVal.from), "dd-MM-yyyy")}-to-${HelperDate.formatToString(new Date(dateVal.to), "dd-MM-yyyy")}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const exportData = () => {
    let data = {
      startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
      endDate: dateVal.to ? HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : ""
    };

    setIsLoadingExportReport(true);

    ReportService.exportClockIn(orgID, data,
      (response) => {
        setIsLoadingExportReport(false);
        if (response.dataResult.error) {
          doToast(response.dataResult.error.message, "fail");
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  let getClockInReport = (_sort = false, page = false) => {
    setIsWaitingFetchClockInReport(true);
    let _retriveDataError = { ...retriveDataError };
    let data = {
      startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
      endDate: dateVal.to ? HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
      pageNumber: !page ? pageDisplay : page,
      pageSize: 50,
      sortBy: !_sort ? sort.by : _sort.by,
      sortOrder: !_sort ? sort.order : _sort.order
    }

    ReportService.getClockIn(props.match.params.orgID, data, (response) => {
      setIsWaitingFetchClockInReport(false);
      let dataResult = response.dataResult;

      if (dataResult.error.message === '') {
        setPrintedClockInReportData(dataResult.data.agents);
        setTotalItems(dataResult.data.totalItems);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    });
  };

  let handleChangePage = (page) => {
    setPageDisplay(page)

    getClockInReport(false, page);
  }

  let printText = (val) => {
    return val !== "" ? val : "-";
  }

  let onSort = (_sort) => {
    let _s = {
      by: _sort,
      order: _sort === sort.by ? (sort.order === "ASC" ? "DESC" : "ASC") : "ASC"
    }

    setSort(_s);
    setPageDisplay(1);
    getClockInReport(_s, 1);
  }

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      } else {
        doToast(props.history.location.state.message, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history]);

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="clock-in-report-wrapper sectionWrap">
        {/* tier blocking */}
        {retriveDataError.code === "49901" &&
          <RetrieveDataLoading
            isLoading={isWaitingFetchClockInReport}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
            loadingText={"Loading Report"}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="clock-in-report-title">
            <b>Clock In Report</b>
          </p>

          {!retriveDataError.code &&
            <>
              {isLoadingExportReport ?
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40 add-clock-in-report-button"
                  text="Download Report"
                  position="left"
                  isLoading
                  loadingColor="gray"
                  isDisabled
                />
                :
                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40 add-clock-in-report-button"
                  onClickAction={exportData}
                  text="Download Report"
                  position="left"
                  icon={{
                    type: "svg",
                    src: FiDownload
                  }}
                />
              }
            </>
          }

          <div className="clock-in-report-data-outer-wrapper">
            {!retriveDataError.code &&
              <AdvancedDateFilter
                toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
                isOpenDropdown={isShowDropdownDate}
                dateVal={dateVal}
                filterDateForCalender={filterDateForCalender}
                filterBy={filterBy}
                onClickDate={(date) => setDateVal(date)}
                onClickFilterBy={(filter) => setFilterBy(filter)}
                onClickCalendar={(date) => {
                  setFilterDateForCalender(date)
                }}
                ignoreAllTime
                isDisabled={isWaitingFetchClockInReport || isLoadingExportReport}
                disabledDays={{
                  after: new Date()
                }}
                maxRange={365}
              />
            }

            {(isWaitingFetchClockInReport || (retriveDataError.code && retriveDataError.code !== "49901")) ?
              <RetrieveDataLoading
                isLoading={isWaitingFetchClockInReport}
                errorMessage={retriveDataError.message}
                errorCode={retriveDataError.code}
              />
              :
              <React.Fragment>
                <div className="clock-in-report-data-wrapper">
                  {printedClockInReportData.length === 0 ?
                    <div className="no-clock-in-report">
                      <img src={NoReportFound} alt="No Report Found" />

                      <p>
                        <b>No report found.</b>
                      </p>
                    </div>
                    :
                    <table className='no-hover table-clock-in-report'>
                      <thead>
                        <tr>
                          {TABLE_HEADER.map((val, idx) => {
                            return (
                              <React.Fragment key={`table-head-${idx}`}>
                                <AscendingDescendingTableHeader
                                  className={val.class}
                                  fieldName={val.value}
                                  title={val.title}
                                  onClick={() => onSort(val.value)}
                                  sortByVal={sort}
                                  noSort
                                  withTooltip={val.tooltip}
                                  tooltipText={val.tooltip}
                                  showTooltip={showTooltip[idx]}
                                  toogleTooltipClock={() => {
                                    toogleTooltipClock(idx)
                                  }}
                                  idx={idx}
                                />
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </thead>

                      <tbody>
                        {printedClockInReportData.map((value, key) => {
                          return (
                            <tr key={`clock-in-report-row-${key}`}>
                              <td className="col_1 col_fullname">
                                {value.fullName}{value.alias === "" ? "" : ` (${value.alias})`}
                              </td>
                              <td className="col_2 col_email">
                                {value.email}
                              </td>
                              <td className="col_3">
                                {printText(value.clockInDuration)}
                              </td>
                              <td className="col_4">
                                {printText(value.activeDuration)}
                              </td>
                              <td className="col_5">
                                {printText(value.awayDuration)}
                              </td>
                              <td className="col_6">
                                {printText(value.awayCount)}
                              </td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  }
                </div>

                <div className="pagination-wrapper">
                  {
                    totalItems > 0 &&
                    <Pagination
                      total={totalItems}
                      page={pageDisplay}
                      listPerPage={50}
                      step={1}
                      onChangePage={handleChangePage}
                    />
                  }
                </div>
              </React.Fragment>
            }
          </div>
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default ClockInReport;