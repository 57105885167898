import React, { useEffect, useState } from "react";
import "./SelectTierForm1.scss";
import { ToastContainer } from 'react-toastify';
import { Modal, ModalBody, Collapse } from 'reactstrap';
import NewOrganizationHeader from "../../newOrganizationHeader/NewOrganizationHeader";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import { FiAlertCircle, FiChevronDown } from "react-icons/fi";
// import iconLightbulb from "../../../../assets/img/lightbulb.svg";
import iconWhatsapp from "../../../../assets/img/omnichannel/room-list/icon-whatsapp.svg";
import checkOrange from "../../../../assets/img/circle-check-orange.svg";
import { setParentMenuBlockActive } from '../../../../redux/actions/reduxActionSideMenu';
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
// import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import { connect } from 'react-redux';
import HelperAuth from "../../../../helper/HelperAuth";
import { rupiahFormat } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import { setSelectedTier, clearSelectedTier } from "../../../../redux/actions/reduxActionSelectedTier";
import mixpanel from "mixpanel-browser";

// const REDIRECT_TO = {
//     home: "home",
//     subscription: "subscription",
//     invoice: "invoice"
// }

const FREE_TEXT = (trialDay) => (trialDay > 0 ? `Start ${trialDay} Day${trialDay > 1 ? "s" : ""} for Free` : "Select Tier");
// const tierDiscount = 17;

const PAY_FOR = {
    month: "month",
    year: "year"
};

const ACTIVE_FEATURE = {
    case: true,
    mau: true,
    agent: true,
    channel: true,
    topic: true,
    ca_unassigned: true,
    ca_round_robin: true,
    ca_balanced: true,
    office_hrs: true,
    auto_responder: true,
    white_label: true,
    cbot_integration: true
}

var SelectTierForm1 = (props) => {
    let [isWaitTierData, setIsWaitTierData] = useState(true);
    let [tierData, setTierData] = useState(false);
    let [payForAnnually, setPayForAnnually] = useState(false);
    // let [selectedTier, setSelectedTier] = useState(false);
    let [currentTier, setCurrentTier] = useState(false);
    let [currentPayment, setCurrentPayment] = useState(false);
    let [isShowModalHasPendingSubscription, setShowModalHasPendingSubscription] = useState(false);
    let [showDetailState, setShowDetailState] = useState({});
    let [activeTier, setActiveTier] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });

    useEffect(() => {
        if (props.selectedTier) {
            // setPayForAnnually(props.selectedTier.payUnit === "year");
            setActiveTier(props.selectedTier);
        }
    }, [props.selectedTier])

    useEffect(() => {
        if (HelperAuth.userWasLoggedIn()) {
            runGetOrganizationDetail();
            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

            if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.init(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY);
                mixpanel.identify(myAgentData.account.id);
                mixpanel.track(
                    "[Page] Select Tier",
                    {
                        userFullName: myAgentData.account.fullName,
                        userID: myAgentData.account.id,
                        organizationID: props.match.params.orgID.split("-")[0]
                    }
                );
            }
        }
    }, []);

    let toggleModalHasPendingSubscription = () => {
        setShowModalHasPendingSubscription(!isShowModalHasPendingSubscription);
    }

    let togglePayForUnit = () => {
        let _selectedTier = { ...props.selectedTier };
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Button] Switch Payment Terms",
                {
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    organizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }

        if (_selectedTier) {
            _selectedTier.payUnit = !payForAnnually ? "year" : "month";
            props.setSelectedTier(_selectedTier);
        }

        setPayForAnnually(!payForAnnually);
    }

    let runGetOrganizationDetail = () => {
        let _retriveDataError = { ...retriveDataError };

        ServiceOrganization.postGetOrganizationDetail(props.match.params.orgID, (response) => {
            let dataResult = response.dataResult;

            if (response.dataResult.status === 200) {
                if (response.dataResult.data.selectedTier.id !== 0) {
                    props.history.push(`/o/${props.match.params.orgID}/home`)
                } else {
                    setCurrentTier(response.dataResult.data.selectedTier);
                    let paymentSetting = response.dataResult.data.paymentSettings;

                    if (!paymentSetting.isSet) {
                        paymentSetting.isAutomaticPayment = null;
                    }

                    setCurrentPayment(paymentSetting);

                    getTierList();
                }
            } else {
                if (response.dataResult.error.code === "49204") {
                    props.history.push(`/o/${props.match.params.orgID}/home`)
                } else {
                    _retriveDataError.message = dataResult.error.message;
                    _retriveDataError.code = dataResult.error.code;
                    setRetrieveDataError(_retriveDataError);
                    setIsWaitTierData(false);
                }
            }
        })
    }

    let getTierList = () => {
        let _retriveDataError = { ...retriveDataError };

        setIsWaitTierData(true);

        ServiceOrganization.postGetTierList(props.match.params.orgID, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.message !== "") {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            } else {
                let newObject = {};

                if (dataResult.data.hasPendingSubscription) {
                    toggleModalHasPendingSubscription();
                }

                dataResult.data.tiers.map((value) => {
                    let features = [];
                    value.features.map((valFeature) => {
                        if (valFeature.valueType === "integer") {
                            features.push(valFeature);
                        } else if (valFeature.valueType === "boolean") {
                            if (valFeature.config.value === "1") {
                                features.push(valFeature);
                            }
                        } else if (valFeature.valueType === "enum") {
                            features.push(valFeature);
                        }

                        return null;
                    })
                    features.map((value, index) => {
                        if (features.length > 5) {
                            newObject[index] = false;
                        }

                        return null;
                    })

                    value.features = features;

                    return null;
                })

                setShowDetailState(newObject);
                setTierData(dataResult.data);
            }

            setIsWaitTierData(false);
        })
    }

    // let setTier = () => {
    // setWaitForResponseSubmitTier(true);

    // let redirectTo = (to, invoiceID) => {
    //     if(to === REDIRECT_TO.home) {
    //         window.location.href = `/o/${props.match.params.orgID}/home`;
    //     }else if(to === REDIRECT_TO.subscription) {
    //         window.location.href = `/o/${props.match.params.orgID}/organization/billing/unpaid-invoices`;
    //     }else {
    //         window.location.href = `/o/${props.match.params.orgID}/organization/billing/unpaid-invoices-detail/${invoiceID}`;
    //     }
    // }

    // ServiceOrganization.postSetTierList(props.match.params.orgID, selectedTier, (response) => {
    //    let dataResult = response.dataResult;

    //     if(dataResult.status === 200) {
    //         if(dataResult.data.success) {
    //             redirectTo(dataResult.data.redirectTo, dataResult.data.invoiceID)
    //         }else {
    //           setWaitForResponseSubmitTier(false);
    //           doToast(dataResult.data.message, "fail");
    //         }
    //     }else {
    //       setWaitForResponseSubmitTier(false);
    //       doToast(dataResult.error.message, 'fail');
    //     }
    // })
    // }

    let renderStep = () => (
        <div className="step-container">
            <div className="step-content orange-step">
                <div className="step-number"><b>1</b></div>
                <b className="step-wording">Select Tier</b>
            </div>
            <div className="step-line step-line-orange" />
            <div className="step-content">
                <div className="step-number"><b>2</b></div>
                <b className="step-wording">Billing Info</b>
            </div>
        </div>
    )

    let printFeature = (data) => {
        let text = "";

        if (data.code === "case" && data.config.value === "0") {
            text = "No inbox";
        } else {
            if (data.valueType === "integer") {
                text = data.config.value === "-1" ? `Unlimited ${data.name}` : `${data.config.value} ${data.name}`
            } else if (data.valueType === "boolean") {
                text = data.name;
            } else {
                text = data.name;
            }
        }

        return text;
    }

    let isSkuItemsExist = (data) => {
        let payUnit = payForAnnually ? PAY_FOR.year : PAY_FOR.month;
        let index = data.skuItems.findIndex(value => value.validityType === payUnit);

        return index !== -1;
    }

    let whatsAppPriceIndex = (data) => {
        let payUnit = payForAnnually ? PAY_FOR.year : PAY_FOR.month;
        let index = data.whatsAppPrices.findIndex(value => value.validityType === payUnit);

        return index;
    }

    let printPrice = (data, isDiscount = false) => {
        let payUnit = payForAnnually ? PAY_FOR.year : PAY_FOR.month;
        let index = data.skuItems.findIndex(value => value.validityType === payUnit);
        let indexM = data.skuItems.findIndex(value => value.validityType === "month");
        let indexY = data.skuItems.findIndex(value => value.validityType === "year");

        let price = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: data.skuItems[index].prices[0].currency,
        }).format(
            !isDiscount ?
                data.skuItems[index].prices[0].unitPrice
                :
                // data.skuItems[index].prices[0].unitPrice * tierDiscount / 100
                data.skuItems[indexM].prices[0].unitPrice * 12 - data.skuItems[indexY].prices[0].unitPrice
        ).replace(/,/g, ".").replace(/\D00$/, "");

        return price + "/" + (payForAnnually ? "year" : "month").replace("Rp", "IDR");
    }

    let printWhatsappPrice = (data) => {
        return `+ ${rupiahFormat(data.unitPrice)}/${data.validityType}`.replace("Rp", "IDR");
    }

    let back = () => {
        props.setParentMenuBlockActive({
            activeMenuBlock: "home",
            submenuList: null
        })

        props.history.push(`/o/${props.match.params.orgID}/home`)
    }

    let onClickTier = (tier) => {
        let payUnit = payForAnnually ? PAY_FOR.year : PAY_FOR.month;
        tier.paymentSetting = currentPayment;
        tier.payUnit = payUnit;
        tier.freeTrialDays = tierData.freeTrialDays;
        tier.currentTier = currentTier.id;
        props.setSelectedTier(tier);
        onClickContinue();
        // let indexSku = tier.skuItems.findIndex(value => value.validityType === payUnit);

        // let _selectedTier = {
        //     currentTierID: !currentTier ? 0 : currentTier.id,
        //     tierID: tier.id,
        //     tierCode: tier.code,
        //     skuID: tier.skuItems[indexSku].id,
        //     skuString: tier.skuItems[indexSku].sku,
        //     name: tier.name
        // };

        // setSelectedTier(_selectedTier);
    }

    let onClickContinue = () => {
        if (!currentPayment.isSet) {
            props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods`);
        } else {
            props.history.push(`/o/${props.match.params.orgID}/select-tier/pay-methods/review-transaction`);
        }
    }

    let renderModalHasPendingSubscription = () => {
        let goToSubscription = () => {
            props.history.push(`/o/${props.match.params.orgID}/organization/billing`)
        }

        return (
            <Modal className="modal-has-pending-subscription" isOpen={isShowModalHasPendingSubscription} toggle={toggleModalHasPendingSubscription}>
                <ModalBody>
                    <p className="modal-has-pending-subscription-title">
                        <FiAlertCircle />
                        <b>You have a selected tier awaiting payment</b>
                    </p>

                    <p className="modal-has-pending-subscription-description">
                        You can go to Subscriptions to finish your payment or you can Select Another Tier to continue.
                    </p>

                    <button className="no-fill-button" onClick={toggleModalHasPendingSubscription}>
                        Select Another Tier
                    </button>

                    <button className="orange-button" onClick={goToSubscription}>
                        Go To Subscriptions
                    </button>
                </ModalBody>
            </Modal>
        )
    }

    let toggleAccordionFeature = (index) => {
        let _showDetailState = { ...showDetailState };
        _showDetailState[index] = !_showDetailState[index];
        setShowDetailState(_showDetailState);
    }

    return (
        <React.Fragment>
            <ToastContainer className="ToastContainer" />
            {renderModalHasPendingSubscription()}

            <NewOrganizationHeader
                titleText="Start Subscription"
                hideLogout
                topRightContent={renderStep}
            />

            <div className="select-tier-main-wrapper">
                <SectionWrap
                    {...props}
                    orgID={props.match.params.orgID}
                    _className="section-width-100 pos-relative"
                    barVerifyClass="margin-top-0"
                >
                    {isWaitTierData || retriveDataError.code ?
                        <RetrieveDataLoading
                            isLoading={isWaitTierData}
                            errorMessage={retriveDataError.message}
                        />
                        :
                        <div className="select-tier-form-1">
                            {currentTier.id !== 0 &&
                                <BackButton text="Back to Home" onClick={back} />
                            }

                            {/* <button className="orange-button continue-tier" disabled={!activeTier} onClick={onClickContinue}>
                                Continue
                            </button> */}

                            {/* style={currentTier.id === 0 ? {paddingTop: "100px"} : {}}> */}
                            <div className="select-tier-top">
                                <p className="select-your-tier-title">
                                    <b>Choose your tier</b>
                                </p>

                                <div className="toggle-select-tier">
                                    <b>Monthly</b>

                                    <div className="pay-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input type="checkbox" onChange={(e) => togglePayForUnit(e)} checked={payForAnnually} />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>

                                    <b>Yearly</b>
                                </div>

                                <div className="alert-component alert-orange">
                                    {/* <img src={iconLightbulb} alt="" /> */}
                                    {/* <b>{tierDiscount}% off</b> */}
                                </div>
                            </div>

                            {tierData &&
                                <div className="pricing-outer-container">
                                    {/* {tierData.freeTrialDays === 0 &&
                                        <div className="alert-component">
                                            <FiAlertTriangle />
                                            <b>To change your tier, please contact us via email</b>

                                            <a href="mailto:sales@taptalk.io">
                                                <button className="light-orange-button">
                                                    <b>sales@taptalk.io</b>
                                                </button>                         
                                            </a>   
                                        </div>
                                    } */}

                                    <div className="main-pricing-wrapper">
                                        {tierData.tiers.map((value, index) => {
                                            return (
                                                isSkuItemsExist(value) ?
                                                    <div
                                                        key={`pricing-${index}`}
                                                        className={`pricing-div-wrapper pricing-div-wrapper-desktop pricing-${value.type}
                                                            ${activeTier ? (activeTier.id === value.id ? "active-selected-tier" : "") : ""}
                                                        `}
                                                    >
                                                        <b className="pricing-badge">{value.name}</b>

                                                        {/* <p className="pricing-price1">
                                                            <b>{printPrice(value)}</b>
                                                        </p> */}

                                                        <p className="pricing-title">
                                                            <b>{value.shortDescription}</b>
                                                        </p>

                                                        {/* {payForAnnually &&
                                                            <p className="save-pricing">
                                                                <b>Save {printPrice(value, true)}</b>
                                                            </p>
                                                        } */}

                                                        {/* <button className="light-orange-button submit-price">
                                                            <div className="lds-ring">
                                                                <div /><div /><div /><div />
                                                            </div>
                                                        </button> */}

                                                        {/* {tierData.freeTrialDays > 0 && */}
                                                        {/* ${activeTier ? (activeTier.id === value.id ? "active-tier-button": "") : ""} */}
                                                        {!value.name.includes("Outbound") ?
                                                            <button
                                                                className={`orange-button submit-price`}
                                                                onClick={() => onClickTier(value)}
                                                            >
                                                                {/* Start {tierData.freeTrialDays} Days Trial */}
                                                                <p>
                                                                    {activeTier &&
                                                                        // (activeTier.id === value.id ?
                                                                        //     <>
                                                                        //         <FiCheck />
                                                                        //         Selected Tier
                                                                        //     </>
                                                                        //     :
                                                                        FREE_TEXT(tierData.freeTrialDays)
                                                                        // )
                                                                    }

                                                                    {!activeTier &&
                                                                        FREE_TEXT(tierData.freeTrialDays)
                                                                    }
                                                                </p>
                                                            </button>
                                                            :
                                                            <a href="mailto:sales@taptalk.io">
                                                                <button className="orange-button submit-price">
                                                                    sales@taptalk.io
                                                                </button>
                                                            </a>
                                                        }
                                                        {/* } */}

                                                        <ul>
                                                            {value.features.map((value, index) => {
                                                                return (
                                                                    ACTIVE_FEATURE[value.code] ?
                                                                        index < 5 ?
                                                                            // (printFeature(value) !== "" ?

                                                                            // )
                                                                            (printFeature(value) !== "" ?
                                                                                <li key={`list-price-${index}`}>
                                                                                    <img src={checkOrange} alt="" className="" />
                                                                                    {printFeature(value)}
                                                                                </li>
                                                                                :
                                                                                null
                                                                            )
                                                                            :
                                                                            null
                                                                        :
                                                                        null
                                                                )
                                                            })}
                                                        </ul>

                                                        <Collapse isOpen={showDetailState[index]}>
                                                            <ul className={`no-margin-padding-top accordion-feauture show-feauture-${index} `}>
                                                                {value.features.length > 5 &&
                                                                    value.features.map((value, index) => {
                                                                        return (
                                                                            ACTIVE_FEATURE[value.code] ?
                                                                                index > 4 ?
                                                                                    <li key={`list-price-${index}`}>
                                                                                        <img src={checkOrange} alt="" className="" />
                                                                                        {printFeature(value)}
                                                                                    </li>
                                                                                    :
                                                                                    null
                                                                                :
                                                                                null
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </Collapse>

                                                        <div className="accordion-toggle-wrapper" onClick={() => toggleAccordionFeature(index)}>
                                                            <div className={`accordion-toggle-content accordion-toggle-content-${index}`}>
                                                                <FiChevronDown className={`${showDetailState[index] ? "active-accordion-feature" : ""}`} />
                                                                <b>Show More</b>
                                                            </div>
                                                        </div>

                                                        {/* <ul className="no-margin-padding-top">
                                                            {whatsAppPriceIndex(value) !== -1 &&
                                                                <li className="pricing-whatsapp">
                                                                    <img src={iconWhatsapp} alt="" />
                                                                    <b>Whatsapp Price</b>
                                                                    <br />
                                                                    <p>{printWhatsappPrice(value.whatsAppPrices[whatsAppPriceIndex(value)])}</p>
                                                                </li>
                                                            }
                                                        </ul> */}

                                                        {/* <p className="note-text">
                                                            <b>
                                                                *Price not including LINE Push API
                                                            </b>
                                                        </p> */}
                                                    </div>
                                                    :
                                                    ""
                                            )
                                        })}

                                        {/* <div className={`pricing-div-wrapper`}>
                                            <b className="pricing-badge">Corporate</b>

                                            <p className="pricing-price1">
                                                <b>Contact Us</b>
                                            </p>

                                            <p className="pricing-title">
                                                <b>For More Usage and Features</b>
                                            </p>

                                            <a href="mailto:sales@taptalk.io">
                                                <button className="orange-button submit-price">
                                                    sales@taptalk.io
                                                </button>
                                            </a>

                                            <ul>
                                                <li>
                                                    <img src={checkOrange} alt="" className="" />
                                                    Official WhatsApp Business API
                                                </li>
                                                <li>
                                                    <img src={checkOrange} alt="" className="" />
                                                    Dedicated Premuim Support and Account Manager
                                                </li>
                                                <li>
                                                    <img src={checkOrange} alt="" className="" />
                                                    Premium Features
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {/* <PopupConfirmation
                        confirmationTitle={`Submit Tier ${selectedTier.name}?`}
                        confirmationDescription={`Are you sure you want to select this tier?`}
                        onClickCancel={onClickCancelTier}
                        onClickConfirmation={setTier}
                        waitForResponse={waitForResponseSubmitTier}
                        showModalProps={isShowModalSubmitTier}
                        submitText="Submit"
                    /> */}
                </SectionWrap>
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps = {
    setParentMenuBlockActive,
    setSelectedTier,
    clearSelectedTier
};

const mapStateToProps = state => ({
    selectedTier: state.selectedTier
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectTierForm1);
