import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./IntegrationChatGPTSetupLeadGeneration.scss";
import { Tooltip } from 'reactstrap';
import { FiInfo, FiTrash2, FiPlus, FiChevronUp, FiSave, FiAlertCircle } from "react-icons/fi";
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { doToast } from "../../../../helper/HelperGeneral";
import HelperInput from "../../../../helper/HelperInput";
import { connect } from 'react-redux';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupDiscard from "../../../reuseableComponent/popupDiscard/PopupDiscard";
import CustomReactSelect from "../../../reuseableComponent/CustomReactSelect/CustomReactSelect";
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import DragIcon from "../../../reuseableComponent/dragIcon/DragIcon";
import FiQuestion from "../../../../assets/img/fi-question.svg";

const IntegrationChatGPTSetupLeadGeneration = (props) => {
    let [isLoadingGetData, setIsLoadingGetData] = useState(true);
    let [isLoadingSavedData, setIsLoadingSavedData] = useState(false);
    let [draggingItem, setDraggingItem] = useState(null);
    let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);
    let [statusWebhookURL, setStatusWebhookURL] = useState("");
    let [checkStatusWebhookURL, setCheckStatusWebhookURL] = useState(false);
    let [showModalCriteria, setShowModalCriteria] = useState(false);

    useEffect(() => {
        fetchSavedLeadsGeneration();
    }, [props.match.params.orgID]);

    const fetchSavedLeadsGeneration = () => {
        setIsLoadingGetData(true);

        // // TODO: GET LEAD GENERATIONS API
        props.getSavedLeadGeneration((response) => {
            let dataResult = response.dataResult;
            if (dataResult.error.message) {
                doToast(dataResult.error.message, 'fail');
            }
            setIsLoadingGetData(false);
        });
    }

    const toggleModalDiscard = () => {
        setIsShowModalDiscard(!isShowModalDiscard);
    }

    const onChangeText = (e, i, id, parentIndex) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria[parentIndex].variables[i][id] = e.target.value;
        props.setLeadsGenerationSettings(_data);

        let _error = { ...props.leadsGenerationSettingsError };
        _error.criteria[parentIndex].variables[i][id] = "";
        props.setLeadsGenerationSettingsError(_error);
    }

    // const resizeQuestionTextArea = (index) => {
    //     const textarea = document.getElementById(`question-${index}`);
    //     if (!textarea) {
    //         return;
    //     }
    //     if (textarea.offsetHeight === textarea.scrollHeight) {
    //         return;
    //     }
    //     textarea.style.height = "1px"; // Reset height
    //     if (textarea.scrollHeight <= 48) {
    //         textarea.style.height = "48px";
    //     }
    //     else {
    //         textarea.style.height = "" + textarea.scrollHeight + "px";
    //     }
    // }

    const toggleConfig = () => {
        let _data = { ...props.leadsGenerationSettings };
        _data.isEnabled = !_data.isEnabled;

        if (_data.isEnabled) {
            _data.criteria.push({
                "text": "",
                "isEnabled": false,
                "sendQuestionMethod": "",
                "shouldSummarize": false,
                "shouldHandover": false,
                "handoverTopicID": 0,
                "isWebhookEnabled": false,
                "webhookURL": "",
                "variables": [
                    {
                        "name": "",
                        "description": "",
                        "isRequired": false
                    },
                ]
            });
        }

        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...props.leadsGenerationSettingsError };
        let _newErr = JSON.parse(JSON.stringify(props.ERROR_LEAD_GENERATION));

        _newErr.variables.push(JSON.parse(JSON.stringify(props.ERROR_LEAD_GENERATION_VARIABLE)))

        _errorMessageData.criteria.push(_newErr);
        props.setLeadsGenerationSettingsError(_errorMessageData);
    }

    const addAnotherCriteria = () => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria.push({
            "text": "",
            "isEnabled": false,
            "sendQuestionMethod": "",
            "shouldSummarize": false,
            "shouldHandover": false,
            "handoverTopicID": 0,
            "isWebhookEnabled": false,
            "webhookURL": "",
            "variables": [
                {
                    "name": "",
                    "description": "",
                    "isRequired": false
                },
            ]
        });
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...props.leadsGenerationSettingsError };
        let _newErr = JSON.parse(JSON.stringify(props.ERROR_LEAD_GENERATION));

        _newErr.variables.push(JSON.parse(JSON.stringify(props.ERROR_LEAD_GENERATION_VARIABLE)))

        _errorMessageData.criteria.push(_newErr);
        props.setLeadsGenerationSettingsError(_errorMessageData);
    }

    const addAnotherVariable = (parentIndex) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria[parentIndex].variables.push({
            description: "",
            isRequired: false,
            name: ""
        });
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...props.leadsGenerationSettingsError };
        _errorMessageData.criteria[parentIndex].variables.push(JSON.parse(JSON.stringify(props.ERROR_LEAD_GENERATION_VARIABLE)));
        props.setLeadsGenerationSettingsError(_errorMessageData);
    }

    const onDeleteVariable = (i, parentIndex) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria[parentIndex].variables.splice(i, 1)
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...props.leadsGenerationSettingsError };
        _errorMessageData.criteria[parentIndex].variables.splice(i, 1)
        props.setLeadsGenerationSettingsError(_errorMessageData);
    }

    const onVariableDragStart = (e, item) => {
        setDraggingItem(item);
        e.dataTransfer.setData('text/plain', '');
    }

    const onVariableDragEnd = (e) => {
        setDraggingItem(null);
    }

    const onVariableDragOver = (e) => {
        e.preventDefault();
    }

    const onVariableDrop = (e, item) => {
        if (!draggingItem) {
            return;
        }

        let _data = { ...props.leadsGenerationSettings };
        const currentIndex = _data.variable.indexOf(draggingItem);
        const targetIndex = _data.variable.indexOf(item);

        if (currentIndex !== -1 && targetIndex !== -1) {
            _data.variable.splice(currentIndex, 1);
            _data.variable.splice(targetIndex, 0, draggingItem);
            props.setLeadsGenerationSettings(_data);

            let _errorMessageData = { ...props.leadsGenerationSettingsError };
            const draggedErrorData = _errorMessageData.variable[currentIndex];
            _errorMessageData.variable.splice(currentIndex, 1);
            _errorMessageData.variable.splice(targetIndex, 0, draggedErrorData);
            props.setLeadsGenerationSettingsError(_errorMessageData);
        }
    }

    const printVariable = (v, i, parentIndex, length) => {
        return (
            <div
                key={`variable-wrap-${i}`}
                className={`variable-wrapper-lead-generation variable-wrapper-lead-generation-${i} form-content-wrapper`}
            >
                <label>
                    <b>Variable</b>
                </label>

                {(length > 1 && props.leadsGenerationSettings.isEnabled) &&
                    <FiTrash2
                        className={`button-delete`}
                        onClick={() => onDeleteVariable(i, parentIndex)}
                    />
                }

                <div className={`input-text-variable input-var-name-${parentIndex}-${i}`}>
                    <input
                        type="text"
                        placeholder="E.g. Full Name"
                        className={` ${props.leadsGenerationSettingsError.criteria[parentIndex].variables[i].name ? "border-red" : ""}`}
                        value={v.name}
                        onChange={(e) => onChangeText(e, i, "name", parentIndex)}
                        disabled={!props.leadsGenerationSettings.criteria[parentIndex].isEnabled || !props.leadsGenerationSettings.isEnabled}
                    />
                </div>

                {props.leadsGenerationSettingsError.criteria[parentIndex].variables[i].name !== "" &&
                    <ErrorOrInfoComp
                        text={props.leadsGenerationSettingsError.criteria[parentIndex].variables[i].name}
                        _className="red-text margin-bottom-16"
                        icon={<FiAlertCircle />}
                    />
                }

                <label className={`input-var-description-${parentIndex}-${i}`}>
                    <b>Description</b>
                </label>
                <textarea
                    placeholder="Describe your variable here (E.g. Your potential customer’s full name according to legal documents)"
                    className={` ${props.leadsGenerationSettingsError.criteria[parentIndex].variables[i].description ? "border-red" : ""}`}
                    value={v.description}
                    onChange={(e) => onChangeText(e, i, "description", parentIndex)}
                    disabled={!props.leadsGenerationSettings.criteria[parentIndex].isEnabled || !props.leadsGenerationSettings.isEnabled}
                    rows={3}
                />
                {props.leadsGenerationSettingsError.criteria[parentIndex].variables[i].description !== "" &&
                    <ErrorOrInfoComp
                        text={props.leadsGenerationSettingsError.criteria[parentIndex].variables[i].description}
                        _className="red-text margin-bottom-16"
                        icon={<FiAlertCircle />}
                    />
                }

                <div className="criteria-switch-wrapper this-question">
                    <label className="custom-switch-wrapper">
                        <input
                            type="checkbox"
                            checked={v.isRequired}
                            onChange={() => {
                                let _leadGen = { ...props.leadsGenerationSettings };
                                _leadGen.criteria[parentIndex].variables[i].isRequired = !_leadGen.criteria[parentIndex].variables[i].isRequired;
                                props.setLeadsGenerationSettings(_leadGen);
                            }}
                            disabled={false}
                        />
                        <span className="custom-switch round"></span>
                    </label>

                    <p className="switch-label margin-0">This question is required</p>
                </div>

                {/* <img
                    src={IconDragDrop}
                    className="drag-indicator"
                    alt=""
                    draggable={false}
                /> */}

                <SortableKnob>
                    <DragIcon _className="fi-drag-drop" />
                </SortableKnob>
            </div>
        )
    }

    let toggleModalDeleteCriteria = (index = false) => {
        setShowModalCriteria(index);
    }

    let removeCriteria = (iCriteria) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria.splice(iCriteria, 1);
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...props.leadsGenerationSettingsError };
        _errorMessageData.criteria.splice(iCriteria, 1);
        props.setLeadsGenerationSettingsError(_errorMessageData);
        toggleModalDeleteCriteria();
    }

    let onSortEnd = (parentIndex, oldIndex, newIndex) => {
        let swapPosition = (from, to) => {
            let _errorMessageData = { ...props.leadsGenerationSettingsError };
            let _data = { ...props.leadsGenerationSettings };

            let oldIndexData = _data.criteria[parentIndex].variables.slice()[from];

            _data.criteria[parentIndex].variables.splice(from, 1);

            let _newData = _data.criteria[parentIndex].variables.toSpliced(to, 0, oldIndexData);

            _data.criteria[parentIndex].variables = _newData;

            let oldIndexDataError = _errorMessageData.criteria[parentIndex].variables.slice()[from];

            _errorMessageData.criteria[parentIndex].variables.splice(from, 1);

            let _newDataError = _errorMessageData.criteria[parentIndex].variables.toSpliced(to, 0, oldIndexDataError);

            _errorMessageData.criteria[parentIndex].variables = _newDataError;

            props.setLeadsGenerationSettings(_data);
            props.setLeadsGenerationSettingsError(_errorMessageData)
        }

        swapPosition(oldIndex, newIndex);
    }

    let printCriteria = (vCriteria, iCriteria) => {
        return (
            <div className="criteria-wrapper" key={`criteria-${iCriteria}`}>
                <label className={`label-criteria`}>
                    <b>Criteria #{iCriteria + 1}</b>

                    {iCriteria > 0 ? <FiTrash2 onClick={() => { toggleModalDeleteCriteria(iCriteria) }} /> : ""}
                </label>

                <div className="form-content-wrapper input-criteria">
                    <input
                        type="text"
                        className={`input-criteria-text-${iCriteria} ${props.leadsGenerationSettingsError.criteria[iCriteria].text !== "" ? "border-red" : ""}`}
                        onChange={(e) => {
                            let _leadGen = { ...props.leadsGenerationSettings };
                            _leadGen.criteria[iCriteria].text = e.target.value;
                            props.setLeadsGenerationSettings(_leadGen);

                            let _error = { ...props.leadsGenerationSettingsError };
                            _error.criteria[iCriteria].text = "";
                            props.setLeadsGenerationSettingsError(_error);
                        }}
                        value={vCriteria.text}
                        disabled={!props.leadsGenerationSettings.isEnabled}
                        placeholder="E.g. When a customer ask about loyalty program"
                    />

                    <FiChevronUp
                        className={`show-more-criteria ${props.leadsGenerationSettings.criteria[iCriteria].showMore === false ? "show-more-criteria-hide" : ""}`}
                        onClick={() => {
                            let _leadGen = { ...props.leadsGenerationSettings };
                            _leadGen.criteria[iCriteria].showMore = _leadGen.criteria[iCriteria].showMore === undefined ? true : !_leadGen.criteria[iCriteria].showMore;
                            props.setLeadsGenerationSettings(_leadGen);
                        }}
                    />
                </div>

                {props.leadsGenerationSettingsError.criteria[iCriteria].text !== "" &&
                    <ErrorOrInfoComp
                        text={props.leadsGenerationSettingsError.criteria[iCriteria].text}
                        _className="red-text"
                        icon={<FiAlertCircle />}
                    />
                }

                <div className="criteria-switch-wrapper criteria-switch-wrapper-top">
                    <label className="custom-switch-wrapper">
                        <input
                            type="checkbox"
                            checked={vCriteria.isEnabled}
                            onChange={() => {
                                let _leadGen = { ...props.leadsGenerationSettings };
                                _leadGen.criteria[iCriteria].isEnabled = !_leadGen.criteria[iCriteria].isEnabled;
                                props.setLeadsGenerationSettings(_leadGen);
                            }}
                            disabled={!props.leadsGenerationSettings.isEnabled}
                        />
                        <span className="custom-switch round"></span>
                    </label>

                    <p className="switch-label">
                        Enable Criteria

                        <img id={`TooltipEnableCriteria${iCriteria}`} src={FiQuestion} className="img-fi-question" alt="" />
                        <Tooltip
                            isOpen={vCriteria.showTooltipCriteria}
                            target={`TooltipEnableCriteria${iCriteria}`}
                            toggle={() => {
                                let _leadGen = { ...props.leadsGenerationSettings };
                                _leadGen.criteria[iCriteria].showTooltipCriteria = _leadGen.criteria[iCriteria].showTooltipCriteria === undefined ? true : !_leadGen.criteria[iCriteria].showTooltipCriteria;
                                props.setLeadsGenerationSettings(_leadGen);
                            }}
                            placement="right"
                        >
                            Enabling this lets the bot send the questions when criteria are met
                        </Tooltip>
                    </p>
                </div>

                {(vCriteria.showMore === undefined || vCriteria.showMore === true) &&
                    <>
                        <label className={`input-criteria-method-${iCriteria}`}>
                            <b>How do you want to send the questions?</b>
                        </label>

                        <div className={`custom-radio ${props.leadsGenerationSettingsError.criteria[iCriteria].sendQuestionMethod !== "" ? "custom-radio-error" : ""} custom-radio-question ${!props.leadsGenerationSettings.isEnabled ? "custom-radio-disabled" : ""}`}>
                            <input type="radio"
                                id={`at_once-${iCriteria}`}
                                name={`question-radio-${iCriteria}`}
                                value={"at_once"}
                                onChange={(e) => {
                                    let _leadGen = { ...props.leadsGenerationSettings };
                                    _leadGen.criteria[iCriteria].sendQuestionMethod = "at_once";
                                    props.setLeadsGenerationSettings(_leadGen);

                                    let _error = { ...props.leadsGenerationSettingsError };
                                    _error.criteria[iCriteria].sendQuestionMethod = "";
                                    props.setLeadsGenerationSettingsError(_error);
                                }}
                                checked={props.leadsGenerationSettings.criteria[iCriteria].sendQuestionMethod === "at_once"}
                                disabled={!props.leadsGenerationSettings.isEnabled}
                            />
                            <label htmlFor={`at_once-${iCriteria}`}>
                                <div className="question-radio-label">
                                    <p className={``}>All questions at once</p>
                                    <span className={``}>Questions will be sent together in a single form for the user to complete.</span>
                                </div>
                            </label>
                        </div>

                        <div className={`custom-radio ${props.leadsGenerationSettingsError.criteria[iCriteria].sendQuestionMethod !== "" ? "custom-radio-error" : ""} custom-radio-question ${!props.leadsGenerationSettings.isEnabled ? "custom-radio-disabled" : ""}`}>
                            <input type="radio"
                                id={`one_by_one-${iCriteria}`}
                                name={`question-radio-${iCriteria}`}
                                value={"one_by_one"}
                                onChange={(e) => {
                                    let _leadGen = { ...props.leadsGenerationSettings };
                                    _leadGen.criteria[iCriteria].sendQuestionMethod = "one_by_one";
                                    props.setLeadsGenerationSettings(_leadGen);

                                    let _error = { ...props.leadsGenerationSettingsError };
                                    _error.criteria[iCriteria].sendQuestionMethod = "";
                                    props.setLeadsGenerationSettingsError(_error);
                                }}
                                checked={props.leadsGenerationSettings.criteria[iCriteria].sendQuestionMethod === "one_by_one"}
                                disabled={!props.leadsGenerationSettings.isEnabled}
                            />
                            <label htmlFor={`one_by_one-${iCriteria}`}>
                                <div className="question-radio-label">
                                    <p className={``}>One at a time</p>
                                    <span className={``}>Questions will be sent one by one, like a conversation, in the order you set.</span>
                                </div>
                            </label>
                        </div>

                        {props.leadsGenerationSettingsError.criteria[iCriteria].sendQuestionMethod !== "" &&
                            <ErrorOrInfoComp
                                text={props.leadsGenerationSettingsError.criteria[iCriteria].sendQuestionMethod}
                                _className="red-text margin-bottom-16"
                                icon={<FiAlertCircle />}
                            />
                        }

                        <div className="criteria-switch-wrapper">
                            <label className="custom-switch-wrapper">
                                <input
                                    type="checkbox"
                                    checked={vCriteria.shouldSummarize}
                                    onChange={() => {
                                        let _leadGen = { ...props.leadsGenerationSettings };
                                        _leadGen.criteria[iCriteria].shouldSummarize = !_leadGen.criteria[iCriteria].shouldSummarize;
                                        props.setLeadsGenerationSettings(_leadGen);
                                    }}
                                    disabled={!props.leadsGenerationSettings.isEnabled}
                                />
                                <span className="custom-switch round"></span>
                            </label>

                            <p className="switch-label">Confirm and Summarize answers received from this criteria</p>
                        </div>

                        {props.leadsGenerationSettings.criteria[iCriteria].shouldSummarize &&

                            <>
                                <div className="criteria-switch-wrapper">
                                    <label className="custom-switch-wrapper">
                                        <input
                                            type="checkbox"
                                            checked={vCriteria.shouldHandover}
                                            onChange={() => {
                                                let _leadGen = { ...props.leadsGenerationSettings };
                                                _leadGen.criteria[iCriteria].shouldHandover = !_leadGen.criteria[iCriteria].shouldHandover;
                                                props.setLeadsGenerationSettings(_leadGen);
                                            }}
                                            disabled={!props.leadsGenerationSettings.isEnabled}
                                        />
                                        <span className="custom-switch round"></span>
                                    </label>

                                    <p className="switch-label">Handover to agent after bot’s confirmation and summarization</p>
                                </div>

                                {props.leadsGenerationSettings.criteria[iCriteria].shouldHandover &&
                                    <div className={`criteria-topic-wrap ${`input-criteria-topic-${iCriteria}`}`}>
                                        <label>
                                            <b>Topic</b>
                                        </label>
                                        <div className={`input-topic ${props.leadsGenerationSettingsError.criteria[iCriteria].handoverTopicID !== "" ? "border-red-1 border-radius-8" : ""}`}>
                                            <CustomReactSelect
                                                options={props.topicList}
                                                placeholder="Select topic"
                                                onChange={(e) => {
                                                    let _leadGen = { ...props.leadsGenerationSettings };
                                                    _leadGen.criteria[iCriteria].handoverTopicID = e.value;
                                                    props.setLeadsGenerationSettings(_leadGen);

                                                    let _error = { ...props.leadsGenerationSettingsError };
                                                    _error.criteria[iCriteria].handoverTopicID = "";
                                                    props.setLeadsGenerationSettingsError(_error);
                                                }}
                                                classNamePrefix="custom-select-criteria-topic"
                                                isDisabled={!props.leadsGenerationSettings.isEnabled}
                                                value={
                                                    props.topicList.filter(
                                                        (obj) => obj.value === vCriteria.handoverTopicID
                                                    )
                                                }
                                                blurInputOnSelect={true}
                                            />
                                        </div>

                                        {props.leadsGenerationSettingsError.criteria[iCriteria].handoverTopicID !== "" &&
                                            <ErrorOrInfoComp
                                                text={props.leadsGenerationSettingsError.criteria[iCriteria].handoverTopicID}
                                                _className="red-text margin-bottom-16"
                                                icon={<FiAlertCircle />}
                                            />
                                        }
                                    </div>
                                }

                                <div className="criteria-switch-wrapper">
                                    <label className="custom-switch-wrapper">
                                        <input
                                            type="checkbox"
                                            checked={vCriteria.isWebhookEnabled}
                                            onChange={() => {
                                                let _leadGen = { ...props.leadsGenerationSettings };
                                                _leadGen.criteria[iCriteria].isWebhookEnabled = !_leadGen.criteria[iCriteria].isWebhookEnabled;
                                                props.setLeadsGenerationSettings(_leadGen);
                                            }}
                                            disabled={!props.leadsGenerationSettings.isEnabled}
                                        />
                                        <span className="custom-switch round"></span>
                                    </label>

                                    <p className="switch-label">Send summarized data to webhook</p>
                                </div>

                                {props.leadsGenerationSettings.criteria[iCriteria].isWebhookEnabled &&
                                    <div className={`webhook-url-wrap form-content-wrapper ${`input-criteria-webhook-${iCriteria}`}`}>
                                        <label>
                                            <b>Webhook URL</b>
                                        </label>

                                        <div className="webhook-input-text-wrap">
                                            <input
                                                type="text"
                                                value={vCriteria.webhookURL}
                                                onChange={(e) => {
                                                    let _leadGen = { ...props.leadsGenerationSettings };
                                                    _leadGen.criteria[iCriteria].webhookURL = e.target.value;
                                                    props.setLeadsGenerationSettings(_leadGen);

                                                    let _error = { ...props.leadsGenerationSettingsError };
                                                    _error.criteria[iCriteria].webhookURL = "";
                                                    props.setLeadsGenerationSettingsError(_error);
                                                }}
                                                placeholder="Insert webhook URL"
                                                disabled={!props.leadsGenerationSettings.isEnabled}
                                                className={props.leadsGenerationSettingsError.criteria[iCriteria].webhookURL !== "" ? "border-red" : ""}
                                            />

                                            <div
                                                className="test-url-button"
                                                onClick={() => {
                                                    if (!checkStatusWebhookURL) {
                                                        let isPass = true;
                                                        let _error = { ...props.leadsGenerationSettingsError };

                                                        if (vCriteria.webhookURL === "") {
                                                            _error.criteria[iCriteria].webhookURL = "This field is required";
                                                        }

                                                        if (!HelperInput.validURL(vCriteria.webhookURL)) {
                                                            _error.criteria[iCriteria].webhookURL = "Invalid URL format";
                                                        }

                                                        props.setLeadsGenerationSettingsError(_error);

                                                        if (isPass) {
                                                            setCheckStatusWebhookURL(true);
                                                            setStatusWebhookURL("");

                                                            axios.post(vCriteria.webhookURL, vCriteria, {
                                                                timeout: 1800000,
                                                                responseType: 'json',
                                                            })
                                                                .then((response, error) => {
                                                                    setStatusWebhookURL(response.status);

                                                                    setCheckStatusWebhookURL(false);
                                                                })
                                                                .catch(function (error, i) {
                                                                    // let _er = JSON.parse(JSON.stringify(error))

                                                                    setStatusWebhookURL(404);
                                                                    setCheckStatusWebhookURL(false);
                                                                })
                                                        }
                                                    }
                                                }}
                                            >
                                                <b>Test URL</b>
                                            </div>

                                            {(checkStatusWebhookURL || statusWebhookURL !== "") &&
                                                <div className="box-status-url">
                                                    Status:

                                                    {checkStatusWebhookURL &&
                                                        <div className="lds-ring">
                                                            <div /><div /><div /><div />
                                                        </div>
                                                    }

                                                    {statusWebhookURL !== "" &&
                                                        <div className={`box-status-val ${statusWebhookURL === 200 ? "green-background" : "red-background"}`} id={`TooltipStatusURL${iCriteria}`}>
                                                            {statusWebhookURL}{statusWebhookURL === 200 ? " OK" : " Not Found"}
                                                            <FiInfo />

                                                            <Tooltip
                                                                isOpen={vCriteria.showTooltipWebhookURL}
                                                                target={`TooltipStatusURL${iCriteria}`}
                                                                toggle={() => {
                                                                    let _leadGen = { ...props.leadsGenerationSettings };
                                                                    _leadGen.criteria[iCriteria].showTooltipWebhookURL = _leadGen.criteria[iCriteria].showTooltipWebhookURL === undefined ? true : !_leadGen.criteria[iCriteria].showTooltipWebhookURL;
                                                                    props.setLeadsGenerationSettings(_leadGen);
                                                                }}
                                                                placement="right"
                                                            >
                                                                {statusWebhookURL === 200 ?
                                                                    "Request successful. The server has responded"
                                                                    :
                                                                    "Requested resource could not be found."
                                                                }
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {props.leadsGenerationSettingsError.criteria[iCriteria].webhookURL !== "" &&
                                                <ErrorOrInfoComp
                                                    text={props.leadsGenerationSettingsError.criteria[iCriteria].webhookURL}
                                                    _className="red-text margin-bottom-16"
                                                    icon={<FiAlertCircle />}
                                                />
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        }

                        {vCriteria.variables.length > 0 &&
                            <SortableList
                                draggedItemClassName="variable-wrapper-lead-generation form-content-wrapper"
                                onSortEnd={(oldIndex, newIndex) => onSortEnd(iCriteria, oldIndex, newIndex)}
                            >
                                {vCriteria.variables.map((vVariable, iVariable) => {
                                    return (
                                        <SortableItem key={vVariable}>
                                            {printVariable(vVariable, iVariable, iCriteria, vCriteria.variables.length)}
                                        </SortableItem>
                                    )
                                })}
                            </SortableList>
                        }

                        {(props.leadsGenerationSettings.isEnabled) &&
                            <ButtonWithLoadingOrIcon
                                text="Add another variable"
                                onClickAction={() => addAnotherVariable(iCriteria)}
                                className="main-button-26 no-fill-button add-another-variable"
                                icon={{
                                    type: "svg",
                                    src: FiPlus
                                }}
                                position="left"
                            />
                        }
                    </>
                }
            </div>
        )
    }

    return (
        <>
            <PopupRemove
                removeTitle={`Remove Criteria`}
                removeDescription={`Criteria #${showModalCriteria + 1} will be remove, are you sure?`}
                onClickCancel={toggleModalDeleteCriteria}
                onClickRemove={() => removeCriteria(showModalCriteria)}
                additionalContent={""}
                withoutBorder
                waitForResponse={false}
                showModalProps={showModalCriteria !== false ? true : false}
                submitText={'Remove Criteria'}
                colorButton="red-button"
            />

            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={props.leadsGenerationSettingsOrigin}
                dataAfterChange={JSON.stringify(props.leadsGenerationSettings)}
            >
                {/* <div className="nav-button-wrapper">
                    <BackButton
                        onClick={() => {
                            if (leadsGenerationOrigin !== JSON.stringify(props.leadsGenerationSettings)) {
                                setIsShowModalDiscard(true);
                            }
                            else {
                                props.setActiveTab(1);
                            }
                        }}
                        text={"Back to Prompt Settings"}
                    />

                    <NextButton
                        onClick={validateContinue}
                        text={"Continue to Handover Settings"}
                    />
                </div> */}

                <div className="chatgpt-lead-page-wrapper">
                    {isLoadingGetData ?
                        <RetrieveDataLoading
                            isLoading={isLoadingGetData}
                            errorMessage=""
                            errorCode=""
                        />
                        :
                        <>
                            <p className="top-title">
                                <span className="top-subtitle">
                                    Turning this on will allow chatbot to send Leads Generation question created below to gather data of potential clients.
                                </span>

                                {!isLoadingSavedData &&
                                    <div className="gpt-lead-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input
                                                type="checkbox"
                                                checked={props.leadsGenerationSettings.isEnabled}
                                                onChange={() => toggleConfig()}
                                            />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>
                                }
                            </p>

                            <ErrorOrInfoComp
                                text="Lead generation interacts with visitors, gathers their details, and identifies potential customers."
                                icon={<FiInfo />}
                                _className=""
                                noBold
                            />

                            {(props.leadsGenerationSettings.criteria.length > 0) &&
                                <div className="criteria-wrap">
                                    {props.leadsGenerationSettings.criteria.map((v, i) => {
                                        return (
                                            printCriteria(v, i)
                                        )
                                    })}
                                </div>
                            }

                            {(props.leadsGenerationSettings.isEnabled) &&
                                <ButtonWithLoadingOrIcon
                                    text="Add another criteria"
                                    onClickAction={() => addAnotherCriteria()}
                                    className="main-button-26 no-fill-button add-another-variable"
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    position="left"
                                />
                            }

                            <ButtonWithLoadingOrIcon
                                className="no-fill-button main-button-40 button-save save-changes-lead"
                                text="Save Changes"
                                icon={{
                                    type: "svg",
                                    src: FiSave
                                }}
                                position="right"
                                onClickAction={() => {
                                    props.validateSaveLeadGenerationsSettings();
                                    // props.setPendingTab(-1);
                                }}
                            />
                        </>
                    }
                </div>
            </SectionWrap>

            <PopupDiscard
                dataOrigin={props.leadsGenerationSettingsOrigin}
                dataAfterChange={JSON.stringify(props.leadsGenerationSettings)}
                isOpenDiscard={isShowModalDiscard}
                toggleDiscard={toggleModalDiscard}
                discardProgress={() => {
                    setIsShowModalDiscard(false);
                    props.setActiveTab(1);
                }}
                discardDesc={props.discardDesc}
            />
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupLeadGeneration)
