import React from 'react';
import "./CustomTooltip.scss";

let CustomToolip = (props) => {
    let { icon, text, style } = props;

    return (
        <div className={`custom-tooltips`} style={style}>
            {icon}
            
            <div className='inner-tooltip-wrapper'>
                <p>
                    {text}
                </p>
            </div>
        </div>
    );
};

export default CustomToolip;
