import React, { useEffect, useState } from 'react';
import './CaseRatings.scss';
import FilterReportForm from '../FilterReportForm/FilterReportForm';
import CaseRatingShow from './CaseRatingShow/CaseRatingShow';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { checkID } from '../../../helper/HelperGeneral';
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";

const CaseRatings = (props) => {
  let [orgID, setOrgID] = useState(false);
  let [isSubmitedFilter, setIsSubmitedFilter] = useState(false);
  let [filterData, setFilterData] = useState(null);

  useEffect(() => {
    if(filterData) {
      setIsSubmitedFilter(true);
    }
  }, [filterData])

  useEffect(() => {
    doMount();
    let myTopicData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Case Rating Report", 
        {
            userFullName : myTopicData.account.fullName,
            userID : myTopicData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  const doMount = async () => {
    const paramID = checkID(props.match);

    if (paramID) {
        setOrgID(paramID);
    } else {
        ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
                let responseData = response.dataResult.data;
                if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                const { id, createdTime } = responseData.memberships[0].organization;
                const urlOrgID = `${id}-${createdTime}`;
                props.history.push(`/o/${urlOrgID}/organization/members`);
            } else {
                console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
        });
    }
  };

  let submitFilterReport = (data) => {
    setFilterData(data);
  }

  return (
    !isSubmitedFilter ?
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="case-rating-wrapper sectionWrap">
          <React.Fragment>
            <p className="case-rating-title">
              <b>Case Ratings</b>
            </p>
  
            <FilterReportForm 
              {...props} 
              submitFilterReport={submitFilterReport}
              withoutCancel
              withoutGetTopicList
              // showNumberDropdown={50}
            />
          </React.Fragment>
        </div>
      </SectionWrap>
      
      :

      <CaseRatingShow 
        {...props} 
        filterData={filterData}
      />
  );
};

export default CaseRatings;
