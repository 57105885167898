import React, { useState, useEffect } from 'react';
import './IntegrationInstagramDetailAccount.scss';
import { NavLink } from "react-router-dom";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import AutoResolveServices from '../../../../services/newServices/AutoResolveServices';
import AutoUnassignServices from '../../../../services/newServices/AutoUnassignServices';
import IntegrationInstagramServices from '../../../../services/integration/IntegrationInstagramServices';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupRemove from '../../../reuseableComponent/popupRemove/PopupRemove';
import IntegrationAutoMessageDetail from "../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import { FiCopy, FiEdit } from 'react-icons/fi';
import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
import statusDisconnect from '../../../../assets/img/integration/icon-disconnect.svg';
import FacebookIcon from "../../../../assets/img/integration/icon-button-facebook.svg";
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import { connect } from 'react-redux';

let CONNECTED = "connected";

const IntegrationInstagramDetailAccount = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  const [instagramAccountDetail, setInstagramAccountDetail] = useState(null);
  const [instagramChatbot, setInstagramChatbot] = useState(false);
  const [pageList, setPageList] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [fbAccessToken, setFbAccessToken] = useState("");
  const [fbData, setFbData] = useState(false);
  const [loadingRecon, setLoadingRecon] = useState(false);
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [isShowModalRemoveChatbot, setIsShowModalRemoveChatbot] = useState(false);
  const [waitForResponseRemoveChatbot, setWaitForResponseRemoveChatbot] = useState(false);
  const [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(false);
  const [welcomeMessageData, setWelcomeMessageData] = useState(false);
  const [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
  const [awayMessageData, setAwayMessageData] = useState(false);
  const [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
  const [closingMessageData, setClosingMessageData] = useState(false);
  const [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
  const [ratingMessageData, setRatingMessageData] = useState(false);
  let [loadingAutoResolve, setLoadingAutoResolve] = useState(false);
  let [autoResolveData, setAutoResolveData] = useState(false);
  let [errorWelcome, setErrorWelcome] = useState(false);
  let [errorAway, setErrorAway] = useState(false);
  let [errorClosing, setErrorClosing] = useState(false);
  let [errorRating, setErrorRating] = useState(false);
  let [errorChatbot, setErrorChatbot] = useState(false);
  let [errorAutoResolve, setErrorAutoResolve] = useState(false);
  let [loadingAutoUnassign, setLoadingAutoUnassign] = useState(false);
  let [autoUnassignData, setAutoUnassignData] = useState(false);
  let [errorAutoUnassign, setErrorAutoUnassign] = useState(false);

  useEffect(() => {
    if(instagramAccountDetail !== null) {
      getFbData();
    }
  }, [instagramAccountDetail])

  useEffect(() => {
    if(fbData && window.FB) {
        window.FB.init({
            appId: fbData.appID,
            cookie: true,
            xfbml: true,
            version: fbData.graphAPIVersion
        });

        getChatBot();
        // setIsLoadingGetDetailData(false);
    }
  }, [fbData])

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if(orgID) {
      getInstagramAccountDetail();
      runGetWelcomeMessage();
      runGetAwayMessage();
      runGetClosingMessage();
      runGetRatingMessage();
      runGetAutoResolve();
      runGetAutoUnassign();
      
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }, [orgID])

  useEffect(() => {
    if(isConnected) {
        window.FB.api("/me/accounts/", (r) => {
            if(r.data.length > 0) {
                let _list = [];
                r.data.map((val) => {
                    _list.push({
                        value: val.id,
                        label: val.name
                    })

                    return null;
                })

                setPageList(_list);
            } else {
              doToast("Page Not Found", "fail");
            }
        })
    }
  }, [isConnected])

  useEffect(() => {
    if(pageList) {
      let idx = pageList.findIndex(val => val.value === instagramAccountDetail.channel.fbPageID);
      
      if(idx !== -1) {
        reconAction();
      }else {
        doToast("Page Not Found", "fail");
      }
    }
  }, [pageList])

  let toggleModalRemoveChatbot = () => {
    setIsShowModalRemoveChatbot(!isShowModalRemoveChatbot);
  };

  let reconAction = () => {
    setLoadingRecon(true);

    let data = {
      "id": instagramAccountDetail.channel.id,
      "fbAppID": instagramAccountDetail.channel.fbAppID,
      "fbUserAccessToken": fbAccessToken,
      "fbPageID": instagramAccountDetail.channel.fbPageID,
    };

    IntegrationInstagramServices.recon(orgID, data, res => {
      let dataRes = res.dataResult;

      if(dataRes.status === 200) {
        if(dataRes.data.success) {
          doToast(dataRes.data.message);
          getInstagramAccountDetail();
        }else {
          doToast(dataRes.data.message, "fail")
        }
      }else {
        setIsConnected(false);
        doToast(dataRes.error.message, "fail");
      }

      setLoadingRecon(false);
    })
  }

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getFbData = async () => {
    setIsLoadingGetDetailData(true);

    IntegrationInstagramServices.getFB(orgID, {platform: "instagram"}, (response) => {
        let _retriveDataError = {...retriveDataError};
        let dataResult = response.dataResult;
        
        if(response.dataResult.status === 200) {
            // setInstagramAccountData(response.dataResult.data.channels);
            setFbData(dataResult.data);
        }else {
            _retriveDataError.message = dataResult.error.message;
            _retriveDataError.code = dataResult.error.code;
            setRetrieveDataError(_retriveDataError);

            setIsLoadingGetDetailData(false)
        }
        
        // setIsLoadingGetDetailData(false);
    });
  }
  
  let copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  const getChatBot = () => {
    IntegrationInstagramServices.getChatBot(orgID, {channelID: parseInt(match.params.accountID)}, (response) => {
      //  let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setInstagramChatbot(response.dataResult.data);
      } else {
        // setInstagramAccountDetail(null);
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorChatbot({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        })

        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingGetDetailData(false);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  const getInstagramAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: parseInt(props.match.params.accountID)
    }

    IntegrationInstagramServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = {...retriveDataError};
      if (response.dataResult.error.message === "") {
        setInstagramAccountDetail(response.dataResult.data);
      } else {
        setInstagramAccountDetail(null);
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingGetDetailData(false);
      }

      // setIsLoadingGetDetailData(false);
    });
  };

  const runGetWelcomeMessage = () => {
    setLoadingGetWelcomeMessage(true);

    IntegrationInstagramServices.getWelcomeMessage(orgID, props.match.params.accountID, (response) => {
        // let _retriveDataError = {...retriveDataError};
        
        if (response.dataResult.error.message === "") {
            setWelcomeMessageData(response.dataResult.data.welcomeMessage);
        } else {
            // _retriveDataError.message = response.dataResult.error.message;
            // _retriveDataError.code = response.dataResult.error.code;
            // setRetrieveDataError(_retriveDataError);
            setErrorWelcome({
              errorCode: response.dataResult.error.code,
              errorMessage: response.dataResult.error.message
            });
            // doToast(response.dataResult.error.message, 'fail');
        }

        setLoadingGetWelcomeMessage(false);
    });
  }

  const runGetAwayMessage = () => {
    setLoadingGetAwayMessage(true);

    IntegrationInstagramServices.getAwayMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAwayMessageData(response.dataResult.data.awayMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAway({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetAwayMessage(false);
    });
  }

  const runGetClosingMessage = () => {
    setLoadingGetClosingMessage(true);

    IntegrationInstagramServices.getClosingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setClosingMessageData(response.dataResult.data.closingMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorClosing({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetClosingMessage(false);
    });
  }

  const runGetRatingMessage = () => {
    setLoadingGetRatingMessage(true);

    IntegrationInstagramServices.getRatingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setRatingMessageData(response.dataResult.data.userRating);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorRating({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });  
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetRatingMessage(false);
    });
  }

  const removeChatbot = () => {
    setWaitForResponseRemoveChatbot(true);

    let data = {
      connectionID: instagramChatbot.item.id,
      channelID: parseInt(match.params.accountID),
      chatbotID: instagramChatbot.item.chatbotID,
    };
    
    IntegrationInstagramServices.removeChatBot(
      orgID,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message);
            setWaitForResponseRemoveChatbot(false);
          } else {
            doToast(response.dataResult.data.message);
            toggleModalRemoveChatbot();
            setIsLoadingGetDetailData(true);
            setInstagramChatbot(null)
            getInstagramAccountDetail(orgID, match.params.accountID);
          }
        } else {
          doToast(response.dataResult.data.message, 'fail');
          setWaitForResponseRemoveChatbot(false);
        }
      }
    );
  }
  
  const back = () => history.push(`/o/${orgID}/integration/instagram`);

  // const editTopic = (id) => history.push(`/o/${orgID}/integration/instagram/edit-topic/${id}`);
  
  // let goToEditChannelButton = (id) => {
  //   history.push(`/o/${orgID}/integration/instagram/edit-channel-button/${id}`);
  // }

  let connectTo = () => {
    window.FB.login(
        (r) => { 
            setIsConnected(r.status === CONNECTED) 

            if(r.status === CONNECTED) {
                setFbAccessToken(r.authResponse.accessToken);
            }else {
              doToast("FB Error");
              console.log(r);
            }
        }, 
        { scope: 'instagram_manage_messages,pages_manage_metadata,instagram_basic,business_management,business_management', return_scopes: true }
    )
  }

  let renderTopics = () => {
    let text = "";
    
    instagramAccountDetail.channel.topics.map((v, i) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  let runGetAutoResolve = () => {
    setLoadingAutoResolve(true);
  
    AutoResolveServices.getAutoResolve(orgID, "instagram", {id: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAutoResolveData(response.dataResult.data.autoResolveConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoResolve({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }
  
      setLoadingAutoResolve(false);
    });
  }

  let runGetAutoUnassign = () => {
    setLoadingAutoUnassign(true);

    AutoUnassignServices.getAutoUnassign(orgID, "instagram", {id: parseInt(match.params.accountID)}, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAutoUnassignData(response.dataResult.data.autoUnassignAgentConfig);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAutoUnassign({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingAutoUnassign(false);
    });
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-instagram-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? 
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading 
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message} 
              />
            </div>
            :
            <>
              <BackButton text="Back to Instagram DM" onClick={back} />

              <div className="integration-instagram-detail-account-box-1">
                <div className="integration-instagram-detail-account-box-1-top">
                  <b>{instagramAccountDetail.channel.igName === "" ? "@"+instagramAccountDetail.channel.igUsername : instagramAccountDetail.channel.igName}</b>
                  <p className="bot-name">{`@${instagramAccountDetail.channel.igUsername}`}</p>

                  {/* <div className="integration-instagram-detail-account-box-1-top-button"> */}
                    {/* <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "img",
                          src: iconDelete
                        }}
                        className="red-button main-button-40"
                        text="Delete"
                        position="left"
                        onClickAction={() => toggleModalDeleteChannel()}
                    /> */}
                     {/* <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40"
                        text="Edit Topic"
                        position="left"
                        onClickAction={() => editTopic(instagramAccountDetail.channel.id)}
                    /> */}
                  {/* </div> */}
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Channel ID</b>
                  </label>
                  <div className="disabled-form"> 
                    <input type="text"
                      value={instagramAccountDetail.channel.stringID}
                      // onClick={copyToClip}
                      id="copy-string-id"
                      readOnly
                      style={{paddingRight: "40px"}}
                    /> 
            
                    <FiCopy 
                      onClick={() => {
                        copyToClip("copy-string-id", "Channel ID copied")
                      }}
                    />
                  </div>
                </div>
                
                {instagramAccountDetail.channel.topics.length > 0 &&
                  <div className="instagram-detail-topic">
                    <div className="topic-wrapper">
                      <b className="topic-title">Topic</b>
                      <p className="topic-val">
                        <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                      </p>
                    </div>

                    <NavLink to={`/o/${orgID}/integration/instagram/edit-topic/${instagramAccountDetail.channel.id}`}>
                      <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40"
                        text="Edit Topic"
                        position="left"
                      />
                    </NavLink>
                  </div>
                }
                
                <div className="instagram-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title">Facebook Page ID</b>
                    <b>{instagramAccountDetail.channel.fbPageID}</b>
                  </div>
                </div>

                <div className="instagram-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title">Facebook Page Name</b>
                    <b>{instagramAccountDetail.channel.fbPageName}</b>
                  </div>
                </div>

                {/* <ButtonWithLoadingOrIcon 
                    icon={{
                      type: "svg",
                      src: FiEdit
                    }}
                    className="no-fill-button main-button-40"
                    text="Edit Topic"
                    position="left"
                    onClickAction={() => editTopic(instagramAccountDetail.channel.id)}
                /> */}
              
                <div className="instagram-bot-token-status">
                  <div className="bot-status-wrapper">
                    <b className="bot-status-title">Status</b>
                    <div className="status-wrapper">
                      {instagramAccountDetail.channel.isConnected ? (
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-text text-green">Connected</b>
                        </>
                      ) : (
                        <>
                          <img src={statusDisconnect} alt="" />
                          <b className="status-text text-red">Not Connected</b>
                          
                          <br />

                          {!loadingRecon ?
                            <ButtonWithLoadingOrIcon
                              position="left"
                              icon={{
                                type: "img",
                                src: FacebookIcon
                              }}
                              text="Reconnect"
                              onClickAction={connectTo}
                              className="main-button-48 facebook-button"
                            />
                            :
                            <ButtonWithLoadingOrIcon
                              position="left"
                              isLoading
                              loadingColor="gray"
                              isDisabled
                              text="Reconnect"
                              onClickAction={connectTo}
                              className="main-button-48 facebook-button"
                            />
                          }
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* chatbot */}
              <div className="integration-instagram-detail-account-box-1 box-channel-button">
                <div className="integration-instagram-detail-account-box-1-top">
                  <b>Chatbot</b>
                  <p className='bot-name'>
                    Setup Your Chatbot
                  </p>
                  
                  {!errorChatbot &&
                    <div className="integration-instagram-detail-account-box-1-top-button">
                      {instagramChatbot.item.id !== 0 &&
                        <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "img",
                            src: iconDelete
                          }}
                          className="red-button main-button-40 button-channel-detail"
                          text="Remove Chatbot"
                          position="left"
                          onClickAction={() => toggleModalRemoveChatbot()}
                        />
                      }

                      <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Set Chatbot"
                          position="left"
                          onClickAction={() => {
                            history.push({
                              pathname: `/o/${orgID}/integration/instagram/edit-chatbot/${match.params.accountID}`,
                              state: {
                                accountDetail: instagramAccountDetail,
                                chatbot: instagramChatbot.item
                              }
                            })
                          }}
                      />
                    </div>
                  }
                </div>
                
                {errorChatbot ?
                  <RetrieveDataLoading 
                    isLoading={false}
                    errorCode={errorChatbot ? errorChatbot.errorCode : false}
                    errorMessage={errorChatbot ? errorChatbot.errorMessage : false}
                  />
                  :
                  <div className="instagram-detail-topic">
                    <div className="channel-button-wrapper">
                        <b className="channel-button-title">Chatbot Name</b>
                        <br />
                        <b>{instagramChatbot.item.id === 0 ? "Not Set" : instagramChatbot.item.chatbotName}</b>
                    </div>

                    <div className="channel-button-wrapper">
                        <b className="channel-button-title">Chatbot Type</b>
                        <br />
                        <b>{instagramChatbot.item.id === 0 ? "Not Set" : instagramChatbot.item.chatbotTypeName}</b>
                    </div>

                    {/* <ButtonWithLoadingOrIcon

                    /> */}
                  </div>
                }

                <PopupRemove
                  removeTitle={`Remove Chatbot?`}
                  removeDescription={`This chatbot will be removed from this channel.`}
                  onClickCancel={toggleModalRemoveChatbot}
                  onClickRemove={removeChatbot}
                  waitForResponse={waitForResponseRemoveChatbot}
                  showModalProps={isShowModalRemoveChatbot}
                  submitText="Delete"
                  colorButton="red-button"
                />
              </div>
              {/* chatbot */}

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/instagram/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={welcomeMessageData}
                  isLoading={loadingGetWelcomeMessage}
                  type="welcome"
                  error={errorWelcome}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/instagram/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={awayMessageData}
                  isLoading={loadingGetAwayMessage}
                  type="away"
                  error={errorAway}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/instagram/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={closingMessageData}
                  isLoading={loadingGetClosingMessage}
                  type="closing"
                  error={errorClosing}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/instagram/auto-message/setup/${match.params.accountID}`,
                          state: {
                              type: type
                          }
                      })
                  }}
                  data={ratingMessageData}
                  isLoading={loadingGetRatingMessage}
                  type="rating"
                  error={errorRating}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/instagram/auto-resolve-setup/${match.params.accountID}`,
                          state: {
                            type: type,
                            medium: "instagram",
                            backToLink: `/o/${orgID}/integration/instagram/detail/${match.params.accountID}`
                          }
                      })
                  }}
                  data={autoResolveData}
                  isLoading={loadingAutoResolve}
                  type="autoResolve"
                  error={errorAutoResolve}
              />

              <IntegrationAutoMessageDetail 
                  withMarginTop
                  onClickEdit={(type) => {
                      props.history.push({
                          pathname: `/o/${orgID}/integration/instagram/auto-unassign-setup/${match.params.accountID}`,
                          state: {
                            type: type,
                            medium: "instagram",
                            backToLink: `/o/${orgID}/integration/instagram/detail/${match.params.accountID}`
                          }
                      })
                  }}
                  data={autoUnassignData}
                  isLoading={loadingAutoUnassign}
                  type="autoUnassign"
                  error={errorAutoUnassign}
              />
            </>
          }
        </div>
      </SectionWrap>
    </div>
  );
};

const mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

const mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationInstagramDetailAccount);
