import React, { useEffect, useState } from "react";
import "./BroadcastMessageFormImageMessageNew.scss"
import { Modal, ModalBody } from "reactstrap";
import { FiImage, FiX, FiEye, FiHelpCircle } from "react-icons/fi";
import { runImageCompress } from "../../../../helper/HelperGeneral";
import HelperUpload from "../../../../helper/HelperUpload";
import { doToast } from "../../../../helper/HelperGeneral";
import { WHATSAPP_FILE_SIZE_LIMIT } from "../../../../constants/taplive";
import { taptalk, tapCoreMessageManager } from "@taptalk.io/web-sdk";
import ChatService from '../../../../services/chat/caseServices';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Tooltip } from 'reactstrap';

let BroadcastMessageFormImageMessageNew = (props) => {
    let [imagePreview, setImagePreview] = useState([]);
    let [percentageUpload, setPercentageUpload] = useState(false);
    let [showModalImagePreview, setShowModalImagePreview] = useState(false);
    let [isShowModalAddVariable, setIsShowModalAddVariable] = useState(false);
    let [tooltipOpen, setTooltipOpen] = useState(false);

    let toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
      let _fileData = props.broadCastMessageDataProps.messages[props._index].fileData;
    
      if(_fileData) {
        let _imagePreview = [];
        _imagePreview.push(_fileData);
        setImagePreview(_imagePreview);
      }
    }, [props.broadCastMessageDataProps])

    let toggleModalImagePreview = () => {
      setShowModalImagePreview(!showModalImagePreview);
    }

    let doUpload = (file) => {
      let uploadData = {
        file: file.file,
        caption: props.broadCastMessageDataProps.messages[props._index].caption,
        room: ""
      };

      tapCoreMessageManager.uploadChatFile(uploadData, {
        onProgress: (percentage, bytes) => {
          setPercentageUpload(percentage);

          if(percentage === 100) {
            setPercentageUpload(false);
          }
        },

        onSuccess: (response) => {
            if(response) {
                let newFileData = {};
                let arrayFileData = [];
                arrayFileData.push(file);
                // newFileData.fileData = arrayFileData;
                // newFileData.fileName = file.name;
                // newFileData.fileURL = response.fileURL;
                props.onChangeAssetFileProps(newFileData, props._index, response.fileURL);
            }
        },

        onError: (error) => {
          doToast(error, "fail");
          console.log(error);
        }
      })
    }
    
    let taptalkAuthTicket = (file) => {
      ChatService.postRequestAuthTicket(props.orgIDProps, (response) => {
        if (response.dataResult.error.code === "") {
          taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
            onSuccess: () => {
              doUpload(file);
            },
            onError: (errorCode, errorMessage) => {
              doToast(errorMessage, 'fail')
              console.log(errorCode, errorMessage);
            }
          })
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      })
    }

    let runTaptalkRequestAuthTicket = (file) => {
      ChatService.postProjectConfig(props.orgIDProps, (response) => {
        let data = response.dataResult.data.tapTalk;
  
        if (response.dataResult.error.code === "") {
          taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
          taptalkAuthTicket(file);
        } else {
          doToast(response.dataResult.error.message, "fail");
        }
      });
    }

    let runUploadImage = (file) => {
      setPercentageUpload(1);

      if(props.withoutRequestAuthTicketProps) {
        doUpload(file);
      }else {
        runTaptalkRequestAuthTicket(file);
      }
    }

    let onChangeAssetFile = (e) => {
        let files = [];
        files.push(e.target.files[0]);

        HelperUpload.checkFileAllowed(files, {
            onAllowed: () => {
              HelperUpload.checkFileLimit(files, {
                onAllowed: () => {
                  HelperUpload.checkFileSizeLimitForMedia([], files, {
                    onAllowed: (arrayOfMedia) => {
                        // setImagePreview(arrayOfMedia);
                        props.onChangeAssetFileProps({
                          fileData: arrayOfMedia[0],
                          body: "",
                          type: "image",
                          filename: arrayOfMedia[0].name,
                          caption: props.broadCastMessageDataProps.messages[props._index].caption
                        }, props._index);

                        runImageCompress(arrayOfMedia[0], (res) => {
                           runUploadImage(res);
                        })
                        // runUploadImage(arrayOfMedia[0])
                    },
                    onReachLimit: (message) => {
                      doToast(message, "fail");
                    }
                  }, WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))
                },
                onReachLimit: (message) => {
                  doToast(message, "fail");
                }
              })
            },
            onFileTypeDidntMatch: (message) => {
              doToast(message, "fail");
            }
        }, "image")
    
        e.target.value = null;
    }

    let renderModalImageDetail = () => {
      return (
        <Modal className="modal-image-preview" toggle={toggleModalImagePreview} isOpen={showModalImagePreview}>
          <ModalBody>
            <FiX onClick={toggleModalImagePreview} />
            {imagePreview.length > 0 && <img src={imagePreview[0].fileSrc} alt="" />}
          </ModalBody>
        </Modal>
      )
    }

    let removeFileAsset = () => {
      props.onRemoveFileAssetProps(props._index);
      setImagePreview([]);
    }

    let onClickVariable = (variable) => {
      let generateNewString = () => {
        let val = props.broadCastMessageDataProps.messages[props._index].caption;
        let target = document.querySelectorAll(`.caption-${props._index}`)[0];
        let lastCursor = target.selectionStart;
        return val.substring(0, lastCursor)+variable+val.substring(lastCursor, val.length);
      }

      props.onChangeVariableMessageProps(generateNewString(), "caption", props._index)
      setIsShowModalAddVariable(false);
    }

    return (
        <div className="form-image-message-wrapper-new">
            {renderModalImageDetail()}
            <label><b>Image</b> <span>(caption optional)</span></label>

            <br />

            <div className="image-and-caption-wrapper">
                {imagePreview.length > 0 &&
                  <div className={`image-preview-wrapper ${percentageUpload ? "image-preview-wrapper-grey" : ""}`}>
                      {imagePreview.length === 0 ?
                          <FiImage />
                          :
                          <div className="image-preview-frame b">
                              {imagePreview[0] &&
                                <img src={props.broadCastMessageDataProps.messages[props._index].body} alt="" />
                              }
                              
                              {/* {(!imagePreview[0].fileSrc && props.broadCastMessageDataProps.fileAsset.fileURL) &&
                                <img src={props.broadCastMessageDataProps.fileAsset.fileURL} alt="" />
                              } */}

                              {percentageUpload &&
                                <div className="progress-wrapper">
                                  <CircularProgressbar value={percentageUpload} />
                                </div>
                              }
                              
                              {!percentageUpload &&
                                <div className="image-button-wrapper">
                                  <FiEye className="preview-button" onClick={toggleModalImagePreview} />

                                  <div className="close-button-wrapper" onClick={removeFileAsset}>
                                    <FiX className="close-button" />
                                  </div>
                                </div>
                              }
                          </div>
                      }
                  </div>
                }

                <textarea 
                  value={props.broadCastMessageDataProps.messages[props._index].caption}
                  maxLength="4000"
                  placeholder="Enter caption here..." 
                  className={`caption-${props._index} ${imagePreview.length === 0 ? "text-area-default" : ""}`}
                  id="caption"
                  onChange={(e) => props.onChangeBroadcastMessageProps(e, props._index)}
                  disabled={percentageUpload}
                />

                <div className="broadcast-message-action-wrapper">
                  <label htmlFor={`upload-image-${props._index}`} className="button-upload-image">
                    <FiImage />
                    <b>Upload Image</b>
                  </label>
                  <input type="file" id={`upload-image-${props._index}`} accept="image/*" onChange={(e) => onChangeAssetFile(e)} />

                  {/* {imagePreview.length > 0 && */}
                    <div className="broadcast-message-action-inner-wrapper">
                      <b className="add-variable-title">Add Variable <FiHelpCircle href="#" id="TooltipVariable" /></b>
                      <Tooltip placement="right" autohide={false}  isOpen={tooltipOpen} target="TooltipVariable" toggle={toggleTooltip} className="reactstrap-tooltip">
                          Variable can be used to send a dynamic word and replacing the word with recipient's data (ex: name or phone number)
                      </Tooltip>
                      
                      <label className="button-add-var" onClick={() => onClickVariable("{{contact.full_name}}")}>
                          <b>Full Name</b>
                      </label>

                      <label className="button-add-var" onClick={() => onClickVariable("{{contact.alias}}")}>
                          <b>Alias</b>
                      </label>

                      <label className="button-add-var" onClick={() => onClickVariable("{{contact.email}}")}>
                          <b>Email</b>
                      </label>

                      <label className="button-add-var" onClick={() => onClickVariable("{{contact.phone}}")}>
                          <b>Phone Number</b>
                      </label>
                    </div>
                  {/* } */}

                  {isShowModalAddVariable &&
                    <div className="add-variable-panel-wrapper form-content-wrapper">
                        <form 
                            onSubmit={(e) => {
                                // onSubmitVariable(e, index, variableVal);
                                setIsShowModalAddVariable(false);
                            }}
                        >
                            <b className="add-variable-panel-title">Add Variable</b>
                            <FiX className="button-close" onClick={() =>  setIsShowModalAddVariable(false)} />

                            {/* <div className="input-info-wrapper">
                                <FiAlertCircle />
                                <b>Lowercase characters and number only</b>
                            </div> */}

                            <br />

                            <button className="button-variable" onClick={() => onClickVariable("{{contact.full_name}}")}>
                              <b>Full Name</b>
                            </button>

                            <button className="button-variable" onClick={() => onClickVariable("{{contact.alias}}")}>
                              <b>Alias</b>
                            </button>

                            <button className="button-variable" onClick={() => onClickVariable("{{contact.email}}")}>
                              <b>Email</b>
                            </button>

                            <button className="button-variable" onClick={() => onClickVariable("{{contact.phone}}")}>
                              <b>Phone Number</b>
                            </button>
                        </form>
                    </div>
                  }
                </div>
            </div>
        </div>
    )
}

export default BroadcastMessageFormImageMessageNew;