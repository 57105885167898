import React, { useState, useEffect } from 'react';
import "./CustomTextEdito.scss";
import { FiBold, FiItalic, FiCode, FiPlus, FiCornerDownLeft, FiAlertCircle, FiX } from "react-icons/fi";
import iconStraightThrought from "../../../../../../../assets/img/icon-straight-throught.svg";
import HelperInput from "../../../../../../../helper/HelperInput";

let BodyTextEditor = ({
    placeholder,
    id,
    value,
    onChange,
    isTextarea,
    row,
    isHeader,
    index,
    maxLength,
    onSubmitVariable,
    runTextFormat,
    limitVariable,
    _className = "",
    // data = false,
    // removeVariable = false,
    // addVariable = false,
    clearVariable = false,
    isDisabled
    // fullText = ""
}) => {
    let [isShowModalAddVariable, setIsShowModalAddVariable] = useState(false);
    let [variableVal, setVariableVal] = useState("");
    let [isDisabledAddVariable, setIsDisabledAddVariable] = useState(false);

    let toggleModalAddVariable = () => {
        setIsShowModalAddVariable(!isShowModalAddVariable);
        // setVariableVal("");
    }

    let onChangeVariableVal = (e) => {
        if(HelperInput.alphanumericAndUnderscoreOnly(e.target.value.toLowerCase())) {
            setVariableVal(e.target.value.toLowerCase())
        }
    }

    useEffect(() => {
        if(!isShowModalAddVariable) {
            setVariableVal("");
        }
    }, [isShowModalAddVariable])

    
    // variable limit 
    let checkLimitVariable = (str) => {
        // var _data = {...data};
        // var part = id === "headerText" ? "header" : "body";
        var re = /\{\{([^{}]*)\}\}/g;
        var m;
        var match = [];

        do {
            m = re.exec(str);
            if (m) {
                if(m[1] !== "") {
                    match.push(m[1]);
                }
            }
        } while (m);

        // let findVarIndex = (name, i, part) => {
        //     return _data.template[i].sampleParameters[part].findIndex(v => v.name === name);
        // }

        //add var
        // if(match.length <= limitVariable) {
        //     if(addVariable) {
        //         match.map(v => {

        //             if(findVarIndex(v, index, part) === -1 && !HelperInput.isContainUppercase(v) && HelperInput.alphanumericAndUnderscoreOnly(v)) {
        //                 addVariable(part, v, index, value);
        //             }

        //             return null;
        //         })
        //     }
        // }
        //add var

        //remove var
        // if(removeVariable) {
        //     if(_data.template[index].sampleParameters[part].length > 0) {
        //         _data.template[index].sampleParameters[part].map((v) => {
        //             if(match.findIndex(_v => _v === v.name) === -1) {
        //                 removeVariable(part, v.name, index, value);
        //             }

        //             return null;
        //         })
        //     }
        // }
        //remove var
        setIsDisabledAddVariable(match.length >= limitVariable);
    }

    useEffect(() => {
        if(limitVariable) {
            checkLimitVariable(value);
        }else {
            setIsDisabledAddVariable(false);
        }

        if(value === "") {
            clearVariable(id === "headerText" ? "header" : "body", index); 
        }
    }, [value])
    // variable limit 
    
    return (
        <div className="input-text-editor custom-text-editor">
            <span className="grey-font-6 input-counter">
                {value.length}/{maxLength} characters
            </span>

            <div className="form-content-wrapper">
                {isTextarea ?
                    <textarea disabled={isDisabled} id={id} placeholder={placeholder} rows={row} value={value} onChange={(e) => onChange(e, index, id === "headerText" ? "header" : "body")} maxLength={maxLength} className={_className} />
                    :
                    <input disabled={isDisabled} type="text" id={id} placeholder={placeholder} value={value} onChange={(e) => onChange(e, index, id === "headerText" ? "header" : "body")} maxLength={maxLength} className={_className} />
                }

                <div className="text-editor-button-action-wrapper">
                    {!isHeader &&
                        <>
                            <button className="button-action" onClick={() => runTextFormat("bold", index)}>
                                <FiBold />
                            </button>

                            <button className="button-action" onClick={() => runTextFormat("italic", index)}>
                                <FiItalic />
                            </button>

                            <button className="button-action" onClick={() => runTextFormat("strikethrough", index)}>
                                <img src={iconStraightThrought} alt="" />
                            </button>

                            <button className="button-action" onClick={() => runTextFormat("monospace", index)}>
                                <FiCode />
                            </button>
                        </>
                    }

                    <div className="add-variable-wrapper">
                        {(!isDisabledAddVariable && !isDisabled) ?
                            <button className={`button-action add-variable ${isShowModalAddVariable ? "active-add-variable" : ""}`} onClick={toggleModalAddVariable} >
                                <FiPlus />
                                <b>Add Variable</b>
                            </button>
                            :
                            <button className={`button-action add-variable ${isShowModalAddVariable ? "active-add-variable" : ""}`} disabled={true} >
                                <FiPlus />
                                <b>Add Variable</b>
                            </button>
                        }

                        {isShowModalAddVariable &&
                            <div className="add-variable-panel-wrapper form-content-wrapper">
                                <form 
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        
                                        if((value.length + (variableVal.length + 4)) <= maxLength) {
                                            onSubmitVariable(e, index, variableVal);
                                        }

                                        toggleModalAddVariable();
                                    }}
                                >
                                    <b className="add-variable-panel-title">Add Variable</b>
                                    <FiX className="button-close" onClick={toggleModalAddVariable} />

                                    <div className="variable-input">
                                        <input type="text" placeholder="E.g first_name" value={variableVal} onChange={(e) => onChangeVariableVal(e)} className={_className} />

                                        <button className="orange-button" disabled={variableVal === ""}>
                                            <FiCornerDownLeft />
                                            <b>ENTER</b>
                                        </button>
                                    </div>

                                    <div className="input-info-wrapper">
                                        <FiAlertCircle />
                                        <b>Lowercase characters, number, and underscore only</b>
                                    </div>

                                    {/* <ul>
                                        <li>
                                            <b>Letters: </b>lowercase only
                                        </li>
                                        <li>
                                            <b>Numbers: </b>allowed
                                        </li>
                                        <li>
                                            <b>Special characters: </b>not allowed
                                        </li>
                                        <li>
                                            <b>Spaces: </b>not allowed
                                        </li>
                                    </ul> */}
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BodyTextEditor;