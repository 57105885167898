import React from 'react';
import GoogleMapReact from 'google-map-react';
import './ChatRoomMessageOutLocation.scss';
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import MarkerMap from '../../../../assets/img/marker-map.svg';
import MessageDeleteGrey from "../../../../assets/img/icon-notallowed-grey.svg";
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName, isOtherAgent } from '../../../../helper/HelperGeneral';
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageOutLocation = (props) => {
    //   let deleteMessageAction = (message) => {
    //     let messageIDs = [];
    //     messageIDs.push(message.messageID);
    //     tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    //   }
    let {
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        onForwardMessage,
        scrollToReply,
        caseData
    } = props;


    // let messageActionView = (message) => {
    //     let onClickReply = () => {
    //         onReplyMessage(message)
    //     }

    //     return (
    //         <div 
    //             className={`message-action-wrapper with-forward`}
    //         >
    //             <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
    //                 <FiCopy />
    //             </div>

    //             <div className="message-action-button-wrapper reply-button" title="Reply" onClick={onClickReply}>
    //                 <FaReply />
    //             </div>

    //             <div className="message-action-button-wrapper reply-button forward-button" title="Forward" onClick={() => onForwardMessage(message)}>
    //                 <FaReply />
    //             </div>

    //             {/*<div className="message-action-button-wrapper forward-message" title="Forward">
    //                 <FaReply />
    //             </div> */}


    //             {/* <div className="message-action-button-wrapper" title="Delete" onClick={() => deleteMessageAction(message)}>
    //                 <FiTrash2 />
    //             </div> */}
    //         </div>
    //     )
    // }

    let MarkerMapComponent = () => <div className="marker-map"><img src={MarkerMap} alt="" /></div>;

    return (
        <div className="chat-room-message-out-wrapper" id={`message-${singleChatDataProps.localID}`}>
            {singleChatDataProps.isDeleted ?
                <div className="message-out-bubble-location deleted-bubble">
                    <React.Fragment>
                        <img src={MessageDeleteGrey} alt="" className="deleted-icon" />
                        This message was deleted.
                    </React.Fragment>
                </div>
                :

                <div className={`message-out-bubble-location message-out-bubble-location-location ${isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) ? "message-bubble-blue" : ""} ${singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""} ${singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""}`}>
                    {/* {singleChatDataProps.forwardFrom.localID !== "" &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                        </div>
                    }
                    */}

                    {(isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) || (singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization")) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                    <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                    <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                    <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                }

                                {printLastMessageName(singleChatDataProps, caseData, true)}
                            </b>
                        </p>
                    }

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyOutMessage
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                            scrollToReply={scrollToReply}
                        />
                    }

                    <div className="location-wrapper">
                        <a href={`http://maps.google.com?q=${singleChatDataProps.data.latitude} ${singleChatDataProps.data.longitude}`} target="_blank" rel="noopener noreferrer">
                            <div className="location-content"
                            //  style={{borderRadius: props.singleChatDataProps.forwardFrom.localID !== "" ? "0" : "16px 2px 0 0"}}
                            >
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_KEY }}
                                    defaultCenter={{
                                        lat: singleChatDataProps.data.latitude,
                                        lng: singleChatDataProps.data.longitude
                                    }}
                                    defaultZoom={16}
                                    draggable={false}
                                >
                                    <MarkerMapComponent
                                        lat={singleChatDataProps.data.latitude}
                                        lng={singleChatDataProps.data.longitude}
                                    />
                                </GoogleMapReact>
                            </div>
                        </a>
                    </div>

                    <span className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.data.address) }}></span>

                    <p className="message-info">
                        {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}

                        {(singleChatDataProps.isSending && !singleChatDataProps.isRead) &&
                            <img src={AirplaneDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && !singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDoubleDark} alt="" />
                        }

                        {singleChatDataProps.isRead &&
                            <img src={CheckMarkDoubleWhite} alt="" />
                        }

                        {/* {!singleChatDataProps.isDelivered &&
                            <img src={CheckMarkDark} alt="" />
                        } */}

                    </p>
                    {/* {messageActionView(singleChatDataProps)} */}
                    <MessageAction
                        singleChatDataProps={singleChatDataProps}
                        _className="message-action-dropdown-right"
                        replyAction={() => onReplyMessage(singleChatDataProps)}
                        forwardAction={() => onForwardMessage(singleChatDataProps)}
                    />
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutLocation);
