import React, { useState, useEffect } from 'react';
import { FiInfo, FiList, FiUploadCloud, FiX, FiPlus, FiTrash2, FiHelpCircle, FiImage, FiVideo } from "react-icons/fi";
import './CreateMessageTemplateMetaAddCard.scss';
import HelperUpload from '../../../../../../helper/HelperUpload';
import { doToast, scrollToClass } from '../../../../../../helper/HelperGeneral';
import HelperInput from '../../../../../../helper/HelperInput';
import ButtonWithLoadingOrIcon from '../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import ErrorOrInfoComp from '../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import CustomSelectOption from '../../../../../reuseableComponent/customSelectOption/CustomSelectOption';
import CustomTooltip from '../../../../../reuseableComponent/customTooltip/CustomTooltip';
import PopupConfirmation from '../../../../../reuseableComponent/popupConfirmation/PopupConfirmation';
import CustomTextEditor from './customTextEditor/CustomTextEditor';
import IntegrationWhatsappBusinessServices from '../../../../../../services/integration/IntegrationWhatsappBusinessServices';

const MAX_META_CARD_COUNTER = 2;

let IntegrationWabaCreateMessageTemplateMetaAddCard = (props) => {
  let { isShow, toggle, currentActiveMetaCard, createTemplateMessageVal, onSaveChangesMetaCard } = props;

  let [addMetaCard, setAddMetaCard] = useState(false);
  let [addMetaCardError, setAddMetaCardError] = useState([]);
  let [addMetaCardOrigin, setAddMetaCardOrigin] = useState({});
  let [cardStructure, setCardStructure] = useState(false);
  let [cardHeaderFormat, setCardHeaderFormat] = useState("");

  let [isShowModalUpdateTemplate, setIsShowModalUpdateTemplate] = useState(false);
  let [isUploadingFile, setIsUploadingFile] = useState(false);
  let [lastUploadData, setLastUploadData] = useState({
    time: false,
    url: ""
  })
  let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);

  let toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard);
  }

  let toggleModalUpdateTemplate = () => {
    setIsShowModalUpdateTemplate(!isShowModalUpdateTemplate);
  }

  const TEXT_FORMAT = {
    bold: "*",
    italic: "_",
    strikethrough: "~",
    monospace: "```"
  }

  const DEFAULT_BUTTON = {
    typeOfAction: {
      value: "",
      label: ""
    },
    quickReplyPayload: "",
    phoneNumber: "",
    website: "",
    sampleUrlParameters: "",
    websiteType: {
      value: "",
      label: ""
    },
    buttonText: "",
  };

  const DEFAULT_CAROUSEL = {
    media: false,
    body: "",
    sampleParametersMetaBody: [],
    buttons: [
      { ...DEFAULT_BUTTON }
    ]
  };

  const DEFAULT_BUTTON_ERROR = {
    typeOfAction: "",
    phoneNumber: "",
    website: "",
    sampleUrlParameters: "",
    websiteType: "",
    buttonText: "",
    quickReplyPayload: ""
  };

  const DEFAULT_CAROUSEL_ERROR = {
    media: "",
    body: "",
    buttons: [
      { ...DEFAULT_BUTTON_ERROR }
    ]
  };

  useEffect(() => {
    if (lastUploadData.time) {
      let _addMetaCard = { ...addMetaCard };
      _addMetaCard.media.url = lastUploadData.url;
      setAddMetaCard(_addMetaCard);
    }
  }, [lastUploadData])

  useEffect(() => {
    if (isShow) {
      scrollToClass(".meta-card-default-media");

      let _createTemplateMessageVal = { ...createTemplateMessageVal };
      let _err = { ...addMetaCardError };

      let runDefault = (setButtons = false) => {
        let _defCarousel = { ...DEFAULT_CAROUSEL };
        let _defCarouselError = { ...DEFAULT_CAROUSEL_ERROR };

        if (setButtons) {
          _defCarousel.buttons = [];
          _defCarouselError.buttons = [];

          if (_createTemplateMessageVal.template[currentActiveMetaCard.templateIdx].carouselCardstructure) {
            _createTemplateMessageVal.template[currentActiveMetaCard.templateIdx].carouselCardstructure.map((vCardStruc, iCardStruc) => {
              _defCarousel.buttons.push({
                ...DEFAULT_BUTTON
              });

              _defCarouselError.buttons.push({
                ...DEFAULT_BUTTON_ERROR
              })
              _defCarousel.buttons[iCardStruc].typeOfAction = vCardStruc;

              return null;
            })
          }
        }

        setAddMetaCard(_defCarousel);
        setAddMetaCardOrigin(_defCarousel);
        setAddMetaCardError(_defCarouselError);
      }

      if (currentActiveMetaCard.caroulselCardIndex === false) {
        runDefault();
      } else {
        if (createTemplateMessageVal.template[currentActiveMetaCard.templateIdx].carousel[currentActiveMetaCard.caroulselCardIndex]) {
          setCardHeaderFormat({ ..._createTemplateMessageVal }.template[currentActiveMetaCard.templateIdx].carouselCardHeaderFormat);
          setCardStructure({ ..._createTemplateMessageVal }.template[currentActiveMetaCard.templateIdx].carouselCardstructure);
          setAddMetaCard({ ..._createTemplateMessageVal }.template[currentActiveMetaCard.templateIdx].carousel[currentActiveMetaCard.caroulselCardIndex]);
          setAddMetaCardOrigin({ ..._createTemplateMessageVal }.template[currentActiveMetaCard.templateIdx].carousel[currentActiveMetaCard.caroulselCardIndex]);

          _err.buttons = Array({ ..._createTemplateMessageVal }.template[currentActiveMetaCard.templateIdx].carousel[currentActiveMetaCard.caroulselCardIndex].buttons.length).fill({ ...DEFAULT_BUTTON_ERROR });

          setAddMetaCardError(_err);
        } else {
          runDefault(true);

          setCardHeaderFormat({ ..._createTemplateMessageVal }.template[currentActiveMetaCard.templateIdx].carouselCardHeaderFormat);
          setCardStructure({ ..._createTemplateMessageVal }.template[currentActiveMetaCard.templateIdx].carouselCardstructure);
        }
      }
    } else {
      setAddMetaCard(false);
      setAddMetaCardOrigin(false);
      setCardHeaderFormat("");
      setCardStructure(false);
    }
  }, [isShow]);

  const TYPE_OF_ACTION = [
    {
      value: "quick_reply",
      label: "Quick Reply"
    },
    {
      value: "call_phone_number",
      label: "Call Phone Number"
    },
    {
      value: "visit_website",
      label: "Visit Website"
    }
  ];

  const TYPE_OF_URL = [
    {
      value: "static",
      label: "Static"
    },
    {
      value: "dynamic",
      label: "Dynamic"
    },
  ];

  let printButtonsForm = (v, i) => {
    let onSelectTypeOfAction = (e, i) => {
      let _addMetaCard = { ...addMetaCard };
      let _error = { ...addMetaCardError };

      _addMetaCard.buttons[i].typeOfAction = e;
      _addMetaCard.buttons[i].quickReplyPayload = ""
      _addMetaCard.buttons[i].website = "";
      _addMetaCard.buttons[i].sampleUrlParameters = "";
      _addMetaCard.buttons[i].websiteType = {
        value: "",
        label: ""
      }
      _addMetaCard.buttons[i].phoneNumber = "";

      _error.buttons[i].typeOfAction = "";
      _error.buttons[i].quickReplyPayload = "";
      _error.buttons[i].website = "";
      _addMetaCard.buttons[i].sampleUrlParameters = "";
      _error.buttons[i].websiteType = "";
      _error.buttons[i].phoneNumber = "";

      setAddMetaCard(_addMetaCard);
      setAddMetaCardError(_error);
    };

    let onChangeButtonInput = (e, i, type) => {
      let _addMetaCard = { ...addMetaCard };
      let _error = { ...addMetaCardError };

      if (type === "quick-reply") {
        _addMetaCard.buttons[i].quickReplyPayload = e.target.value;
        _error.buttons[i].quickReplyPayload = "";

        if (e.target.value.length > 25) {
          _error.buttons[i].quickReplyPayload = "Characters exceed limit"
        }
      }

      if (type === "button-text") {
        _addMetaCard.buttons[i].buttonText = e.target.value;
        _error.buttons[i].buttonText = "";
      }

      if (type === "button-text") {
        _addMetaCard.buttons[i].buttonText = e.target.value;
        _error.buttons[i].buttonText = "";

        if (e.target.value.length > 25) {
          _error.buttons[i].buttonText = "Characters exceed limit"
        }
      }

      if (type === "phone-number") {
        if (HelperInput.numberOnly(e.target.value.replaceAll(" ", ""))) {
          _addMetaCard.buttons[i].phoneNumber = HelperInput.phoneBeautify(e.target.value.replaceAll(" ", ""));
          _error.buttons[i].phoneNumber = "";
        }
      }

      if (type === "website-url") {
        _addMetaCard.buttons[i].website = e.target.value;
        _error.buttons[i].website = "";

        if (e.target.value.length > 1000) {
          _error.buttons[i].website = "Characters exceed limit"
        }
      }


      setAddMetaCard(_addMetaCard);
      setAddMetaCardError(_error);
    }

    let onSelectURL = (e, i) => {
      let _addMetaCard = { ...addMetaCard };
      let _error = { ...addMetaCardError };

      _addMetaCard.buttons[i].websiteType = e;
      _error.buttons[i].websiteType = "";
      _addMetaCard.buttons[i].sampleUrlParameters = "";
      _error.buttons[i].sampleUrlParameters = "";

      setAddMetaCard(_addMetaCard);
      setAddMetaCardError(_error);
    }

    let onChangeButtonSampleParam = (e, i) => {
      let _addMetaCard = { ...addMetaCard };
      let _error = { ...addMetaCardError };

      _addMetaCard.buttons[i].sampleUrlParameters = e.target.value;
      _error.buttons[i].sampleUrlParameters = "";

      setAddMetaCard(_addMetaCard);
      setAddMetaCardError(_error);
    }

    let onClickRemoveButton = (i) => {
      let _addMetaCard = { ...addMetaCard };
      let _error = { ...addMetaCardError };

      _addMetaCard.buttons.splice(i, 1);
      _error.buttons.splice(i, 1);

      setAddMetaCard(_addMetaCard);
      setAddMetaCardError(_error);
    }

    // let generateTypeOfAction = (index) => {
    //   let idxTypeOfAction = -1;
    //   let newOpt = [];

    //   TYPE_OF_ACTION.map((v, i) => {
    //     idxTypeOfAction = addMetaCard.buttons.findIndex((_v) => _v.typeOfAction.value === v.value);

    //     if (idxTypeOfAction === -1 || idxTypeOfAction === index) {
    //       newOpt.push(v);
    //     } 

    //     return null;
    //   })

    //   return newOpt;
    // }

    return (
      <div key={`buttons-wrap-${i}`} className='buttons-wrapper-meta-card'>
        <label>
          <b>Type of Action</b>
        </label>
        <CustomSelectOption
          optionListProps={TYPE_OF_ACTION}
          valueDropdownProps={addMetaCard.buttons[i].typeOfAction}
          placeholderProps={'None'}
          onClickDropDownListOptionProps={(e) => onSelectTypeOfAction(e, i)}
          specificClassNameProps="custom-select-type-of-action"
          dropdownError={addMetaCardError.buttons[i].typeOfAction}
          _className={`type-of-action-${i} ${addMetaCardError.buttons[i].typeOfAction !== "" ? "border-red" : ""}`}
          isDisabled={false}
        />

        {addMetaCardError.buttons[i].typeOfAction !== "" &&
          <ErrorOrInfoComp
            text={addMetaCardError.buttons[i].typeOfAction}
            _className={`${addMetaCardError.buttons[i].typeOfAction !== "" ? "red-text" : ""}`}
            icon={<FiInfo />}
          />
        }

        <label>
          <b>Button Text</b>

          <span className={`${addMetaCardError.buttons[i].buttonText !== "Characters exceed limit" ? 'grey-font' : 'red-text'}`}>{addMetaCard.buttons[i].buttonText.length}/25 character</span>
        </label>

        <input
          type="text"
          placeholder="Type button text here"
          className={`button-text-${i} ${addMetaCardError.buttons[i].buttonText !== "" ? "border-red" : ""}`}
          value={addMetaCard.buttons[i].buttonText}
          onChange={(e) => onChangeButtonInput(e, i, "button-text")}
          disabled={false}
        />
        {(addMetaCardError.buttons[i].buttonText !== "") &&
          <ErrorOrInfoComp
            text={addMetaCardError.buttons[i].buttonText}
            _className={`${addMetaCardError.buttons[i].buttonText !== "" ? "red-text" : ""}`}
            icon={<FiInfo />}
          />
        }

        {(addMetaCard.buttons[i].typeOfAction.value === "quick_reply") &&
          <>
            <label>
              <b>Payload</b>

              <span className={addMetaCardError.buttons[i].quickReplyPayload === "Characters exceed limit" ? "red-text" : "grey-text"}>{addMetaCard.buttons[i].quickReplyPayload.length}/25 character</span>
            </label>
            <input
              type="text"
              placeholder="Type button text here"
              className={`quick-reply-${i} ${addMetaCardError.buttons[i].quickReplyPayload !== "" ? "border-red" : ""}`}
              value={addMetaCard.buttons[i].quickReplyPayload}
              onChange={(e) => onChangeButtonInput(e, i, "quick-reply")}
              disabled={false}
            />
            {addMetaCardError.buttons[i].quickReplyPayload !== "" &&
              <ErrorOrInfoComp
                text={addMetaCardError.buttons[i].quickReplyPayload}
                _className={`${addMetaCardError.buttons[i].quickReplyPayload !== "" ? "red-text" : ""}`}
                icon={<FiInfo />}
              />
            }
          </>
        }

        {(addMetaCard.buttons[i].typeOfAction.value === "call_phone_number") &&
          <>
            <label>
              <b>Phone Number</b>

              <span className={addMetaCardError.buttons[i].phoneNumber === "Characters exceed limit" ? "red-text" : ""}>{addMetaCard.buttons[i].phoneNumber.length}/20 character</span>
            </label>
            <div className='phone-number-wrapper'>
              <FiPlus />
              <input
                type="text"
                placeholder="62 8123 456 789"
                className={`phone-number-${i} ${addMetaCardError.buttons[i].phoneNumber !== "" ? "border-red" : ""}`}
                value={addMetaCard.buttons[i].phoneNumber}
                onChange={(e) => onChangeButtonInput(e, i, "phone-number")}
                maxLength={20}
                disabled={false}
              />
            </div>
            {addMetaCardError.buttons[i].phoneNumber !== "" &&
              <ErrorOrInfoComp
                text={addMetaCardError.buttons[i].phoneNumber}
                _className={`${addMetaCardError.buttons[i].phoneNumber !== "" ? "red-text" : ""}`}
                icon={<FiInfo />}
              />
            }
          </>
        }

        {(addMetaCard.buttons[i].typeOfAction.value === "visit_website") &&
          <>
            <label>
              <b>URL Type</b>
            </label>
            <CustomSelectOption
              optionListProps={TYPE_OF_URL}
              valueDropdownProps={addMetaCard.buttons[i].websiteType}
              placeholderProps={'Select url type'}
              onClickDropDownListOptionProps={(e) => onSelectURL(e, i)}
              specificClassNameProps="custom-select-url_type"
              dropdownError={addMetaCardError.buttons[i].websiteType}
              _className={`url-type-${i} ${addMetaCardError.buttons[i].websiteType !== "" ? "border-red" : ""}`}
              isDisabled={false}
            />
            {addMetaCardError.buttons[i].websiteType !== "" &&
              <ErrorOrInfoComp
                text={addMetaCardError.buttons[i].websiteType}
                _className={`${addMetaCardError.buttons[i].websiteType !== "" ? "red-text" : ""}`}
                icon={<FiInfo />}
              />
            }

            <label>
              <b>Website URL</b>

              <span className={addMetaCardError.buttons[i].website !== "Characters exceed limit" ? 'grey-font' : 'red-text'}>{addMetaCard.buttons[i].website.length}/1000 character</span>
            </label>

            <div className={`input-param ${addMetaCard.buttons[i].websiteType.value === "dynamic" ? "param-right" : ""}`}>
              {addMetaCard.buttons[i].websiteType.value === "dynamic" &&
                <span className='span-param'>
                  <b>{`{{1}}`}</b>
                </span>
              }
              <input
                type="text"
                placeholder="https://example.com"
                className={`website-url-param-${i} ${addMetaCardError.buttons[i].website !== "" ? "border-red" : ""}`}
                value={addMetaCard.buttons[i].website}
                onChange={(e) => onChangeButtonInput(e, i, "website-url")}
                maxLength={1000}
                disabled={false}
              />
            </div>
            {addMetaCardError.buttons[i].website !== "" &&
              <ErrorOrInfoComp
                text={addMetaCardError.buttons[i].website}
                _className={`${addMetaCardError.buttons[i].website !== "" ? "red-text" : ""}`}
                icon={<FiInfo />}
              />
            }

            {addMetaCard.buttons[i].websiteType.value === "dynamic" &&
              <>
                <label>
                  <b>Add Sample URL</b>

                  <CustomTooltip
                    icon={<FiHelpCircle />}
                    _className=""
                    text="Adding a variable creates a personalised link for the customer to view their info."
                  />
                </label>

                <div className='input-param param-left'>
                  <span className='span-param'>
                    <b>{`{{1}}`}</b>
                  </span>
                  <input
                    type="text"
                    placeholder={`enter URL for ${addMetaCard.buttons[i].website === "" ? "https://example.com/{{1}}" : (`${addMetaCard.buttons[i].website}${addMetaCard.buttons[i].website[addMetaCard.buttons[i].website.length - 1] !== "/" ? "/{{1}}" : "{{1}}"}`)}`}
                    className={`button-sample-param-${i} ${addMetaCardError.buttons[i].sampleUrlParameters !== "" ? "border-red" : ""}`}
                    value={addMetaCard.buttons[i].sampleUrlParameters}
                    onChange={(e) => onChangeButtonSampleParam(e, i)}
                    disabled={false}
                  />
                </div>
                <ErrorOrInfoComp
                  text={addMetaCardError.buttons[i].sampleUrlParameters === "" ? "Only one variable can be added to the end of the URL" : addMetaCardError.buttons[i].sampleUrlParameters}
                  _className={`${addMetaCardError.buttons[i].sampleUrlParameters !== "" ? "red-text" : ""}`}
                  icon={<FiInfo />}
                />
              </>
            }
          </>
        }

        {addMetaCard.buttons.length > 1 &&
          <FiTrash2
            className='button-delete'
            onClick={() => onClickRemoveButton(i)}
          />
        }
      </div>
    )
  }

  let onSaveChanges = (ignoreErrorChecking = false) => {
    let _addMetaCard = { ...addMetaCard };
    let _error = { ...addMetaCardError };
    let errCount = 0;
    let errClass = "";
    let splitBody = _addMetaCard.body.split("\n");

    if (_addMetaCard.media === false) {
      _error.media = "Please select media";
      errCount = errCount + 1;
      errClass = "meta-card-default-media";
    }

    if (_addMetaCard.body === "") {
      _error.body = "This field is required";
      errCount = errCount + 1;
      errClass = "meta-card-body";
    }

    if (splitBody.length > 1) {
      _error.body = "Body text can't contain multiple lines";
      errCount = errCount + 1;
      errClass = "meta-card-body";
    }

    if(_addMetaCard.buttons.length > 0) {
      _addMetaCard.buttons.map((v, i) => {
        if (v.typeOfAction.value === "") {
          _error.buttons[i].typeOfAction = "This field is required";
          errCount = errCount + 1;
          errClass = `type-of-action-${i}`
        }
  
        if (v.typeOfAction.value === TYPE_OF_ACTION[0].value) {
          //quick reply
          if (v.quickReplyPayload === "") {
            _error.buttons[i].quickReplyPayload = "This field is required"
            errCount = errCount + 1;
            errClass = `quick-reply-${i}`
          }
  
          if (v.quickReplyPayload.length > 25) {
            _error.buttons[i].quickReplyPayload = "Characters exceed limit";
            errCount = errCount + 1;
            errClass = `quick-reply-${i}`
          }
        }
  
        if (v.typeOfAction.value === TYPE_OF_ACTION[1].value) {
          //call phone number
          if (v.phoneNumber === "") {
            _error.buttons[i].phoneNumber = "This field is required"
            errCount = errCount + 1;
            errClass = `phone-number-${i}`
          }
        }
  
        if (v.typeOfAction.value === TYPE_OF_ACTION[2].value) {
          //visit website
          if (v.websiteType.value === "") {
            _error.buttons[i].websiteType = "This field is required"
            errCount = errCount + 1;
            errClass = `url-type-${i}`
          }
  
          if (v.websiteType.value === TYPE_OF_URL[1].value) {
            if (v.sampleUrlParameters === "") {
              _error.buttons[i].sampleUrlParameters = "This field is required"
              errCount = errCount + 1;
              errClass = `url-type-${i}`
            }
          }
  
          if (v.website.length > 1000) {
            _error.buttons[i].website = "Characters exceed limit";
            errCount = errCount + 1;
            errClass = `website-url-${i}`
          }
  
          if (!HelperInput.validURL(v.website)) {
            _error.buttons[i].website = "Invalid URL format";
            errCount = errCount + 1;
            errClass = `website-url-${i}`
          }
  
          if (v.website === "") {
            _error.buttons[i].website = "This field is required"
            errCount = errCount + 1;
            errClass = `website-url-${i}`
          }
        }
  
        if (v.buttonText === "") {
          _error.buttons[i].buttonText = "This field is required";
          errCount = errCount + 1;
          errClass = `button-text-${i}`
        }
  
        if (v.buttonText.length > 25) {
          _error.buttons[i].buttonText = "Characters exceed limit";
          errCount = errCount + 1;
          errClass = `button-text-${i}`
        }
  
        return null;
      })
    }

    if(_addMetaCard.sampleParametersMetaBody.length > 0) {
      _addMetaCard.sampleParametersMetaBody.map((v, i) => {
        if(v.value === "") {
          v.error = "This field is required";
          errCount = errCount + 1;
          errClass = `sample-param-meta-${i}`
        }

        return null;
      })

      setAddMetaCard(_addMetaCard)
    }

    if (errCount === 0) {
      let cardHeaderFormatIsDiff = false;
      let cardStructureIsDiff = false;
      let _cardStructure = [];

      if (cardHeaderFormat !== "") {
        cardHeaderFormatIsDiff = cardHeaderFormat !== addMetaCard.media.type.split("/")[0];
      }

      if (cardStructure !== false) {
        addMetaCard.buttons.map((v) => {
          _cardStructure.push(v.typeOfAction);

          return null;
        })

        cardStructureIsDiff = JSON.stringify(cardStructure) !== JSON.stringify(_cardStructure);
      }

      if ((cardStructureIsDiff || cardHeaderFormatIsDiff) && !ignoreErrorChecking) {
        toggleModalUpdateTemplate();
      } else {
        addMetaCard.body = addMetaCard.body.trim();

        onSaveChangesMetaCard(currentActiveMetaCard.templateIdx, currentActiveMetaCard.caroulselCardIndex === false ? 0 : currentActiveMetaCard.caroulselCardIndex, addMetaCard, cardStructureIsDiff, cardHeaderFormatIsDiff);
        toggle();
      }
    } else {
      setAddMetaCardError(_error);
      scrollToClass(`.${errClass}`);
    }
  }

  let addCardButtonClick = () => {
    let _addMetaCard = { ...addMetaCard };
    let _error = { ...addMetaCardError };

    if (_addMetaCard.buttons.length < MAX_META_CARD_COUNTER) {
      _addMetaCard.buttons.push({ ...DEFAULT_BUTTON });
      _error.buttons.push({ ...DEFAULT_BUTTON_ERROR });
    }

    setAddMetaCard(_addMetaCard);
    setAddMetaCardError(_error);
  }

  let doUploadFile = (file) => {
    let type = "";

    if (file.type.split("/")[0] !== "video" && file.type.split("/")[0] !== "image") {
      type = "file";
    } else {
      type = file.type.split("/")[0];
    }

    var formData = new FormData();
    formData.append("fileType", type);
    formData.append("file", file);

    IntegrationWhatsappBusinessServices.uploadFile(props.match.params.orgID, formData, (response) => {
      let dataResult = response.dataResult;

      if (dataResult.error.message === "") {
        setLastUploadData({
          time: new Date().valueOf(),
          url: dataResult.data.fileURL
        });
      } else {
        doToast(dataResult.error.message, "fail");
      }

      setIsUploadingFile(false);
    });
  }

  let onChangeFile = (e) => {
    let file = e.target.files[0];
    let type = file.type.split("/")[0];
    let fullType = file.type;
    let fileError = "File format is not allowed";

    if (fullType === "image/jpeg" || fullType === "image/png" || fullType === "video/mp4" || fullType === "image/3gpp") {
      fileError = "";
    }

    if (fileError === "") {
      if (type === "image" || type === "video") {
        HelperUpload.checkFileSizeLimitForMedia([], e.target.files, {
          onReachLimit: (mess) => {
            doToast(mess, "fail");
          },
          onAllowed: (arrayOfMedia) => {
            let _addMetaCard = { ...addMetaCard };
            let _error = { ...addMetaCardError };

            _addMetaCard.media = arrayOfMedia[0];

            _error.media = "";

            setAddMetaCard(_addMetaCard);
            setAddMetaCardError(_error);
            setIsUploadingFile(true);

            doUploadFile(arrayOfMedia[0]);
          }
        }, type === "image" ? 5242880 : 16777216);
      } else {
        doToast("Only image or video file are allowed", "fail");
      }
    } else {
      doToast(fileError, "fail");
    }
  }

  let printSampleParam = (idx, val) => {
    return (
      <div className={`sample-param-input-wrapper sample-param-meta-${idx}`} key={`sample-meta-${idx}`}>
        <p><b>{`{{${val.name}}}`}</b></p>

        <span className={`max-char-variable ${val.error !== "Characters exceed limit" ? 'grey-font' : 'red-text'}`}>
          {val.value.length}/50
        </span>
        <input
          type="text"
          placeholder={`sample content for {{${val.name}}}`}
          value={val.value}
          onChange={(e) => onChangeSampleParam(idx, e.target.value)}
          disabled={false}
          className={`${val.error !== "" ? 'border-red' : ''}`}
          maxLength={50}
        />

        {val.error !== "" &&
          <ErrorOrInfoComp
            text={val.error}
            icon={<FiInfo />}
            _className="red-text"
          />
        }
      </div>
    )
  }

  let reArrange = (ar) => {
    let orderMethod = () => {
      let run = false;

      if (ar.length > 1) {
        for (let i = 0; i < ar.length; i++) {

          if (i !== (ar.length - 1)) {
            if (ar[i].indexPosition > ar[i + 1].indexPosition) {
              let temp = ar[i + 1];

              ar[i + 1] = ar[i];
              ar[i] = temp;

              run = true;
              break;
            }
          }
        }

        if (run) {
          orderMethod(ar);
        }
      }

      return ar;
    }

    return orderMethod(ar);
  }

  let runSetBodyText = (index, text) => {
    if (text.length <= 160) {
      let _addMetaCard = { ...addMetaCard };
      let _error = { ...addMetaCardError };
      _addMetaCard.body = text;
      _error.body = "";

      setAddMetaCardError(_error);
      setAddMetaCard(_addMetaCard);
    }
  }

  let runTextFormat = (format, index) => {
    let bodyTarget = document.querySelector("#body-add-meta-card");
    let selectStart = bodyTarget.selectionStart;
    var selectFinish = bodyTarget.selectionEnd;
    let _addMetaCard = { ...addMetaCard };
    let _bodyText = _addMetaCard.body;
    let allTextFormat = _bodyText.substring(selectStart, selectFinish);
    let textFormatTrim = allTextFormat.trim();
    let textFormatSplit = allTextFormat.split(textFormatTrim);


    _bodyText = _bodyText.substring(0, selectStart) + TEXT_FORMAT[format] + textFormatTrim + TEXT_FORMAT[format] + (textFormatSplit[0] === textFormatSplit[1] ? "" : textFormatSplit[1]) + _bodyText.substring(selectFinish, _bodyText.length);
    runSetBodyText(index, _bodyText);
  }

  let onAddVariable = (val, index, fullText) => {
    let _addMetaCard = { ...addMetaCard };

    if (_addMetaCard.sampleParametersMetaBody.findIndex(v => v.name === val) === -1) {
      _addMetaCard.sampleParametersMetaBody.push({
        "name": val,
        "value": "",
        "error": ""
      })
    }

    _addMetaCard.sampleParametersMetaBody.map((v) => {
      v.indexPosition = fullText.indexOf(`{{${v.name}}}`);

      return null;
    })

    reArrange(addMetaCard.sampleParametersMetaBody);

    setAddMetaCard(_addMetaCard);
  }

  let onRemoveVariable = (val, i, fullText) => {
    let _addMetaCard = { ...addMetaCard };

    let _findIndex = _addMetaCard.sampleParametersMetaBody.findIndex(v => v.name === val);

    if (_findIndex !== -1) {
      _addMetaCard.sampleParametersMetaBody.splice(_findIndex, 1);
    }

    _addMetaCard.sampleParametersMetaBody.map((v) => {
      v.indexPosition = fullText.indexOf(`{{${v.name}}}`);

      return null;
    })

    reArrange(_addMetaCard.sampleParametersMetaBody);
    setAddMetaCard(_addMetaCard);
  }

  let onClearVariable = () => {
    let _addMetaCard = { ...addMetaCard };

    _addMetaCard.sampleParametersMetaBody = [];

    setAddMetaCard(_addMetaCard);
  }

  let onSubmitVariable = (e, index, val) => {
    e.preventDefault();

    let _addMetaCard = { ...addMetaCard };

    _addMetaCard.body = _addMetaCard.body + "{{" + val + "}}";

    onAddVariable(val, index, _addMetaCard.body);

    setAddMetaCard(_addMetaCard);
  }

  let onChangeSampleParam = (idx, val) => {
    let _addMetaCard = { ...addMetaCard };
    _addMetaCard.sampleParametersMetaBody[idx].value = val;

    _addMetaCard.sampleParametersMetaBody[idx].error = "";

    setAddMetaCard(_addMetaCard);
  }

  let onChangeBodyAddMetaCard = (e, index) => {
    let _addMetaCard = { ...addMetaCard };
    let _error = { ...addMetaCardError };

    ////////////
    let limitVariable = 9999;
    _addMetaCard.body = e.target.value;

    var re = /\{\{([^{}]*)\}\}/g;
    var m;
    var match = [];

    do {
      m = re.exec(e.target.value);
      if (m) {
        if (m[1] !== "") {
          match.push(m[1]);
        }
      }
    } while (m);

    let findVarIndex = (name, i) => {
      return _addMetaCard.sampleParametersMetaBody.findIndex(v => v.name === name);
    }

    //add var
    if (match.length <= limitVariable) {
      if (onAddVariable) {
        match.map(v => {
          if (findVarIndex(v, index) === -1 && !HelperInput.isContainUppercase(v) && HelperInput.alphanumericAndUnderscoreOnly(v)) {
            onAddVariable(v, index, e.target.value);
          }

          return null;
        })
      }
    }
    //add var

    //remove var
    if (onRemoveVariable) {
      if (_addMetaCard.sampleParametersMetaBody.length > 0) {
        _addMetaCard.sampleParametersMetaBody.map((v) => {
          if (match.findIndex(_v => _v === v.name) === -1) {
            onRemoveVariable(v.name, index, e.target.value);
          }

          return null;
        })
      }
    }
    //remove var

    _addMetaCard.sampleParametersMetaBody.map((v) => {
      v.indexPosition = e.target.value.indexOf(`{{${v.name}}}`);

      return null;
    })

    _error.body = "";

    reArrange(_addMetaCard.sampleParametersMetaBody);
    ////////////


    setAddMetaCard(_addMetaCard);
    setAddMetaCardError(_error);
  }

  return (
    <>
      <div className={`add-meta-card-wrapper ${isShow ? "active-add-meta-card" : ""}`}>
        <div className={`add-meta-card-main-wrapper ${isShow ? "active-add-meta-card-main-wrapper" : ""}`}>
          <div
            className="add-meta-card-close"
            onClick={() => {
              toggle();
            }}
          >
            <FiX />
          </div>

          {addMetaCard &&
            <>
              <p className={`title-add-meta-card`}>
                <FiList />
                <b>Card #{currentActiveMetaCard.caroulselCardIndex + 1}</b>

                <p>
                  Fill in your product information to complete your carousel template.
                </p>
              </p>

              <div className='body-add-meta-card'>
                <label className='meta-card-default-media'>
                  <b>Default Media</b>

                  {addMetaCard.media !== false &&
                    <ButtonWithLoadingOrIcon
                      text="Replace Media"
                      icon={{
                        type: "svg",
                        src: FiUploadCloud
                      }}
                      position="left"
                      className="light-orange-button carousel-replace-media main-button-26"
                      onClickAction={() => {
                        let _el = document.querySelector("#meta-media");

                        _el.click();
                      }}
                      isDisabled={isUploadingFile}
                    />
                  }
                </label>

                {addMetaCard.media ?
                  <div className="image-or-video-wrapper">
                    <>
                      {addMetaCard.media.type.split("/")[0] === "image" ?
                        <>
                          {/* <img src={addMetaCard.media.url ? addMetaCard.media.url : addMetaCard.media.fileSrc} alt="" /> */}
                          <img src={addMetaCard.media.url ? addMetaCard.media.url : window.URL.createObjectURL(addMetaCard.media)} alt="" />
                          {isUploadingFile ?
                            <div className='lds-ring'>
                              <div></div><div></div><div></div><div></div>
                            </div>
                            :
                            <FiImage />
                          }
                        </>
                        :
                        <>
                          <video src={addMetaCard.media.url ? addMetaCard.media.url : addMetaCard.media.fileSrc} />
                          {/* <video src={window.URL.createObjectURL(addMetaCard.media)} /> */}
                          {isUploadingFile ?
                            <div className='lds-ring'>
                              <div></div><div></div><div></div><div></div>
                            </div>
                            :
                            <FiVideo />
                          }
                        </>
                      }
                    </>
                  </div>
                  :
                  <ButtonWithLoadingOrIcon
                    position="left"
                    text="Upload Media"
                    className="main-button-48 light-orange-button button-upload-media"
                    icon={{
                      type: "svg",
                      src: FiUploadCloud
                    }}
                    onClickAction={() => {
                      let _el = document.querySelector("#meta-media");

                      _el.click();
                    }}
                  />
                }

                <input type="file" onChange={(e) => onChangeFile(e)} onClick={(e) => { e.currentTarget.value = "" }} id={"meta-media"} accept={`image/*,video/*`} />

                {addMetaCardError.media !== "" &&
                  <ErrorOrInfoComp
                    text={addMetaCardError.media}
                    _className="red-text"
                    icon={<FiInfo />}
                  />

                }

                <ErrorOrInfoComp
                  text={`
                    Allowed type: image and video (all cards must have the same media type) 
                    <br />
                    Allowed format: image/jpeg, image/png, video/3gpp, video/mp4
                    <br /> 
                    Maximum upload size for image: 5MB/video: 16MB 
                    <br /> 
                    This media may be used as default sending message.`}
                  _className=""
                  icon={<FiInfo />}
                />

                <label className='meta-card-body'>
                  <b>Card Body</b>
                </label>

                <CustomTextEditor
                  placeholder="Enter message..."
                  id="body-add-meta-card"
                  value={addMetaCard.body}
                  onChange={onChangeBodyAddMetaCard}
                  index={0}
                  isTextarea
                  row={7}
                  maxLength={160}
                  onSubmitVariable={onSubmitVariable}
                  runTextFormat={runTextFormat}
                  limitVariable={9999}
                  data={addMetaCard}
                  addVariable={onAddVariable}
                  removeVariable={onRemoveVariable}
                  clearVariable={onClearVariable}
                  _className={`body-text-0 ${addMetaCardError.body === "" ? "" : "border-red"}`}
                  isDisabled={false}
                />

                {addMetaCardError.body !== "" &&
                  <ErrorOrInfoComp
                    text={addMetaCardError.body}
                    _className={"red-text"}
                    icon={<FiInfo />}
                  />
                }

                {addMetaCard.sampleParametersMetaBody.map((v, i) => {
                  return (
                    printSampleParam(i, v)
                  )
                })}

                <label>
                  <b>Buttons</b>
                </label>

                <div className='buttons-wrappers'>
                  {addMetaCard.buttons.map((v, i) => {
                    return (
                      printButtonsForm(v, i)
                    )
                  })}
                </div>

                {addMetaCard.buttons.length < MAX_META_CARD_COUNTER &&
                  <p className='orange-text add-card-button' onClick={addCardButtonClick}>
                    <FiPlus />

                    <b>Add Card Button</b>
                  </p>
                }
              </div>

              <div className='foot-add-meta-card'>
                <ButtonWithLoadingOrIcon
                  text="Cancel"
                  className="main-button-48 no-fill-button"
                  onClickAction={() => {
                    if (JSON.stringify(addMetaCardOrigin) === JSON.stringify(addMetaCard)) {
                      toggle();
                    } else {
                      toggleModalDiscard();
                    }
                  }}
                />

                <ButtonWithLoadingOrIcon
                  text="Save Changes"
                  className="main-button-48 orange-button"
                  onClickAction={() => {
                    if (!isUploadingFile) {
                      onSaveChanges();
                    }
                  }}
                  isDisabled={isUploadingFile}
                />
              </div>
            </>
          }


        </div>
      </div>

      <PopupConfirmation
        isOpen={isShowModalUpdateTemplate}
        toggle={toggleModalUpdateTemplate}
        title="Update Button"
        description="Updating this will apply changes to all associated cards. Are you sure you want to continue? This cannot be undone."
        className="modal-confirmation-update-template modal-conformation-carousel-card"
      >
        <ButtonWithLoadingOrIcon
          text="Confirm"
          className="main-button-48 orange-button"
          onClickAction={() => {
            toggleModalUpdateTemplate();
            onSaveChanges(true);
          }}
        />
      </PopupConfirmation>

      <PopupConfirmation
        isOpen={isShowModalDiscard}
        toggle={toggleModalDiscard}
        title="Discard Progress?"
        description="You have unsaved progress on this page. Are you sure you want to discard your progress?"
        className="modal-confirmation-update-template modal-conformation-carousel-card"
      >
        <ButtonWithLoadingOrIcon
          text="Discard Progress"
          className="main-button-48 red-button"
          onClickAction={() => {
            toggleModalDiscard();
            toggle();
          }}
        />
      </PopupConfirmation>
    </>
  );
};

export default IntegrationWabaCreateMessageTemplateMetaAddCard;
