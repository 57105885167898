import React, { useState, useEffect } from "react";
import "./UTMTab2.scss";
import { FiInfo } from "react-icons/fi";
import FilterReportForm from '../../FilterReportForm/FilterReportForm';
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperQueryString from "../../../../helper/HelperQueryString";
import { checkID } from '../../../../helper/HelperGeneral';
import mixpanel from "mixpanel-browser";
import UTMTab2Show from "./UTMTab2Show/UTMTab2Show";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";

let UTMTab2 = (props) => {
    // let [orgID, setOrgID] = useState(false);
    let [isSubmitedFilter, setIsSubmitedFilter] = useState(false);
    let [filterData, setFilterData] = useState(null);
    let [filterGroupBy, setFilterGroupBy] = useState([]);
    let [errorGroupBy, setErrorGroupBy] = useState("");

    useEffect(() => {
        if (filterData) {
            setIsSubmitedFilter(true);
        }
    }, [filterData])

    useEffect(() => {
        doMount();
        let myTopicData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Referrer & UTM - UTM",
                {
                    userFullName: myTopicData.account.fullName,
                    userID: myTopicData.account.id,
                    organizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }

        if(props.location.search !== "") {
            let qs = HelperQueryString.parseQuerystring();
            setFilterGroupBy([qs["group_by"]]);
        }
    }, []);

    let doMount = async () => {
        let paramID = checkID(props.match);

        if (paramID) {
            // setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    let { id, createdTime } = responseData.memberships[0].organization;
                    let urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let submitFilterReport = (data) => {
        if (filterGroupBy.length === 0) {
            setErrorGroupBy("This field is required");
        } else {
            setFilterData(data);
        }
    }

    let groupBy = [
        {
            label: "Source",
            value: "source"
        },
        {
            label: "Medium",
            value: "medium"
        },
        {
            label: "Campaign",
            value: "campaign"
        },
        {
            label: "Term",
            value: "term"
        },
        {
            label: "Content",
            value: "content"
        },
    ]

    let changeCheck = (e, _valCheck) => {
        setErrorGroupBy("");
        let val = e.target.checked;
        let _filterGroupBy = filterGroupBy.slice();

        if (val) {
            _filterGroupBy.push(_valCheck);
        } else {
            let _find = _filterGroupBy.findIndex(_v => _v === _valCheck);

            if (_find !== -1) {
                _filterGroupBy.splice(_find, 1);
            }
        }

        setFilterGroupBy(_filterGroupBy)
    }


    let changeSelectAll = (e) => {
        setErrorGroupBy("");
        let val = e.target.checked;
        let _filterGroupBy = filterGroupBy.slice();

        if (val) {
            let _arr = [];

            groupBy.map(v => {
                _arr.push(v.value);

                return null;
            })

            _filterGroupBy = _arr;
        } else {
            _filterGroupBy = [];
        }

        setFilterGroupBy(_filterGroupBy)
    }

    return (
        !isSubmitedFilter ?
            <div className="utm-wrapper sectionWrap">
                <React.Fragment>
                    <p className="utm-title">
                        <b>UTM Report</b>
                    </p>

                    <FilterReportForm
                        {...props}
                        submitFilterReport={submitFilterReport}
                        withoutCancel
                        withoutGetTopicList
                        // showNumberDropdown={20}
                        _className={"with-children"}
                        customDate={props.location.search === "" ? 
                            false 
                            : 
                            {
                                from: HelperQueryString.parseQuerystring()["start_date"],
                                to: HelperQueryString.parseQuerystring()["end_date"]
                            }
                        }
                    >
                        <div className="group-by-wrapper">
                            <b>Group By</b>

                            <div className={`checkbox-wrapper`}>
                                <div className={`${groupBy.length === filterGroupBy.length ? "custom-checkbox" : "custom-checkbox-stripe"} select-all-group`}>
                                    <input type="checkbox" id="select-all-group" checked={filterGroupBy.length > 0} onChange={(e) => changeSelectAll(e)} />
                                    <label htmlFor="select-all-group">
                                        Select All
                                    </label>
                                </div>

                                {groupBy.map((v, i) => {
                                    return (
                                        <div className={`custom-checkbox`} key={`select-all-group-${i}`}>
                                            <input type="checkbox" id={`select-all-group-${v.value}`} checked={filterGroupBy.findIndex(_v => _v === v.value) !== -1} onChange={(e) => changeCheck(e, v.value)} />
                                            <label htmlFor={`select-all-group-${v.value}`}>
                                                {v.label}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {errorGroupBy !== "" &&
                            <ErrorOrInfoComp
                                _className={"font-red"}
                                icon={<FiInfo />}
                                text={errorGroupBy}
                            />
                        }
                    </FilterReportForm>
                </React.Fragment>
            </div>

            :

            <UTMTab2Show
                {...props}
                filterData={filterData}
                _filterGroupBy={filterGroupBy.slice()}
            />
    )
}

export default UTMTab2;