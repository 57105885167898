import React, { useState } from 'react';
import './ChatRoomMessageOutBroadcast.scss';
import { Modal, ModalBody } from "reactstrap";
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import BroadcastGrey from '../../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg';
import MessageDeleteGrey from "../../../../assets/img/icon-notallowed-grey.svg";
import { FiInfo } from 'react-icons/fi';
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName, isOtherAgent } from '../../../../helper/HelperGeneral';
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';
import { TAPLIVE_MEDIUM } from '../../../../constants/taplive';
import { connect } from 'react-redux';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageOutBroadcast = (props) => {
    let [modalInfo, setModalInfo] = useState(false)

    //   let messageActionView = (message) => {
    //     let onClickReply = () => {
    //         props.onReplyMessage(message)
    //     }

    //     return (
    //         <div 
    //             className={`message-action-wrapper`}
    //         >  
    //               <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
    //                   <FiCopy />
    //               </div>

    //               <div className="message-action-button-wrapper reply-button" title="Reply"  onClick={() => onClickReply()}>
    //                   <FaReply />
    //               </div>
    //          </div>
    //     )
    //   }

    return (
        <div className="chat-room-message-out-broadcast-wrapper" id={`message-${props.singleChatDataProps.localID}`}>
            <Modal
                className="modal-templated-message"
                isOpen={modalInfo}
            >
                <ModalBody>
                    <div>
                        <FiInfo />
                        <b>This is a templated message</b>
                    </div>
                    <p>
                        Templated messages are messages that has been saved and approved by WhatsApp officially and cannot be altered.
                    </p>

                    <button
                        className="orange-button main-button-40"
                        onClick={() => setModalInfo(false)}
                    >
                        Dismiss
                    </button>
                </ModalBody>
            </Modal>

            {props.singleChatDataProps.isDeleted ?
                <div className="message-out-bubble-broadcast deleted-bubble">
                    <React.Fragment>
                        <img src={MessageDeleteGrey} alt="" className="deleted-icon" />
                        message was deleted.
                    </React.Fragment>
                </div>
                :

                <div className={`message-out-bubble-broadcast ${isOtherAgent(props.singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) ? "message-bubble-blue" : ""} ${props.singleChatDataProps.user.userRole.code === "chatbot" || props.singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""}`}>
                    {/* {props.singleChatDataProps.forwardFrom.localID !== "" &&
                    <div className="forwarded-message">
                        <b>Forwarded</b>
                        <br />
                        From: <b>{props.singleChatDataProps.forwardFrom.fullname}</b>
                    </div>
                } */}
                    {(isOtherAgent(props.singleChatDataProps.user.xcUserID.replace("agent:", ""), props.caseData) || (props.singleChatDataProps.user.userRole.code === "chatbot" || props.singleChatDataProps.user.userRole.code === "organization")) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                {props.singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                    <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                }

                                {props.singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                    <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                }

                                {props.singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                    <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                }

                                {printLastMessageName(props.singleChatDataProps, props.caseData, true)}
                            </b>
                        </p>
                    }

                    <div className="message-title">
                        <img src={BroadcastGrey} alt="" />
                        <b>Broadcasted Message</b>
                    </div>

                    <span className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(props.singleChatDataProps.body) }}></span>
                    <p className="message-info">
                        {Helper.getDateMonthYear(props.singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(props.singleChatDataProps.created)}

                        {(props.singleChatDataProps.isSending && !props.singleChatDataProps.isRead) &&
                            <img src={AirplaneDark} alt="" />
                        }

                        {(!props.singleChatDataProps.isSending && !props.singleChatDataProps.isDelivered && !props.singleChatDataProps.isRead) &&
                            <img src={CheckMarkDark} alt="" />
                        }

                        {(!props.singleChatDataProps.isSending && props.singleChatDataProps.isDelivered && !props.singleChatDataProps.isRead) &&
                            <img src={CheckMarkDoubleDark} alt="" />
                        }

                        {props.singleChatDataProps.isRead &&
                            <img src={CheckMarkDoubleWhite} alt="" />
                        }

                        {/* {!props.singleChatDataProps.isDelivered &&
                        <img src={CheckMarkDark} alt="" />
                    } */}
                    </p>

                    {props.medium === TAPLIVE_MEDIUM.whatsappba &&
                        <div className="message-out-template-banner" onClick={() => { setModalInfo(true) }}>
                            <FiInfo />
                            {/* <div> */}
                            <p>This is a templated message</p>
                            {/* </div> */}
                        </div>
                    }

                    {/* {messageActionView(props.singleChatDataProps)} */}
                    <MessageAction
                        singleChatDataProps={props.singleChatDataProps}
                        _className="message-action-dropdown-right"
                        replyAction={() => props.onReplyMessage(props.singleChatDataProps)}
                        hideForward
                    />
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutBroadcast);
