import React, { useState, useEffect } from 'react';
import './CaseReport.scss'
import CustomSelectOption from '../../components/reuseableComponent/customSelectOption/CustomSelectOption'
import taptalk from '../../assets/img/case-report/taptalk.svg'
import iconReport from '../../assets/img/case-report/icon-report.svg'
// import iconDownload from '../../assets/img/case-report/icon-download.svg'
import ServiceOrganization from '../../services/organization/ServiceOrganization';
import CaseReportService from '../../services/newServices/CaseReportService'
import { checkID, doToast } from '../../helper/HelperGeneral';
import { AiOutlineDownload, AiOutlineWarning } from 'react-icons/ai'
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalBody } from 'reactstrap';
// import Loading from '../reuseableComponent/loading/Loading'
import ButtonWithLoadingOrIcon from '../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'

const monthList = [
  {
    label: 'January',
    value: 1
  },
  {
    label: 'February',
    value: 2
  },
  {
    label: 'March',
    value: 3
  },
  {
    label: 'April',
    value: 4
  },
  {
    label: 'May',
    value: 5
  },
  {
    label: 'June',
    value: 6
  },
  {
    label: 'July',
    value: 7
  },
  {
    label: 'August',
    value: 8
  },
  {
    label: 'September',
    value: 9
  },
  {
    label: 'October',
    value: 10
  },
  {
    label: 'November',
    value: 11
  },
  {
    label: 'December',
    value: 12
  }
]

const CaseReport = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingGenerateReport, setIsLoadingGenerateReport] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [showModalFailed, setShowModalFailed] = useState(false);

  useEffect(() => {
    doMount();
  }, []);

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/home`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const downloadReport = (data) => {
    const id = orgID.split('-');
    const timestamp = + new Date();
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Case_Report-${id[0]}-${yearVal.label}-${monthVal.value}-${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const generateReport = () => {
    setIsLoadingGenerateReport(true);
    setIsInputDisabled(true);
    setIsButtonDisabled(true);

    CaseReportService.getCaseReport(
      orgID,
      monthVal.value,
      yearVal.value,
      (response) => {
        setIsLoadingGenerateReport(false);
        setIsInputDisabled(false);
        setIsButtonDisabled(false);

        if (response.dataResult.error) {
          doToast(response.dataResult.error.message, "fail");
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  const [monthVal, setMonthVal] = useState({
    label: '',
    value: '',
  })
  const [yearVal, setYearVal] = useState({
    label: '',
    value: '',
  })

  const onSelectMonth = (value) => {
    setMonthVal(value);
  };

  const onSelectYear = (value) => {
    setYearVal(value);
  };

  useEffect(() => {
    if (monthVal.value !== '' && yearVal.value !== '') {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [monthVal, yearVal])

  const generateYear = () => {
    const now = new Date().getFullYear();
    const start = 2020
    return Array(now - start + 1).fill('').map((v, idx) => now - idx);
  }

  const yearList = generateYear().map((item) => {
    return {
      label: item,
      value: item
    }
  })

  const closeModalFailed = () => {
    setShowModalFailed(false)
  }

  const renderModal = () => {
    return (
      <Modal isOpen={showModalFailed} toggle={closeModalFailed} className={"failed-modal"}>
        <ModalBody>
          <div className="confirm-modal-wrapper">
            <AiOutlineWarning />
            <p className="confirm-description">Failed to download. Your connection was interrupted.</p>
            <ButtonWithLoadingOrIcon
              className="orange-button main-button-40 width-100"
              text="Dismiss"
              onClickAction={closeModalFailed}
            />
          </div>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <div className="case-report-container">
      {renderModal()}
      <img src={taptalk} alt="TapTalk" className="case-logo" />
      <div className="case-report-title-wrapper">
        <div className="icon-wrapper">
          <img src={iconReport} alt="" />
        </div>
        <h1>Case Report</h1>
      </div>
      <p className="case-description">Case report includes details on every case that is recieved by OneTalk’s inbox, including updated and accumulated case data from each month.</p>
      <div className="case-report-input-container">
        <div className="month-select">
          <label>
            <b>Month</b>
          </label>
          <CustomSelectOption
            optionListProps={monthList}
            valueDropdownProps={monthVal}
            placeholderProps={'Select Month'}
            onClickDropDownListOptionProps={onSelectMonth}
            specificClassNameProps="custom-select-month"
            isDisabled={isInputDisabled}
          />
        </div>
        <div className="year-select">
          <label>
            <b>Year</b>
          </label>
          <CustomSelectOption
            optionListProps={yearList}
            valueDropdownProps={yearVal}
            placeholderProps={'Select Year'}
            onClickDropDownListOptionProps={onSelectYear}
            specificClassNameProps="custom-select-year"
            isDisabled={isInputDisabled}
          />
        </div>
        {
          isLoadingGenerateReport ?
            <ButtonWithLoadingOrIcon
              isLoading
              loadingColor="gray"
              isDisabled
              className="orange-button main-button-40 width-100"
              text="Download Report"
              position="left"
            />
            :
            <ButtonWithLoadingOrIcon
              className="orange-button main-button-40 width-100"
              text="Download Report"
              position="left"
              onClickAction={generateReport}
              icon={{
                type: "svg",
                src: AiOutlineDownload
              }}
              isDisabled={isButtonDisabled}
            />
        }
      </div>
    </div>
  );
};

export default CaseReport;