import React, { useEffect, useState } from "react";
import "./BroadcastMessageFormMediaMessage.scss"
import { Modal, ModalBody } from "reactstrap";
import { FiImage, FiX, FiEye } from "react-icons/fi";
import { runImageCompress } from "../../../../helper/HelperGeneral";
import HelperUpload from "../../../../helper/HelperUpload";
import { doToast } from "../../../../helper/HelperGeneral";
import { WHATSAPP_FILE_SIZE_LIMIT } from "../../../../constants/taplive";
import { taptalk, tapCoreMessageManager } from "@taptalk.io/web-sdk";
import ChatService from '../../../../services/chat/caseServices';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

let BroadcastMessageFormMediaMessage = (props) => {
    let [mediaPreview, setMediaPreview] = useState([]);
    let [percentageUpload, setPercentageUpload] = useState(false);
    let [showModalMediaPreview, setShowModalMediaPreview] = useState(false);

    useEffect(() => {
      if(props.broadCastMessageDataProps.fileData) {
        let _fileData = props.broadCastMessageDataProps.fileData;
        let _mediaPreview = [];
        _mediaPreview.push(_fileData);
        setMediaPreview(_fileData);

        if(props.broadCastMessageDataProps.fileURL === "" && props.withoutRequestAuthTicketProps) {
          runUploadImage(props.broadCastMessageDataProps.fileData[0]);
        }
      }
    }, [props.broadCastMessageDataProps])

    let toggleModalMediaPreview = () => {
      setShowModalMediaPreview(!showModalMediaPreview);
    }

    let doUpload = (file) => {
      let uploadData = {
        file: props.messageType === 'video' ? file : file.file,
        caption: props.broadCastMessageDataProps.caption,
        room: ""
      };

      tapCoreMessageManager.uploadChatFile(uploadData, {
        onProgress: (percentage, bytes) => {
          setPercentageUpload(percentage);

          if(percentage === 100) {
            setPercentageUpload(false);
          }
        },

        onSuccess: (response) => {
            if(response) {
                let newFileData = {};
                let arrayFileData = [];
                arrayFileData.push(file);
                newFileData.fileData = arrayFileData;
                newFileData.fileName = file.name;
                newFileData.fileURL = response.fileURL;
                props.onChangeAssetFileProps(newFileData);
            }
        },

        onError: (error) => {
          doToast(error, "fail");
          console.log(error);
        }
      })
    }
    
    let taptalkAuthTicket = (file) => {
      ChatService.postRequestAuthTicket(props.orgIDProps, (response) => {
        if (response.dataResult.error.code === "") {
          taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
            onSuccess: () => {
              doUpload(file);
            },
            onError: (errorCode, errorMessage) => {
              doToast(errorMessage, 'fail')
              console.log(errorCode, errorMessage);
            }
          })
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      })
    }

    let runTaptalkRequestAuthTicket = (file) => {
      ChatService.postProjectConfig(props.orgIDProps, (response) => {
        let data = response.dataResult.data.tapTalk;
  
        if (response.dataResult.error.code === "") {
          taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
          taptalkAuthTicket(file);
        } else {
          doToast(response.dataResult.error.message, "fail");
        }
      });
    }

    let runUploadImage = (file) => {
      setPercentageUpload(1);

      if(props.withoutRequestAuthTicketProps) {
        doUpload(file);
      }else {
        runTaptalkRequestAuthTicket(file);
      }
    }

    let onChangeAssetFile = (e) => {
        let files = [];
        files.push(e.target.files[0]);

        HelperUpload.checkFileAllowed(files, {
            onAllowed: () => {
              HelperUpload.checkFileLimit(files, {
                onAllowed: () => {
                  HelperUpload.checkFileSizeLimitForMedia([], files, {
                    onAllowed: (arrayOfMedia) => {
                        // setMediaPreview(arrayOfMedia);
                        props.onChangeAssetFileProps({
                          fileName: "",
                          fileURL: "",
                          fileData: arrayOfMedia
                        });

                        runImageCompress(arrayOfMedia[0], (res) => {
                           runUploadImage(res);
                        })
                        // runUploadImage(arrayOfMedia[0])
                    },
                    onReachLimit: (message) => {
                      doToast(message, "fail");
                    }
                  }, WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))
                },
                onReachLimit: (message) => {
                  doToast(message, "fail");
                }
              })
            },
            onFileTypeDidntMatch: (message) => {
              doToast(message, "fail");
            }
        }, "image")
    
        e.target.value = null;
    }

    let renderModalMediaDetail = () => {
      return (
        <Modal className="modal-image-preview" toggle={toggleModalMediaPreview} isOpen={showModalMediaPreview}>
          <ModalBody>
            <FiX onClick={toggleModalMediaPreview} />
            {mediaPreview.length > 0 && 
              (props.messageType === "image" ?
                <img src={mediaPreview[0].src} alt="" />
                :
                <video src={props.broadCastMessageDataProps.fileURL} controls />
              )
            }
          </ModalBody>
        </Modal>
      )
    }

    let removeFileAsset = () => {
      props.onRemoveFileAssetProps();
      setMediaPreview([]);
    }

    return (
        <div className="form-image-message-wrapper">
            {renderModalMediaDetail()}

            {!props.noTitle &&
              <>
                <label><b>Image</b> <span>(caption optional)</span></label>

                <label htmlFor="upload-image" className="button-upload-image">
                    <FiImage />
                    <b>Upload Image</b>
                </label>
                <input type="file" id="upload-image" accept="image/*" onChange={(e) => onChangeAssetFile(e)} />
                
                <br />
              </>
            }
            
            {console.log("mediaPreview", mediaPreview)}

            <div className="image-and-caption-wrapper">
                {mediaPreview.length > 0 &&
                  <div className={`image-preview-wrapper ${percentageUpload ? "image-preview-wrapper-grey" : ""}`}>
                      {mediaPreview.length === 0 ?
                          <FiImage />
                          :
                          <div className="image-preview-frame a">
                              {mediaPreview[0].fileSrc &&
                                (props.messageType === "video" ?
                                  <video src={""} alt="" />
                                  :
                                  <img src={window.URL.createObjectURL(mediaPreview[0])} alt="" />
                                )
                              }
                              
                              {(!mediaPreview[0].fileSrc && props.broadCastMessageDataProps.fileURL) &&
                                (props.messageType === "video" ?
                                  <video src={props.broadCastMessageDataProps.fileURL} alt="" />
                                  :
                                  <img src={props.broadCastMessageDataProps.fileURL} alt="" />
                                )
                              }

                              {percentageUpload &&
                                <div className="progress-wrapper">
                                  <CircularProgressbar value={percentageUpload} />
                                </div>
                              }
                              
                              {!percentageUpload &&
                                <div className="image-button-wrapper">
                                  <FiEye className="preview-button" onClick={toggleModalMediaPreview} />

                                  <div className="close-button-wrapper" onClick={removeFileAsset}>
                                    <FiX className="close-button" />
                                  </div>
                                </div>
                              }
                          </div>
                      }
                  </div>
                }

                <textarea 
                  value={props.caption}
                  maxLength="4000"
                  placeholder="Enter caption here..." 
                  id="caption"
                  onChange={(e) => props.onChangeBroadcastMessageProps(e)}
                  disabled={percentageUpload}
                />
            </div>
        </div>
    )
}

export default BroadcastMessageFormMediaMessage;