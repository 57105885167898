import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const ChatbotServices = {
  getChatbot(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATBOT, {}, headers, callback);  
  },

  detailChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DETAIL_CHATBOT, data, headers, callback);  
  },

  addChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_CHATBOT, data, headers, callback);  
  },

  addChatbotCustom(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_CHATBOT_CUSTOM, data, headers, callback);  
  },

  addChatbotChatGPT(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_CHATBOT_CHATGPT, data, headers, callback);  
  },

  editChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_CHATBOT, data, headers, callback);  
  },

  editChatbotCustom(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_CHATBOT_CUSTOM, data, headers, callback);  
  },

  deleteChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_CHATBOT, data, headers, callback);  
  },

  getAutoChatbot(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_AUTO_ASSIGN, {}, headers, callback);  
  },

  setAutoChatbot(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_AUTO_ASSIGN, data, headers, callback);  
  },
  
  getChatGptTierOptions(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_TIER_OPTIONS, {}, headers, callback);  
  },

  setChatGptSubmission(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CHAT_GPT_SUBMISSION_SET, data, headers, callback);  
  },

  getChatGptSavedKnowledgeBase(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_SAVED_KNOWLEDGE_BASE, data, headers, callback);  
  },

  addKnowledgeBaseItem(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_CHATGPT_KNOWLEDGE_BASE_ITEM, data, headers, callback);
  },

  uploadKnowledgeBaseDocument(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime,
      'Content-Type': 'multipart/form-data'
    };

    BaseService.doPostAccessTokenNoWebWorker(API.UPLOAD_CHATGPT_KNOWLEDGE_BASE_DOCUMENT, data, headers, callback);
  },

  removeKnowledgeBasePendingChanges(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.REMOVE_CHATGPT_KNOWLEDGE_BASE_PENDING_CHANGES, data, headers, callback);
  },

  removeKnowledgeBaseTrainedSources(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.REMOVE_CHATGPT_KNOWLEDGE_BASE_TRAINED_SOURCES, data, headers, callback);
  },
  
  getChatGptBrandProfileOptions(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_BRAND_PROFILE_OPTIONS, {}, headers, callback);  
  },

  getChatGptSavedBrandProfile(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_SAVED_BRAND_PROFILE, data, headers, callback);  
  },

  saveChatGptBrandProfile(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SAVE_CHATGPT_BRAND_PROFILE, data, headers, callback);  
  },
  
  getChatGptPromptSettingsOptions(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_PROMPT_SETTINGS_OPTIONS, {}, headers, callback);  
  },

  getChatGptSavedPromptSettings(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_SAVED_PROMPT_SETTINGS, data, headers, callback);  
  },

  saveChatGptPromptSettings(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SAVE_CHATGPT_PROMPT_SETTINGS, data, headers, callback);  
  },

  getChatGptSavedHandoverSettings(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_SAVED_HANDOVER_SETTINGS, data, headers, callback);  
  },

  saveChatGptHandoverSettings(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SAVE_CHATGPT_HANDOVER_SETTINGS, data, headers, callback);  
  },

  getChatGptPeriods(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_PERIODS, data, headers, callback);   
  },

  getChatGptUsage(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_CHATGPT_USAGE, data, headers, callback);   
  },

  getLeadGen(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_LEAD_GEN, data, headers, callback);   
  },

  savetLeadGen(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SAVE_LEAD_GEN, data, headers, callback);   
  },
};

const API = {
  GET_CHATBOT: "/organization/chatbot/get_list",
  DETAIL_CHATBOT: "/organization/chatbot/get_details",
  ADD_CHATBOT: "/organization/chatbot/create/scf",
  ADD_CHATBOT_CUSTOM: "/organization/chatbot/create/custom",
  ADD_CHATBOT_CHATGPT: "/organization/chatbot/create/chatgpt",
  EDIT_CHATBOT: "/organization/chatbot/edit_info",
  EDIT_CHATBOT_CUSTOM: "/organization/chatbot/edit_configs/custom",
  DELETE_CHATBOT: "/organization/chatbot/delete",
  GET_CHATGPT_TIER_OPTIONS: "/organization/chatbot/get_chatgpt_tier_options",
  GET_AUTO_ASSIGN: "/organization/setup/chatbot_idle_case_config/get",
  SET_AUTO_ASSIGN: "/organization/setup/chatbot_idle_case_config/set",
  CHAT_GPT_SUBMISSION_SET: "/organization/chatbot/chatgpt_setup/submit_build",
  GET_CHATGPT_SAVED_KNOWLEDGE_BASE: "/organization/chatbot/chatgpt_setup/get_saved_knowledge_base",
  ADD_CHATGPT_KNOWLEDGE_BASE_ITEM: "/organization/chatbot/chatgpt_setup/add_knowledge_base_item",
  UPLOAD_CHATGPT_KNOWLEDGE_BASE_DOCUMENT: "/organization/chatbot/chatgpt_setup/upload_knowledge_base_document",
  REMOVE_CHATGPT_KNOWLEDGE_BASE_PENDING_CHANGES: "/organization/chatbot/chatgpt_setup/remove_knowledge_base_pending_changes",
  REMOVE_CHATGPT_KNOWLEDGE_BASE_TRAINED_SOURCES: "/organization/chatbot/chatgpt_setup/remove_knowledge_base_trained_sources",
  GET_CHATGPT_BRAND_PROFILE_OPTIONS: "/organization/chatbot/chatgpt_setup/get_brand_profile_options",
  GET_CHATGPT_SAVED_BRAND_PROFILE: "/organization/chatbot/chatgpt_setup/get_saved_brand_profile",
  SAVE_CHATGPT_BRAND_PROFILE: "/organization/chatbot/chatgpt_setup/save_brand_profile",
  GET_CHATGPT_PROMPT_SETTINGS_OPTIONS: "/organization/chatbot/chatgpt_setup/get_prompt_settings_options",
  GET_CHATGPT_SAVED_PROMPT_SETTINGS: "/organization/chatbot/chatgpt_setup/get_saved_prompt_settings",
  SAVE_CHATGPT_PROMPT_SETTINGS: "/organization/chatbot/chatgpt_setup/save_prompt_settings",
  GET_CHATGPT_SAVED_HANDOVER_SETTINGS: "/organization/chatbot/chatgpt_setup/get_saved_handover_settings",
  SAVE_CHATGPT_HANDOVER_SETTINGS: "/organization/chatbot/chatgpt_setup/save_handover_settings",
  GET_CHATGPT_PERIODS: "/organization/chatbot/chatgpt/get_periods",
  GET_CHATGPT_USAGE: "/organization/chatbot/chatgpt/get_usage",
  GET_LEAD_GEN: "/organization/chatbot/chatgpt_setup/get_saved_leads_generation_settings",
  SAVE_LEAD_GEN: "/organization/chatbot/chatgpt_setup/save_leads_generation_settings"
}

export default ChatbotServices;
