import React, { useEffect, useState } from 'react';
import './UTMTab1Show.scss';
import ReportService from '../../../../../services/newServices/ReportService';
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import HelperDate from '../../../../../helper/HelperDate';
import { doToast, checkID } from '../../../../../helper/HelperGeneral';
import { FiDownload } from 'react-icons/fi';
import NoReportFound from "../../../../../assets/img/no-report-found.svg";
import AscendingDescendingTableHeader from '../../../../reuseableComponent/AscendingDescendingTableHeader/AscendingDescendingTableHeader';
import AdvancedDateFilter from '../../../../reuseableComponent/advancedDateFilter/AdvancedDateFilter';
import Pagination from "../../../../reuseableComponent/pagination/Pagination";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import NumberDropdown from '../../../../reuseableComponent/numberDropdown/NumberDropdown';

let UTMTab1Show = (props) => {
    let TABLE_HEADER = [
        {
            title: "From URL",
            value: "fromURL",
            class: "col_1"
        },
        {
            title: "Total Cases",
            value: "totalCases",
            class: "col_2"
        },
    ]

    let [orgID, setOrgID] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [printedData, setPrintedData] = useState([]);
    let [isLoadingExportReport, setIsLoadingExportReport] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [dateVal, setDateVal] = useState({
        from: new Date(new Date().setDate(new Date().getDate() - 30)),
        to: new Date()
    });
    let [filterDateForCalender, setFilterDateForCalender] = useState({
        from: undefined,
        to: undefined
    });
    let [filterBy, setFilterBy] = useState({
        text: "Last 30 Days",
        val: "last_30"
    });
    let [sort, setSort] = useState({
        by: TABLE_HEADER[0].source,
        order: "ASC"
    })
    const pageSizeSelectionArray = [20, 50, 100];
    let [pageSize, setPageSize] = useState(20);  
    let [pageDisplay, setPageDisplay] = useState(1);
    // let [listPage, setListPage] = useState(50);
    let [totalItems, setTotalItems] = useState(0);
    let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);
    let [isShowDropdownPageSize, setIsShowDropdownPageSize] = useState(false);

    useEffect(() => {
        if (orgID) {
            setPageDisplay(1)
            getData(false, 1, pageSize);
        }
    }, [dateVal])

    useEffect(() => {
        doMount();
    }, []);

    let doMount = async () => {
        //filter
        setDateVal(props.filterData.dateVal);
        setFilterDateForCalender(props.filterData.filterDateForCalender);
        setFilterBy(props.filterData.filterBy);
        setPageSize(props.filterData.pageSize);
        //filter

        let paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    let { id, createdTime } = responseData.memberships[0].organization;
                    let urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let downloadReport = (data) => {
        // let id = orgID.split('-');
        // let timestamp = + new Date();
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `referrer-from-url-from-${HelperDate.formatToString(new Date(dateVal.from), "dd-MM-yyyy")}-to-${HelperDate.formatToString(new Date(dateVal.to), "dd-MM-yyyy")}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    let exportData = () => {
        let data = {
            startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
            endDate: dateVal.to ? HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
            sortBy: sort.by,
            sortOrder: sort.order
        };

        setIsLoadingExportReport(true);

        ReportService.exportReferrer(orgID, data,
            (response) => {
                setIsLoadingExportReport(false);
                if (response.dataResult.error) {
                    doToast(response.dataResult.error.message, "fail");
                } else {
                    downloadReport(response.dataResult)
                }
            }
        )
    }

    let getData = (_sort = false, page = false, size = false) => {
        setIsWaitingFetch(true);
        let _retriveDataError = { ...retriveDataError };
        let data = {
            startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
            endDate: dateVal.to ? HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
            pageNumber: !page ? pageDisplay : page,
            pageSize: size ? size : pageSize ? pageSize : 20,
            sortBy: !_sort ? sort.by : _sort.by,
            sortOrder: !_sort ? sort.order : _sort.order,
        }

        ReportService.getRef(orgID, data, (response) => {
            setIsWaitingFetch(false);
            let dataResult = response.dataResult;

            if (dataResult.error.message === '') {
                setPrintedData(dataResult.data.items);
                setTotalItems(dataResult.data.totalItems);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
        });
    };

    useEffect(() => {
        if (printedData && printedData.length > 0) {
          setPageDisplay(1);
          handleChangePage(1);
        }
    }, [pageSize]);

    const onNumberSelected = (val) => {
        if (pageSize !== val) {
            setPageSize(val);
        }
    }
    
    let handleChangePage = (page) => {
        setPageDisplay(page)

        getData(false, page, pageSize);
    }

    // let printText = (val) => {
    //     return val !== "" ? val : "-";
    // }

    let onSort = (_sort) => {
        let _s = {
            by: _sort,
            order: _sort === sort.by ? (sort.order === "ASC" ? "DESC" : "ASC") : "ASC"
        }

        setSort(_s);
        setPageDisplay(1);
        getData(_s, 1, pageSize);
    }

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.success) {
                doToast(props.history.location.state.message);
            } else {
                doToast(props.history.location.state.message, 'fail');
            }

            window.history.pushState(null, '');
        }
    }, [props.history]);  

    return (
        <div className="utm-wrapper sectionWrap">
            {/* tier blocking */}
            {retriveDataError.code === "49901" &&
                <RetrieveDataLoading
                    isLoading={isWaitingFetch}
                    errorMessage={retriveDataError.message}
                    errorCode={retriveDataError.code}
                    loadingText={"Loading Report"}
                />
            }
            {/* tier blocking */}

            <React.Fragment>
                <p className="utm-title">
                    <b>Referrer URL Report</b>
                </p>

                {!retriveDataError.code &&
                    (isLoadingExportReport ?
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40 add-utm-button"
                            text="Download Report"
                            position="left"
                            isLoading
                            loadingColor="gray"
                            isDisabled
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40 add-utm-button"
                            onClickAction={exportData}
                            text="Download Report"
                            position="left"
                            icon={{
                                type: "svg",
                                src: FiDownload
                            }}
                        />
                    )
                }

                <div className="utm-data-outer-wrapper">
                    {
                        !retriveDataError.code &&
                        <>
                            <AdvancedDateFilter
                                toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
                                isOpenDropdown={isShowDropdownDate}
                                dateVal={dateVal}
                                filterDateForCalender={filterDateForCalender}
                                filterBy={filterBy}
                                onClickDate={(date) => setDateVal(date)}
                                onClickFilterBy={(filter) => setFilterBy(filter)}
                                onClickCalendar={(date) => {
                                    setFilterDateForCalender(date)
                                }}
                                ignoreAllTime
                                isDisabled={isWaitingFetch || isLoadingExportReport}
                                disabledDays={{
                                    after: new Date()
                                }}
                                maxRange={365}
                            />
                            {/* <NumberDropdown
                                isOpenDropdown={isShowDropdownPageSize}
                                toggleDropdown={() => setIsShowDropdownPageSize(!isShowDropdownPageSize)}
                                numbersArray={pageSizeSelectionArray}
                                selectedNumber={pageSize}
                                onNumberSelected={onNumberSelected}
                                isDisabled={isWaitingFetch || isLoadingExportReport}
                            /> */}
                        </>
                    }

                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ?
                        <RetrieveDataLoading
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message}
                            errorCode={retriveDataError.code}
                            loadingText={"Loading Report"}
                        />
                        :
                        <React.Fragment>
                            <div className="utm-data-wrapper">
                                {printedData.length === 0 ?
                                    <div className="no-utm">
                                        <img src={NoReportFound} alt="No Report Found" />

                                        <p>
                                            <b>No report found.</b>
                                        </p>
                                    </div>
                                    :
                                    <table className='no-hover'>
                                        <thead>
                                            <tr>
                                                <React.Fragment>
                                                    {TABLE_HEADER.map((val, idx) => {
                                                        return (
                                                            <React.Fragment key={`table-head-${idx}`}>
                                                                <AscendingDescendingTableHeader
                                                                    className={val.class}
                                                                    fieldName={val.value}
                                                                    title={val.title}
                                                                    onClick={() => onSort(val.value)}
                                                                    sortByVal={sort}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </React.Fragment>
                                                <td>
                                                    <NumberDropdown
                                                        isOpenDropdown={isShowDropdownPageSize}
                                                        toggleDropdown={() => setIsShowDropdownPageSize(!isShowDropdownPageSize)}
                                                        numbersArray={pageSizeSelectionArray}
                                                        selectedNumber={pageSize}
                                                        onNumberSelected={onNumberSelected}
                                                        isDisabled={isWaitingFetch || isLoadingExportReport}
                                                    />
                                                </td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {printedData.map((value, key) => {
                                                return (
                                                    <tr key={`utm-row-${key}`}>
                                                        <td className="col_1">
                                                            {value.fromURL}
                                                        </td>
                                                        <td userFullName="col_2">
                                                            {value.totalCases}
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>

                            <div className="pagination-wrapper">
                                {
                                    totalItems > 0 &&
                                    <Pagination
                                        total={totalItems}
                                        page={pageDisplay}
                                        listPerPage={pageSize}
                                        step={1}
                                        onChangePage={handleChangePage}
                                    />
                                }
                            </div>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        </div>
    );
};

export default UTMTab1Show;
