import React from "react";
import "./ErrorOrInfoComp.scss";

let ErrorOrInfoComp = (props) => {
    return (
        ((props.text !== "Characters exceed limit") ?
            <div className={`error-or-info-comp ${props._className}`}>
                {props.icon}
                {props.noBold ?
                    <div className={props.textClass} dangerouslySetInnerHTML={{ __html: props.text }} />
                    :
                    <b className={props.textClass} dangerouslySetInnerHTML={{ __html: props.text }} />
                }
            </div>
            :
            ""
        )
    )
}

export default ErrorOrInfoComp;