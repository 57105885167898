import React, { Component, useEffect, useState } from 'react';
import './Pagination.scss';
import iconFirstPage from "../../../assets/img/icon-pagination-first-enabled.svg";
import iconFirstPageDisabled from "../../../assets/img/icon-pagination-first-disabled.svg";
import iconFirstPageHover from "../../../assets/img/icon-pagination-first-hover.svg";
import iconPreviousPage from "../../../assets/img/icon-pagination-previous-enabled.svg";
import iconPreviousPageDisabled from "../../../assets/img/icon-pagination-previous-disabled.svg";
import iconPreviousPageHover from "../../../assets/img/icon-pagination-previous-hover.svg";
import iconNextPage from "../../../assets/img/icon-pagination-next-enabled.svg";
import iconNextPageDisabled from "../../../assets/img/icon-pagination-next-disabled.svg";
import iconNextPageHover from "../../../assets/img/icon-pagination-next-hover.svg";
import iconLastPage from "../../../assets/img/icon-pagination-last-enabled.svg";
import iconLastPageDisabled from "../../../assets/img/icon-pagination-last-disabled.svg";
import iconLastPageHover from "../../../assets/img/icon-pagination-last-hover.svg";

const style = {
    marginRight4: {
        marginRight: "4px"
    },
    marginRight8: {
        marginRight: "8px"
    },
    marginRight12: {
        marginRight: "12px"
    },
};

class Pagination extends Component {

    constructor(props) {
        super(props);

        this.state = {
            total: this.props.total,
            page: this.props.page,
            listPerPage: this.props.listPerPage,
            step: this.props.step,
            pageInput: "" + this.props.page
        };

        this.generatePage = this.generatePage.bind(this);
        this.printFirstPage = this.printFirstPage.bind(this);
        this.printMidPage = this.printMidPage.bind(this);
        this.printLastPage = this.printLastPage.bind(this);
        this.printPages = this.printPages.bind(this);
        this.onChangePageInput = this.onChangePageInput.bind(this);
        this.onPageInputBlur = this.onPageInputBlur.bind(this);
        this.onSubmitPageInput = this.onSubmitPageInput.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.listPerPage !== this.props.listPerPage) {
            this.setState({ listPerPage: this.props.listPerPage });
        }
        if (prevProps.total !== this.props.total) {
            this.setState({ total: this.props.total });
        }
        if (prevProps.page !== this.props.page) {
            this.setState({ 
                page: this.props.page,
                pageInput: "" + this.props.page
            });
        }
    }
  
    getTotalPage() {
        let _total = 0;

        _total = Math.ceil(this.state.total / this.state.listPerPage);

        return _total;
    }

    generatePage() {
        let totalPage = Math.ceil(this.state.total / this.state.listPerPage);

        let pageList = [];
        pageList.push(this.printPages(this.state.page, totalPage));

        // if (totalPage < this.state.step * 2 + 6) {

        //     pageList.push(this.printMidPage(1, totalPage + 1));

        // } else if (this.state.page < this.state.step * 2 + 1) {

        //     pageList.push(this.printMidPage(1, this.state.step * 2 + 4));
        //     pageList.push(this.printLastPage(totalPage));

        // }
        // else if (this.state.page > totalPage - this.state.step * 2) {

        //     pageList.push(this.printFirstPage());
        //     pageList.push(this.printMidPage(totalPage - this.state.step * 2 - 2, totalPage + 1));

        // }
        // else {

        //     pageList.push(this.printFirstPage());
        //     pageList.push(this.printMidPage(this.state.page - this.state.step, this.state.page + this.state.step + 1));
        //     pageList.push(this.printLastPage(totalPage));

        // }

        return pageList;
    }

    printFirstPage() {
        let pageFirst = [];
        pageFirst.push(
            <li onClick={() => this.changePageState(1)}
                className={this.state.page === 1 ? "active pagination-button" : "pagination-button"}
                key={"first-page"}
            >
                <span>{1}</span>
            </li>
        );
        pageFirst.push(<li key="first-dot">...</li>);

        return pageFirst;
    }

    printMidPage(page, totalPage) {
        let pageMid = [];
        for (let i = page; i < totalPage; i++) {
            pageMid.push(
                <li onClick={() => this.changePageState(i)}
                    className={this.state.page === i ? "active pagination-button" : "pagination-button"}
                    key={i}
                >
                    <span>{i}</span>
                </li>
            );
        }

        return pageMid;
    }

    printPages(page, totalPage) {
        let pages = [];
        let pageStart = 1;
        const offset = 3;
        if (page > pageStart + offset) {
            pageStart = page - offset;
        }
        for (let i = pageStart; i <= pageStart + (offset * 2); i++) {
            if (i <= totalPage) {
                pages.push(
                    <li
                        onClick={() => this.changePageState(i)}
                        style={i >= pageStart + (offset * 2) ? style.marginRight8 : style.marginRight12}
                        className={this.state.page === i ? "active pagination-button" : "pagination-button enabled"}
                        key={i}
                    >
                        <span>{i}</span>
                    </li>
                );
            }
        }
        return pages;
    }

    printLastPage(totalPage) {
        let pageLast = [];
        pageLast.push(<li key="last-dot">...</li>);
        pageLast.push(
            <li onClick={() => this.changePageState(totalPage)}
                className={this.state.page === totalPage ? "active pagination-button" : "pagination-button"}
                key={"last-page"}
            >
                <span>{totalPage}</span>
            </li>
        );

        return pageLast;
    }

    onChangePageInput(e) {
        let value = e.target.value;
        value = value.replace(/\D/g,'');
        let page = value ? parseInt(value) : "";
        if (page || page === 0) {
            if (page < 1) {
                page = 1;
            }
            else if (page > this.getTotalPage()) {
                page = this.getTotalPage();
            }
        }
        this.setState({ pageInput: "" + page });
    }

    onPageInputBlur(e) {
        let input = document.getElementById("page-input");
        if (input) {
            input.blur();
        }
        const pageInput = this.state.pageInput ? parseInt(this.state.pageInput) : 0;
        if (pageInput <= 0 || pageInput > this.getTotalPage()) {
            this.setState({ pageInput: this.state.page });
            return false;
        }
        return true;
    }

    onSubmitPageInput(e) {
        e.preventDefault();
        if (this.onPageInputBlur()) {
            const pageInput = this.state.pageInput ? parseInt(this.state.pageInput) : 0;
            this.changePageState(pageInput);
        }
    }

    changePageState(page) {
        this.setState({ page: page });
        this.props.onChangePage(page);
    }

    render() {
        return (
            <div id="pagination-wrapper">
                <ul>
                    {
                        this.state.page > 1 ?
                        <>
                            <li 
                                className="pagination-label pagination-button enabled"
                                style={style.marginRight4}
                                onClick={() => this.changePageState(1)}
                                key={"first-page"}
                                onMouseOver={() => {
                                    if (document.getElementById("pagination-button-first")) {
                                        document.getElementById("pagination-button-first").src = iconFirstPageHover;
                                    }
                                }}
                                onMouseOut={() => {
                                    if (document.getElementById("pagination-button-first")) {
                                        document.getElementById("pagination-button-first").src = iconFirstPage;
                                    }
                                }}
                            >
                                <img 
                                    id="pagination-button-first"
                                    className="pagination-button-icon" 
                                    src={iconFirstPage}
                                    alt=""
                                />
                            </li>
                            <li 
                                className="pagination-label pagination-button enabled"
                                style={style.marginRight8}
                                onClick={() => this.changePageState(this.state.page - 1)}
                                key={"previous-page"}
                                onMouseOver={() => {
                                    if (document.getElementById("pagination-button-previous")) {
                                        document.getElementById("pagination-button-previous").src = iconPreviousPageHover;
                                    }
                                }}
                                onMouseOut={() => {
                                    if (document.getElementById("pagination-button-previous")) {
                                        document.getElementById("pagination-button-previous").src = iconPreviousPage;
                                    }
                                }}
                            >
                                <img 
                                    id="pagination-button-previous" 
                                    className="pagination-button-icon" 
                                    src={iconPreviousPage}
                                    alt=""
                                />
                            </li>
                        </>
                        :
                        <>
                            <li 
                                className="pagination-label pagination-button"
                                style={style.marginRight4}
                                key={"first-page"}
                            >
                                <img 
                                    className="pagination-button-icon" 
                                    src={iconFirstPageDisabled}
                                    alt=""
                                />
                            </li>
                            <li 
                                className="pagination-label pagination-button"
                                style={style.marginRight8}
                                key={"previous-page"}
                            >
                                <img 
                                    className="pagination-button-icon" 
                                    src={iconPreviousPageDisabled}
                                    alt=""
                                />
                            </li>
                        </>
                    }

                    {this.generatePage()}

                    {
                        (this.state.page < Math.ceil(this.props.total / this.state.listPerPage)) ?
                        <>
                            <li 
                                className="pagination-label pagination-button enabled"
                                style={style.marginRight4}
                                onClick={() => this.changePageState(this.state.page + 1)}
                                key={"next-page"}
                                onMouseOver={() => {
                                    if (document.getElementById("pagination-button-next")) {
                                        document.getElementById("pagination-button-next").src = iconNextPageHover;
                                    }
                                }}
                                onMouseOut={() => {
                                    if (document.getElementById("pagination-button-next")) {
                                        document.getElementById("pagination-button-next").src = iconNextPage;
                                    }
                                }}
                            >
                                <img 
                                    id="pagination-button-next" 
                                    className="pagination-button-icon" 
                                    src={iconNextPage}
                                    alt=""
                                />
                            </li>
                            <li 
                                className="pagination-label pagination-button enabled"
                                style={style.marginRight12}
                                onClick={() => this.changePageState(this.getTotalPage())}
                                key={"last-page"}
                                onMouseOver={() => {
                                    if (document.getElementById("pagination-button-last")) {
                                        document.getElementById("pagination-button-last").src = iconLastPageHover;
                                    }
                                }}
                                onMouseOut={() => {
                                    if (document.getElementById("pagination-button-last")) {
                                        document.getElementById("pagination-button-last").src = iconLastPage;
                                    }
                                }}
                            >
                                <img 
                                    id="pagination-button-last" 
                                    className="pagination-button-icon" 
                                    src={iconLastPage}
                                    alt=""
                                />
                            </li>
                        </>
                        :
                        <>
                            <li 
                                className="pagination-label pagination-button"
                                style={style.marginRight4}
                                key={"next-page"}
                            >
                                <img 
                                    className="pagination-button-icon" 
                                    src={iconNextPageDisabled}
                                    alt=""
                                />
                            </li>
                        <li 
                            className="pagination-label pagination-button"
                            style={style.marginRight12}
                            key={"last-page"}
                        >
                            <img 
                                className="pagination-button-icon" 
                                src={iconLastPageDisabled}
                                alt=""
                            />
                        </li>
                        </>
                    }

                    <li
                        className="pagination-input"
                        key={"input-page"}
                    >
                        <b className="pagination-label">Page</b>
                        <form
                            className="page-input-form"
                            onSubmit={(e) => this.onSubmitPageInput(e)}
                            onBlur={(e) => this.onPageInputBlur(e)}
                        >
                            <input
                                id="page-input"
                                className="page-input"
                                type="text"
                                value={this.state.pageInput}
                                onChange={(e) => this.onChangePageInput(e)}
                            />
                        </form>
                        <b className="pagination-label">of {this.getTotalPage()}</b>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Pagination;
