import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupPromptSetting.scss";
import { connect } from 'react-redux';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { FiAlertCircle, FiInfo, FiPlus, FiSave, FiTrash2 } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SelectBulk from "../../../reuseableComponent/selectBulk/SelectBulk";
import { doToast } from "../../../../helper/HelperGeneral";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { Tooltip } from "reactstrap";
import { DEFAULT_LANGUAGE_LIST_ARRAY } from "../IntegrationChatGPTSetup2";

const IntegrationChatGPTSetupPromptSetting = (props) => {

    // const onSelectModel = (model) => {
    //     let promptSettings = { ...props.promptSettings };
    //     if (promptSettings.model.value === model.value) {
    //         return;
    //     }
    //     promptSettings.model = model;
    //     props.setPromptSettings(promptSettings);

    //     let _errorMessageData = { ...props.promptSettingsError };
    //     _errorMessageData.model = "";
    //     props.setPromptSettingsError(_errorMessageData);
    // }

    const onSelectRole = (role) => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.role.value === role.value) {
            return;
        }
        promptSettings.role = role;
        props.setPromptSettings(promptSettings);

        let _errorMessageData = { ...props.promptSettingsError };
        _errorMessageData.role = "";
        props.setPromptSettingsError(_errorMessageData);
    }

    const onSelectTone = (tone) => {
        if (props.isLoadingSave) {
            return;
        }
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.tone.value === tone.value) {
            return;
        }
        promptSettings.tone = tone;
        props.setPromptSettings(promptSettings);

        let _errorMessageData = { ...props.promptSettingsError };
        _errorMessageData.tone = "";
        props.setPromptSettingsError(_errorMessageData);
    }

    const onSelectLanguage = (language) => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.defaultLanguage.value === language.value) {
            promptSettings.defaultLanguage = {
                id: "",
                code: "",
                value: "",
                name: "",
                label: "",
            };
        }
        else {
            promptSettings.defaultLanguage = language;
        }
        props.setPromptSettings(promptSettings);
    }

    const onChangeGreetings = (e, index) => {
        let val = e.target.value;

        let promptSettings = { ...props.promptSettings };
        promptSettings.greetings[index] = val;
        props.setPromptSettings(promptSettings);
        resizeGreetingsTextArea(index);

        if (val) {
            let _errorMessageData = { ...props.promptSettingsError };
            if (_errorMessageData.greetings.length > index) {
                _errorMessageData.greetings[index] = "";
                props.setPromptSettingsError(_errorMessageData);
            }
        }
    }

    const resizeGreetingsTextArea = (index, scroll = true) => {
        const textarea = document.getElementById(`greetings-${index}`);
        if (!textarea) {
            return;
        }
        if (textarea.offsetHeight === textarea.scrollHeight) {
            return;
        }
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 48) {
            textarea.style.height = "48px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
            if (scroll) {
                textarea.scrollIntoView();
            }
        }
    }

    const addGreetingMessage = () => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.greetings.length >= 5) {
            return;
        }
        promptSettings.greetings.push("");
        props.setPromptSettings(promptSettings);

        let _errorMessageData = { ...props.promptSettingsError };
        _errorMessageData.greetings.push("");
        props.setPromptSettingsError(_errorMessageData);
    }

    const removeGreetingMessage = (index) => {
        let promptSettings = { ...props.promptSettings };
        if (promptSettings.greetings.length <= 1) {
            return;
        }
        promptSettings.greetings.splice(index, 1);
        props.setPromptSettings(promptSettings);

        let _errorMessageData = { ...props.promptSettingsError };
        _errorMessageData.greetings.splice(index, 1);
        props.setPromptSettingsError(_errorMessageData);

        setTimeout(() => {
            props.promptSettings.greetings.map((value, index) => {
                resizeGreetingsTextArea(index, false);
                return null;
            });
            // resizeSystemPromptTextArea(false);
        }, 0);
    }

    const onChangeSystemPrompt = (e) => {
        let val = e.target.value;
        let promptSettings = { ...props.promptSettings };
        promptSettings.systemPrompt = val;
        props.setPromptSettings(promptSettings);
        // resizeSystemPromptTextArea();

        let _errorMessageData = { ...props.promptSettingsError };
        // if (val.length > SYSTEM_PROMPT_MAX_LENGTH) {
        //     _errorMessageData.systemPrompt = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
        // }
        // else {
            _errorMessageData.systemPrompt = "";
        // }
        props.setPromptSettingsError(_errorMessageData);
    }

    const resizeSystemPromptTextArea = (scroll = true) => {
        const elements = document.getElementsByClassName("input-system-prompt");
        if (!elements) {
            return;
        }
        const textarea = elements[0];
        if (!textarea) {
            return;
        }
        if (textarea.offsetHeight === textarea.scrollHeight) {
            return;
        }
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 96) {
            textarea.style.height = "96px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
            if (scroll) {
                textarea.scrollIntoView();
            }
        }
    }

    const fetchPromptSettingsOptionsAndSavedData = () => {
        setIsLoadingOptions(true);
        props.getPromptSettingsData((response) => {
            let dataResult = response.dataResult;
            if (dataResult.error.message) {
                doToast(dataResult.error.message, 'fail');
            }
            setIsLoadingOptions(false);
        });
    }

    const onChangeSearchBulk = (e) => {
        let _bulkOrigin = bulkListOrigin.slice();
        let result = [];
        setSearchBulk(e.target.value);

        if (_bulkOrigin.length > 0) {
            for (let i in _bulkOrigin) {
                let objectKey = Object.keys(_bulkOrigin[i]);

                for (let j in objectKey) {
                    if(objectKey[j] === "label" || objectKey[j] === "name") {
                        let isString = typeof _bulkOrigin[i][objectKey[j]] === "string";
                        if (_bulkOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
                            result.push(_bulkOrigin[i])
                            break;
                        }
                    }
                }
            }
        }

        setBulkList(result);
    }

    const onChangeSingleSelect = (e, val) => {
        if (!props.isLoadingSave) {
            let _prompt = {...props.promptSettings};
    
            if (e.target.checked) {
                _prompt.supportedLanguages = [..._prompt.supportedLanguages, val];
            }
            else {
                _prompt.supportedLanguages = _prompt.supportedLanguages.filter(el => el.id !== val.id)
            }
    
            props.setPromptSettings(_prompt);
        }
    }

    const removeSingleBulk = (val) => {
        if (!props.isLoadingSave) {
            let _prompt = {...props.promptSettings};
            
            _prompt.supportedLanguages = _prompt.supportedLanguages.filter(el => el.id !== val.id);
            props.setPromptSettings(_prompt);
        }
    }

    const isChecked = (id) => {
        const _data = {...props.promptSettings};
        return _data.supportedLanguages.findIndex(val => val.id === id) !== -1;
    }

    const onClearAll = () => {
        if (!props.isLoadingSave) {
            let _prompt = {...props.promptSettings};
            _prompt.supportedLanguages = [];
            props.setPromptSettings(_prompt);
        }
    }

    const onCheckAll = (e) => {
        if (!props.isLoadingSave) {
            let _prompt = {...props.promptSettings};

            if (e.target.checked) {
                _prompt.supportedLanguages = bulkList.slice();
            }
            else {
                _prompt.supportedLanguages = [];
            }
            props.setPromptSettings(_prompt);
        }
    }

    const toggleToneTooltip = (e) => {
        let _showToneTooltip = { ...showToneTooltip };
        let targetID = e.target.id;
        if (targetID) {
            targetID = targetID.replaceAll("tooltip-", "");
            props.promptSettingsOptions.tones.map((tone, index) => {
                if (targetID === tone.value) {
                    _showToneTooltip.tooltips[index] = !_showToneTooltip.tooltips[index];
                }
                return null;
            });
        }
        else {
            _showToneTooltip = {
                tooltips: [false, false, false, false]
            };
        }
        setShowToneTooltip(_showToneTooltip);
    }

    // const toggleConfig = () => {
    //     let _data = { ...props.promptSettings };
    //     _data.allowGlobalKnowledge = !_data.allowGlobalKnowledge;
    //     props.setPromptSettings(_data);
    // }
 
    const onChangePersonaName = (e) => {
        let _promptSettings = { ...props.promptSettings };
        _promptSettings.personaName = e.target.value;
        props.setPromptSettings(_promptSettings);

        let _errorMessageData = { ...props.promptSettingsError };
        _errorMessageData.personaName = "";
        props.setPromptSettingsError(_errorMessageData);
    };

    let [searchBulk, setSearchBulk] = useState("");
    let [bulkListOrigin, setBulkListOrigin] = useState(DEFAULT_LANGUAGE_LIST_ARRAY.slice());
    let [bulkList, setBulkList] = useState(DEFAULT_LANGUAGE_LIST_ARRAY.slice());
    let [isLoadingOptions, setIsLoadingOptions] = useState(false);
    let [showToneTooltip, setShowToneTooltip] = useState({
        tooltips: [false, false, false, false]
    });

    useEffect(() => {
        fetchPromptSettingsOptionsAndSavedData();
    }, [props.match.params.orgID]);

    useEffect(() => {
        let toneTooltipArray = [];
        if (props.promptSettingsOptions.tones && props.promptSettingsOptions.tones.length > 0) {
            props.promptSettingsOptions.tones.map((tone, index) => {
                toneTooltipArray.push(false);
                return null;
            });
            setShowToneTooltip({
                tooltips: toneTooltipArray
            });
        }
        if (props.promptSettingsOptions.languages && props.promptSettingsOptions.languages.length > 0) {
            setBulkList(props.promptSettingsOptions.languages);
            setBulkListOrigin(props.promptSettingsOptions.languages);
        }
    }, [props.promptSettingsOptions]);

    useEffect(() => {
        if (props.promptSettings.defaultLanguage && props.promptSettings.defaultLanguage.code) {
            let removeDefaultLanguage = () => {
                let _promptSettings = {...props.promptSettings};
                _promptSettings.defaultLanguage = {
                    id: "",
                    code: "",
                    value: "",
                    name: "",
                    label: "",
                };
                props.setPromptSettings(_promptSettings);
            }
            if (props.promptSettings.supportedLanguages && props.promptSettings.supportedLanguages.length > 0) {
                let isRemoved = true;
                props.promptSettings.supportedLanguages.map((language) => {
                    if (language.code === props.promptSettings.defaultLanguage.code) {
                        isRemoved = false;
                    }
                    return null;
                });
                if (isRemoved) {
                    removeDefaultLanguage();
                }
            }
            else {
                removeDefaultLanguage();
            }
        }

        let _errorMessageData = { ...props.promptSettingsError };
        _errorMessageData.greetings.length = props.promptSettings.greetings.length;
        props.setPromptSettingsError(_errorMessageData);

        setTimeout(() => {
            props.promptSettings.greetings.map((value, index) => {
                resizeGreetingsTextArea(index, false);
                return null;
            });
            // resizeSystemPromptTextArea(false);
        }, 0);
    }, [props.promptSettings]);

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={props.promptSettingsOrigin}
                dataAfterChange={JSON.stringify(props.promptSettings)}
            >
                <div>
                    <p className="text-info-top">
                        <FiInfo />
                        Customize how chatbot generates responses, help determine behaviors and styles for chatbot’s style to fit different needs.
                    </p>
                    {
                        isLoadingOptions ?
                        <RetrieveDataLoading
                            isLoading={isLoadingOptions}
                            errorMessage=""
                            errorCode=""
                        />
                        :
                        <>
                            {/* <div className="form-content-wrapper">
                                <div className="dropdown-model">
                                    <label><b>Model</b></label>
                                    <CustomSelectOption
                                        optionListProps={modelOptions}
                                        valueDropdownProps={props.promptSettings.model}
                                        placeholderProps={"Please select model"}
                                        onClickDropDownListOptionProps={onSelectModel}
                                        isDisabled={props.isLoadingSave}
                                        _className={`${props.promptSettingsError.model ? "border-red" : ""}`}
                                    />
                                    {
                                        props.promptSettingsError.model ?
                                            <ErrorOrInfoComp
                                                text={props.promptSettingsError.model}
                                                _className={"font-red"}
                                                icon={<FiAlertCircle />}
                                            />
                                            :
                                            <ErrorOrInfoComp
                                                text={`To know more about GPT Model, <a href="https://platform.openai.com/docs/models" target="_blank" rel="noopener noreferrer">click here</a>`}
                                                icon={<FiInfo />}
                                            />
                                    }
                                </div>
                            </div> */}

                            
                            {/* <div className="form-content-wrapper external-source-wrapper">
                                <label><b>Allow External Knowledge</b></label>
                                <span className="top-subtitle">
                                    <div className="external-source-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input
                                                type="checkbox"
                                                checked={props.promptSettings.allowGlobalKnowledge}
                                                onChange={() => toggleConfig()}
                                                disabled={isLoadingOptions}
                                            />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>
                                    <p>Enabling this feature will allow the chatbot to access external knowledge sources.</p>
                                </span>
                            </div> */}
                        
                            <div className="form-content-wrapper persona-name-wrapper">
                                <label>
                                    <b>Persona Name</b>
                                    <span className="optional"> (Optional)</span>
                                </label>
                                <input
                                    type="text"
                                    id="personaName"
                                    onChange={(e) => onChangePersonaName(e)}
                                    value={props.promptSettings.personaName}
                                    placeholder="Input persona name"
                                    className={`input-persona-name ${props.promptSettingsError.website ? "border-red" : ""}`}
                                />
                                {
                                    props.promptSettingsError.personaName &&
                                    <ErrorOrInfoComp
                                        text={props.promptSettingsError.personaName}
                                        _className={"font-red"}
                                        icon={<FiAlertCircle />}
                                    />
                                }
                            </div>

                            <div className="form-content-wrapper">
                                <div className="dropdown-role">
                                    <label><b>Role</b></label>
                                    <CustomSelectOption
                                        optionListProps={props.promptSettingsOptions.roles}
                                        valueDropdownProps={props.promptSettings.role}
                                        placeholderProps={"Please select role"}
                                        onClickDropDownListOptionProps={onSelectRole}
                                        isDisabled={props.isLoadingSave}
                                        _className={`${props.promptSettingsError.role ? "border-red" : ""}`}
                                    />
                                    {
                                        props.promptSettingsError.role &&
                                        <ErrorOrInfoComp
                                            text={props.promptSettingsError.role}
                                            _className={"font-red"}
                                            icon={<FiAlertCircle />}
                                        />
                                    }
                                </div>
                            </div>

                            <div className="tone-form-wrapper">
                                <label><b>Tone</b></label>
                                <div className="tone-wrapper">
                                    {
                                        props.promptSettingsOptions.tones.map((tone, index) => {
                                            return (
                                                <div className="tone-list-wrapper" key={`tone-list-wrapper-${index}`}>
                                                    <div className="tone-list-marker-wrapper">
                                                        <div className={`tone-list-line ${props.promptSettings.tone.index > index ? "bg-light-orange" : "bg-gray"}`} />
                                                        <div 
                                                            className={`tone-list-dot ${props.promptSettings.tone.index === index ? "bg-orange" : props.promptSettings.tone.index > index ? "bg-light-orange" : "bg-gray"}`}
                                                            onClick={() => onSelectTone(tone)}
                                                        />
                                                    </div>
                                                    <div className="tone-list-label-wrapper">
                                                        <span>
                                                            {tone.name}
                                                            <FiInfo className="tooltip-icon" id={`tooltip-${tone.value}`} />
                                                            <Tooltip
                                                                className={`tooltip-tone tooltip-${tone.value}`}
                                                                placement="bottom"
                                                                isOpen={showToneTooltip.tooltips[index]}
                                                                target={`tooltip-${tone.value}`}
                                                                toggle={toggleToneTooltip}
                                                                opacity={1.0}
                                                            >
                                                                {tone.description}
                                                            </Tooltip>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    props.promptSettingsError.tone &&
                                    <ErrorOrInfoComp
                                        text={props.promptSettingsError.tone}
                                        _className={"font-red"}
                                        icon={<FiAlertCircle />}
                                    />
                                }
                            </div>

                            <div className="form-content-wrapper language-bulk-wrapper">
                                <div className="dropdown-language">
                                    <SelectBulk
                                        onChangeSearchBulk={onChangeSearchBulk}
                                        onClearAll={onClearAll}
                                        bulkList={bulkList}
                                        onChangeSingleSelect={onChangeSingleSelect}
                                        isChecked={isChecked}
                                        isCheckAll={props.promptSettings.supportedLanguages.length > 0}
                                        searchBulk={searchBulk}
                                        title={"Language"}
                                        selectedBulk={props.promptSettings.supportedLanguages}
                                        removeSingleBulk={removeSingleBulk}
                                        selectedTitle={"Selected languages will be displayed here."}
                                        // isTitleOptional
                                        searchTitle={"Search for languages"}
                                        onCheckAll={onCheckAll}
                                        errorText={""}
                                        isDisabled={props.isLoadingSave}
                                    />
                                </div>
                            </div>

                            <div className="form-content-wrapper">
                                <div className="dropdown-language">
                                    <label>
                                        <b>Default Language</b>
                                        <span className="optional"> (Optional)</span>
                                    </label>
                                    <CustomSelectOption
                                        optionListProps={props.promptSettings.supportedLanguages}
                                        valueDropdownProps={props.promptSettings.defaultLanguage}
                                        placeholderProps={"Please select language"}
                                        onClickDropDownListOptionProps={onSelectLanguage}
                                        isDisabled={props.isLoadingSave}
                                    />
                                </div>
                            </div>

                            <div className="form-content-wrapper">
                                <div className="greetings-wrapper">
                                    <label><b>Greetings</b></label>
                                    {
                                        props.promptSettings.greetings.map((value, index) => {
                                            return (
                                                <div className={`greetings-list greetings-list-${index}`} key={`greetings-list-${index}`}>
                                                    <textarea
                                                        disabled={props.isLoadingSave}
                                                        id={`greetings-${index}`}
                                                        onChange={(e) => onChangeGreetings(e, index)}
                                                        value={value}
                                                        placeholder="Insert greeting message"
                                                        className={`input-greetings ${props.promptSettingsError.greetings[index] ? "border-red" : ""}`}
                                                    />
                                                    {
                                                        props.promptSettings.greetings.length > 1 &&
                                                        <FiTrash2
                                                            className="icon-remove-greeting"
                                                            onClick={() => { removeGreetingMessage(index) }}
                                                        />
                                                    }
                                                    {
                                                        props.promptSettingsError.greetings[index] &&
                                                        <ErrorOrInfoComp
                                                            text={props.promptSettingsError.greetings[index]}
                                                            _className={"font-red add-margin-bottom"}
                                                            icon={<FiAlertCircle />}
                                                        />
                                                    }
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        props.promptSettings.greetings.length < 5 &&
                                        <ButtonWithLoadingOrIcon
                                            text="Add another greeting message"
                                            onClickAction={addGreetingMessage}
                                            className="main-button-26 no-fill-button-no-border button-add-greeting"
                                            icon={{
                                                type: "svg",
                                                src: FiPlus
                                            }}
                                            position="left"
                                        />
                                    }
                                </div>
                            </div>

                            <div className="form-content-wrapper">
                                <div className="system-prompt-wrapper">
                                    <label>
                                        <b>Additional Prompt</b>
                                        <span className="optional"> (Optional)</span>
                                        {/* <span className={`input-counter grey-font ${props.promptSettings.systemPrompt.length > SYSTEM_PROMPT_MAX_LENGTH ? "red-text" : ""}`}>
                                            {props.promptSettings.systemPrompt.length}/{SYSTEM_PROMPT_MAX_LENGTH} characters
                                        </span> */}
                                    </label>
                                    {/* <p className="system-prompt-description">Additional prompt settings to customize your chatbot</p> */}
                                    <textarea
                                        disabled={props.isLoadingSave}
                                        onChange={(e) => onChangeSystemPrompt(e)}
                                        value={props.promptSettings.systemPrompt}
                                        placeholder="Additional prompt settings to customize your chatbot"
                                        className={`input-system-prompt ${props.promptSettingsError.systemPrompt ? "border-red" : ""}`}
                                    />
                                    {/* <ErrorOrInfoComp
                                        text="E.g: ‘When giving answer to user question, make it brief and easy to understand’"
                                        icon={<FiInfo />}
                                    /> */}
                                    {
                                        props.promptSettingsError.systemPrompt &&
                                        <ErrorOrInfoComp
                                            text={props.promptSettingsError.systemPrompt}
                                            _className={"font-red"}
                                            icon={<FiAlertCircle />}
                                        />
                                    }
                                </div>
                            </div>

                            <ButtonWithLoadingOrIcon
                                className="no-fill-button main-button-40 button-save"
                                text="Save Changes"
                                icon={{
                                    type: "svg",
                                    src: FiSave
                                }}
                                position="right"
                                onClickAction={() => {
                                    props.validateSavePromptSettings();
                                    props.setPendingTab(-1);
                                }}
                            />
                        </>
                    }
                </div>
            </SectionWrap>
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupPromptSetting)
