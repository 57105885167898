import React, { useState, useEffect } from "react";
import "./ViewMessageTemplate.scss";
import ServiceOrganization from "../../../../../services/organization/ServiceOrganization";
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";
import HelperChat from "../../../../../helper/HelperChat";
import { getWhatsappParam } from "../../../../../helper/HelperGeneral";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import WhatsappPreview from "../../../../reuseableComponent/whatsappPreview/WhatsappPreview";
import { FiInfo } from "react-icons/fi";
// import { BiMessageDetail } from "react-icons/bi";
import IconMessage from "../../../../../assets/img/sidemenu-icon/icon-messages-black.svg";
import { FiPlus, FiVideo, FiFile, FiImage } from "react-icons/fi"
import { BROADCAST_HEADER_FORMAT } from "../../../../../constants/message";
import HelperInput from "../../../../../helper/HelperInput";

const ViewMessageTemplate = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [templateDetail, setTemplateDetail] = useState();
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetTemplateData, setIsLoadingGetTemplateData] = useState(
    false
  );

  const BUTTONS = {
    "quick_reply": "Quick Reply",
    "call_to_action": "Call to Action"
  }

  const SUBTYPE = {
    "quick_reply": "Quick Reply",
    "url": "Visit Website",
    "phone_number": "Phone Number"
  }

  // let convertBody = (text) => {
  //   var bold = /\*(.*?)\*/gm;
  //   var italic = /\_(.*?)\_/gm;
  //   var strikethrough = /\~(.*?)\~/gm;
  //   var monospace = /\```(.*?)\```/gm;
  //   var html = text
  //     .replace(bold, "<b>$1</b>")
  //     .replace(italic, "<i>$1</i>")
  //     .replace(strikethrough, "<s>$1</s>")
  //     .replace(monospace, "<mono>$1</mono>");
  //   return html;
  // };

  const checkID = (match) => {
    const { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  const back = () =>
    history.push({
      pathname: `/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}`,
      state: {
        whichTab: "messageTemplate",
      },
    });

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      setIsLoadingGetTemplateData(true);
      let data = {
        channelID: Number(match.params.id),
        templateID: Number(match.params.templateID),
      };
      IntegrationWhatsappBusinessServices.getTemplateDetail(
        orgID,
        data,
        (response) => {
          let _retrieveDataError = { ...retrieveDataError };

          if (response.dataResult.error.message === "") {
            setTemplateDetail(response.dataResult.data);
          } else {
            _retrieveDataError.message = response.dataResult.error.message;
            _retrieveDataError.code = response.dataResult.error.code;
            setRetrieveDataError(_retrieveDataError);
          }
          setIsLoadingGetTemplateData(false);
        }
      );
    }
  }, [orgID]);

  const getHeaderValue = (item) => {
    if (item.headerFormat === BROADCAST_HEADER_FORMAT.image) {
      if (item.parameters.header
        && item.parameters.header[0]
        && item.parameters.header[0].name) return `{{${item.parameters.header[0].name}}}`
      else return "-"
    } else {
      if (item.headerTextNamed) return item.headerTextNamed
      else return "-"
    }
  }

  let printSampleParam = (part, idx, val) => {
    return (
      <div className={`sample-param-input-wrapper`} key={`sample-${part}-${idx}`}>
        <p><b>{`{{${val.name}}}`}</b></p>

        <input
          type="text"
          placeholder={`sample content for {{${val.name}}}`}
          value={val.value}
          disabled={true}
        />
      </div>
    )
  }

  let getTemplateType = () => {
    let templateType = "";

    if (templateDetail.template.templateType !== "text") {
      if (templateDetail.languages[0].headerTextNamed === "") {
        templateType = "dynamic-media";
      } else {
        templateType = "static-media"
      }
    } else {
      templateType = templateDetail.template.templateType;
    }


    return templateType;
  }

  let printButtonsQuickReply = (v, i) => {
    return (
      <div className="wrapper-button-action" key={`wrapper-button-action-${i}`}>
        <label>
          <b>Button Text #{i + 1}</b>
        </label>
        <p>
          {v.text}
        </p>

        <label>
          <b>Payload #{i + 1}</b>
        </label>
        <p>
          {v.quickReplyPayload ? v.quickReplyPayload : "-"}
        </p>
      </div>
    )
  }

  let getURLParam = (url) => {
    let _param = getWhatsappParam(url);

    return {
      urlType: _param.length > 0 ? "Dynamic" : "Static",
      params: _param
    };
  }

  let printButtonsCallToAction = (v, i, _item) => {
    return (
      <div className="wrapper-button-action" key={`wrapper-button-action-${i}`}>
        <label>
          <b>Type of Action</b>
        </label>
        <p>
          {v.type ?
            SUBTYPE[v.type] ? SUBTYPE[v.type] : "-"
            :
            SUBTYPE[v.subType] ? SUBTYPE[v.subType] : "-"
          }
        </p>

        <label>
          <b>Button Text</b>
        </label>
        <p>
          {v.text}
        </p>

        {v.type === "quick_reply" &&
          <>
            <label>
              <b>Payload</b>
            </label>
            <p>
              {v.quickReplyPayload}
            </p>
          </>
        }

        {(v.subType === "url" || v.type === "url") &&
          <>
            <label>
              <b>URL Type</b>
            </label>
            <p>
              {v.urlNamed ? getURLParam(v.urlNamed).urlType : "-"}
            </p>
          </>
        }

        {(v.subType === "url" || v.type === "url") &&
          <>
            <label>
              <b>Website URL</b>
            </label>
            <p>
              {v.urlIndexed ? v.urlIndexed : "-"}
            </p>
          </>
        }

        {(v.subType === "phone_number" || v.type === "phone_number") &&
          <>
            <label>
              <b>Phone Number</b>
            </label>
            <p>
              <FiPlus />
              {HelperInput.phoneBeautify(v.phoneNumber)}
            </p>
          </>
        }

        {(_item.sampleParameters && v.subType === "url" && getURLParam(v.urlNamed).urlType === "Dynamic") &&
          <>
            <label>
              <b>Sample URL</b>
            </label>

            {_item.sampleParameters.button.length > 0 &&
              (_item.sampleParameters.button.map((_v, _i) => {
                return (
                  <div className="sample_url_wrapper" key={`sample_url_wrapper_${_i}`}>
                    <b>
                      {`{{1}}`}
                    </b>

                    <div className="box-value">
                      {_v.value}
                    </div>
                  </div>
                )
              }))
            }
          </>
        }
      </div>
    )
  }

  let printCarouselDetail = (data, idx, sampleParam) => {
    return (
      <div className="carousel-card-wrapper">
        <b className="card-title">Card #{idx + 1}</b>

        <div className="card-header-url">
          {data.headerFormat === "video" ?
            <>
              <FiVideo />
              <video src={data.headerFileURL} />
            </>
            :
            <>
              <FiImage />
              <img src={data.headerFileURL} alt="" />
            </>
          }
        </div>

        <p className="card-label">
          <b>Card Body</b>
        </p>

        <div className="disabled-form">
          <div>
            <p>
              {data.bodyTextNamed}
            </p>
          </div>
        </div>

        {(sampleParam[idx].body && sampleParam[idx].body.length > 0) &&
          <>
            {sampleParam[idx].body.map((vParamBody) => {
              return (
                <>
                  <div class="sample-param-input-wrapper">
                    <p>
                      <b>{`{{${vParamBody.name}}}`}</b>
                    </p>
                    <input type="text" placeholder={`sample content for {{${vParamBody.name}}}`} disabled />
                  </div>
                </>
              )
            })}
          </>
        }

        <p className="card-label">
          <b>Buttons</b>
        </p>

        {data.buttons.map((vDataButtons, iDataButtons) => {
          return (
            printButtonsCallToAction(vDataButtons, iDataButtons, sampleParam)
          )
        })}
      </div>
    )
  }

  return (
    <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
      <BackButton text="Back to Message Templates" onClick={back} />
      <div className="view-message-template-whatsapp sectionWrap">
        <div className="view-message-template-whatsapp-top">
          <b>View Message Template</b>
        </div>
        {isLoadingGetTemplateData || retrieveDataError.code ? (
          <div className="message-template-whatsapp-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetTemplateData}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : templateDetail ? (
          <React.Fragment>
            <div className="view-message-template-whatsapp-middle">
              <div className="view-message-template-whatsapp-middle-template-details">
                <FiInfo />
                <b>Template Details</b>
              </div>
              <div className="view-message-template-whatsapp-middle-template-name">
                <b>Template Name</b>
                <p>{templateDetail.template.name}</p>
              </div>
              <div className="view-message-template-whatsapp-middle-category">
                <b>Category</b>
                <p>{templateDetail.template.categoryName}</p>
              </div>
            </div>

            {templateDetail.languages.map((item, idx) => {
              return (
                <div
                  className="view-message-template-whatsapp-bottom"
                  key={`message-template-language-${idx}`}
                >
                  <div className="view-message-template-whatsapp-bottom-message-content">
                    {idx === 0 ? <img src={IconMessage} alt="" /> : <FiPlus />}
                    <b>{idx === 0 ? "Message Content" : "More Language"}</b>
                  </div>
                  <div className="view-message-box">
                    <div className="view-message-box-left">
                      <div className="view-message-template-whatsapp-bottom-language-code">
                        <b>Language Code</b>
                        <p>{item.languageName}</p>
                      </div>

                      {(templateDetail.template.categoryCode !== "AUTHENTICATION" && templateDetail.languages[0].variant !== "carousel") &&
                        <div className="view-message-template-whatsapp-bottom-header">
                          <b>Header</b>
                          {templateDetail.template.templateType === "text" ?
                            <p>
                              {getHeaderValue(item)}
                            </p>
                            :
                            (item.headerFormat === "image" ?
                              <div className="header-image">
                                <div className="icon-wrapper">
                                  <FiImage />
                                </div>
                                Image
                              </div>
                              :
                              (item.headerFormat === "video" ?
                                <div className="header-video">
                                  <div className="icon-wrapper">
                                    <FiVideo />
                                  </div>
                                  Video
                                </div>
                                :
                                <div className="header-document">
                                  <div className="icon-wrapper">
                                    <FiFile />
                                  </div>
                                  Document
                                </div>
                              )
                            )
                          }
                        </div>
                      }

                      {(item.parameters && item.parameters.header && item.parameters.header.length > 0) &&
                        (item.parameters.header.map((v, i) => {
                          return (
                            printSampleParam("header", i, v)
                          )
                        })

                        )
                      }

                      <div className="view-message-template-whatsapp-bottom-body marginTop24">
                        <b>Body</b>
                        <div className="disabled-form">
                          <div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  HelperChat.lineBreakWithoutTrim(
                                    item.bodyTextNamed
                                  )
                                ,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>

                      {(item.parameters && item.parameters.body && item.parameters.body.length > 0) &&
                        (item.parameters.body.map((v, i) => {
                          return (
                            printSampleParam("body", i, v)
                          )
                        }))
                      }

                      {item.cards &&
                        <div className="view-request-message-template-whatsapp-bottom-footer marginTop24 asd">
                          <b>Carousel</b>

                          {item.cards.map((vCards, iCards) => {
                            return printCarouselDetail(vCards, iCards, item.cardParameters)
                          })}
                        </div>
                      }

                      {templateDetail.languages[0].variant !== "carousel" &&
                        <div className="view-message-template-whatsapp-bottom-footer marginTop24">
                          <b>Footer</b>

                          <div className="disabled-form">
                            <div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.footerTextNamed ? item.footerTextNamed : "-",
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      }

                      {(item.buttons && item.buttons.length > 0) &&
                        <div className="view-request-message-template-whatsapp-bottom-footer marginTop24">
                          <b>Buttons</b>

                          <p className="margin-top-8">
                            {item.buttons[0] === "" ? "-" : (BUTTONS[item.buttonType] ? BUTTONS[item.buttonType] : "-")}
                          </p>


                          {/* quick reply */}
                          {(item.buttonType === "quick_reply" && item.buttons.length > 0) &&
                            <>
                              {item.buttons.map((v, i) => {
                                return printButtonsQuickReply(v, i);
                              })}
                            </>
                          }
                          {/* quick reply */}

                          {/* call to action */}
                          {item.buttonType === "call_to_action" &&
                            <>
                              {item.buttons.map((v, i) => {
                                return printButtonsCallToAction(v, i, item);
                              })}
                            </>
                          }
                          {/* call to action */}
                        </div>
                      }
                    </div>

                    <div className="view-message-box-right">
                      <label>
                        <b>Preview</b>
                      </label>

                      <WhatsappPreview
                        header={getHeaderValue(item) === "-" ? "" : getHeaderValue(item)}
                        body={item.bodyTextNamed}
                        footer={item.footerTextNamed}
                        template={getTemplateType()}
                        language={item}
                        categoryCode={templateDetail.template.categoryCode}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    </SectionWrap>
  );
};

export default ViewMessageTemplate;
