import React, { useState, useEffect } from "react";
import './ChatRoomEditContactInfo.scss';
import { FiEdit, FiUser, FiAtSign, FiInfo, FiBriefcase, FiCalendar, FiPhone } from "react-icons/fi";
import { setEditContactInfo } from "../../../../redux/actions/reduxActionEditContactInfo";
import { setChangeContactInfo } from "../../../../redux/actions/reduxActionChangeUserAlias";
import { connect } from 'react-redux';
import { autoFocusField, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperInput from '../../../../helper/HelperInput';
import HelperDate from "../../../../helper/HelperDate";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import UserContactServices from '../../../../services/newServices/UserContactServices';
import iconUsers from '../../../../assets/img/fi-user-circle.svg';
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import CalendarPickerSingle from '../../../reuseableComponent/calendarPickerSingle/CalendarPickerSingle';
import {
    FIELD_TYPE_SINGLE_LINE_TEXT,
    FIELD_TYPE_MULTILINE_TEXT,
    FIELD_TYPE_DROPDOWN,
    FIELD_TYPE_MULTIPLE_SELECT,
    FIELD_TYPE_DATE,
    FIELD_TYPE_NUMBER,
    VALIDATION_FREE_TEXT,
    VALIDATION_NUMBER,
    VALIDATION_NUMERIC,
    VALIDATION_ALPHANUMERIC,
    VALIDATION_TODAY_OR_LATER_DATES,
    VALIDATION_TODAY_OR_EARLIER_DATES,
    ERROR_MESSAGE_FIELD_REQUIRED,
    ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT,
} from '../../../setup/contactInfoCustomField/ContactInfoCustomField';

// import ExclamationRed from "../../../../assets/img/exclamation-red.svg";
// import { doToast } from "../../../../helper/HelperGeneral";

let ChatRoomEditContactInfo = (props) => {
    // let [isLoadingData, setIsLoadingData] = useState(false);
    let [contactInfo, setContactInfo] = useState(false);
    let [contactInfoOrigin, setContactInfoOrigin] = useState(false);
    let [showPopupConfirmation, setShowPopupConfirmation] = useState(false);
    let [showDiscard, setShowDiscard] = useState(false);
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        name: "",
        alias: "",
        companyName: "",
        jobTitle: "",
        email: "",
        phone: ""
    })
    const [customFields, setCustomFields] = useState([]);
    const [customFieldValues, setCustomFieldValues] = useState({});
    const [customFieldValuesOrigin, setCustomFieldValuesOrigin] = useState({});
    const [customFieldErrors, setCustomFieldErrors] = useState({});
    const [currentDateSelections, setCurrentDateSelections] = useState({});
    const [showingDatePickerFieldCode, setShowingDatePickerFieldCode] = useState(false);
    const [datePickerDisabledDays, setDatePickerDisabledDays] = useState({});

    useEffect(() => {
        // getContactInfo();
        if (props.caseDetailData) {
            let _case = {...props.caseDetailData};
            _case.user.phone = HelperInput.phoneBeautify(_case.user.phone.replaceAll(" ", ""));
            setContactInfo(_case.user);
            setContactInfoOrigin(_case.user);
            setCustomFields(_case.user.customFields);

            let _customFieldValues = { ...customFieldValues };
            let _currentDateSelections = { ...currentDateSelections };
            let _customFieldErrors = { ...customFieldErrors };
            if (_case.user.customFields && _case.user.customFields.length > 0) {
                _case.user.customFields.map((customField) => {
                    if (customField.fieldType === FIELD_TYPE_DATE) {
                        if (customField.value) {
                            const selectedDate = new Date(Date.parse(customField.value, "yyyy-MM-dd"));
                            _customFieldValues[customField.fieldCode] = selectedDate;
                            _currentDateSelections[customField.fieldCode] = selectedDate;
                        }
                        else {
                            _customFieldValues[customField.fieldCode] = "";
                        }
                    }
                    else if (customField.fieldType === FIELD_TYPE_DROPDOWN) {
                        _customFieldValues[customField.fieldCode] = {
                            value: customField.value ? customField.value : "",
                            label: customField.value ? customField.value : "",
                            array: [],
                            customField: customField
                        }
                    }
                    else if (customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT) {
                        let value = "";
                        if (customField.value) {
                            const multipleSelectValuesArray = JSON.parse(customField.value);
                            if (multipleSelectValuesArray && multipleSelectValuesArray.length > 0) {
                                value = multipleSelectValuesArray.map((e, i) => (e)).join(", ");
                            }
                        }
                        _customFieldValues[customField.fieldCode] = {
                            value: value,
                            label: value,
                            array: (customField.value && customField.value !== "[]") ? JSON.parse(customField.value) : [],
                            customField: customField
                        }
                    }
                    else {
                        _customFieldValues[customField.fieldCode] = customField.value ? customField.value : "";
                    }
                    _customFieldErrors[customField.fieldCode] = checkCustomFieldError(customField, _customFieldValues[customField.fieldCode]);

                    return null;
                });
            }
            setCustomFieldValues(_customFieldValues);
            setCustomFieldValuesOrigin({..._customFieldValues});
            setCurrentDateSelections(_currentDateSelections);
            setCustomFieldErrors(_customFieldErrors);
        }

        if (props.editContactInfo.isShow) {
            setTimeout(() => {
                autoFocusField("edit-contact-input-1");
            }, 400)
        }
    }, [props.editContactInfo.isShow])

    useEffect(() => {
        if (props.activeRoom) {
            hidePopupContactInfo();
        }
    }, [props.activeRoom])

    useEffect(() => {
        if (customFields && customFields.length > 0) {
            let _customFieldValues = { ...customFieldValues };
            let _customFieldErrors = { ...customFieldErrors };
            customFields.map((customField) => {
                if (!_customFieldValues[customField.fieldCode]) {
                    if (customField.fieldType === FIELD_TYPE_DROPDOWN || customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT) {
                        _customFieldValues[customField.fieldCode] = {
                            value: "",
                            label: "",
                            customField: customField,
                        };
                    }
                    else {
                        _customFieldValues[customField.fieldCode] = "";
                    }
                }
                if (!_customFieldErrors[customField.fieldCode]) {
                    _customFieldErrors[customField.fieldCode] = "";
                }
                checkAndResizeTextArea(customField);

                return null;
            });
            setCustomFieldValues(_customFieldValues);
            setCustomFieldErrors(_customFieldErrors);
        }
    }, [customFields]);

    let saveContact = () => {

        setIsLoadingSubmit(true);

        let customFieldRequest = [];
        if (customFields && customFields.length > 0) {
            customFields.map((customField, index) => {
                let multipleSelectValue = "[]";
                if (customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT &&
                    customFieldValues[customField.fieldCode] &&
                    customFieldValues[customField.fieldCode].array &&
                    customFieldValues[customField.fieldCode].array.length > 0
                ) {
                    customFieldValues[customField.fieldCode].array.map((v, i) => {
                        multipleSelectValue = multipleSelectValue.slice(0, multipleSelectValue.length - 1) + (i === 0 ? `"` : `,"`) + v + `"` + multipleSelectValue.slice(multipleSelectValue.length - 1);

                        return null;
                    });
                }
                customFieldRequest.push({
                    fieldID: customField.fieldID,
                    value:
                        customField.fieldType === FIELD_TYPE_DATE
                            ?
                            customFieldValues[customField.fieldCode] ? HelperDate.formatToString(new Date(customFieldValues[customField.fieldCode]), "yyyy-MM-dd") : ""
                            :
                            customField.fieldType === FIELD_TYPE_DROPDOWN
                                ?
                                (customFieldValues[customField.fieldCode] && customFieldValues[customField.fieldCode].value) ? customFieldValues[customField.fieldCode].value : ""
                                :
                                customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT
                                    ?
                                    multipleSelectValue
                                    :
                                    '' + customFieldValues[customField.fieldCode]
                });

                return null;
            });
        }

        const dataUser = {
            userID: contactInfo.userID,
            fullName: contactInfo.fullName,
            alias: contactInfo.alias,
            email: contactInfo.email,
            phone: contactInfo.phone.replaceAll(" ", ""),
            companyName: contactInfo.companyName,
            jobTitle: contactInfo.jobTitle,
            customFields: customFieldRequest
        }

        UserContactServices.editContact(props.parentProps.match.params.orgID, dataUser, (response) => {
            if (response.dataResult.error.message === "") {
                if (!response.dataResult.data.success) {
                    doToast(response.dataResult.data.message);
                }
                else {
                    setContactInfoOrigin(contactInfo);
                    setCustomFieldValuesOrigin(customFieldValues);

                    props.setChangeContactInfo({
                        state: true,
                        alias: contactInfo.alias,
                        userID: contactInfo.userID,
                        fullname: contactInfo.fullName,
                        email: contactInfo.email,
                        phone: contactInfo.phone,
                        companyName: contactInfo.companyName,
                        jobTitle: contactInfo.jobTitle,
                        customFields: contactInfo.customFields
                    });

                    doToast(response.dataResult.data.message);
                    hidePopupContactInfo();
                }
            }
            else {
                doToast(response.dataResult.error.message, 'fail');
                if (response.dataResult.error.field.includes("customFields")) {
                    const errorField = response.dataResult.error.field.replaceAll("customFields.", "");
                    let _customFieldErrors = { ...customFieldErrors };
                    _customFieldErrors[errorField] = response.dataResult.error.message;
                    setCustomFieldErrors(_customFieldErrors);
                }
            }

            togglePopupConfirmation();
            setIsLoadingSubmit(false);
        });
    }

    const checkCustomFieldError = (customField, value, checkRequired) => {
        let errorMessage = "";
        if (checkRequired &&
            customField.isRequired &&
            (!value || ((customField.fieldType === FIELD_TYPE_DROPDOWN || customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT) && !value.value))
        ) {
            errorMessage = ERROR_MESSAGE_FIELD_REQUIRED;
        }
        else if (customField.validation === VALIDATION_FREE_TEXT && value && /\p{Extended_Pictographic}/u.test(value)) {
            errorMessage = "Text may not contain emoji";
        }
        else if (customField.validation === VALIDATION_NUMBER && value && (/[^\d-]/g.test(value) || value.lastIndexOf('-') > 0 || (checkRequired && value === '-'))) {
            errorMessage = "You can only fill this field with positive or negative number";
        }
        else if (customField.validation === VALIDATION_NUMERIC && value && /[^\d]/g.test(value)) {
            errorMessage = "You can only fill this field with numbers";
        }
        else if (customField.validation === VALIDATION_ALPHANUMERIC && value && !(/^[a-zA-Z0-9]*$/).test(value)) {
            errorMessage = "You can only fill this field with alphabets and numbers with no space";
        }
        else if (customField.maxCharacters > 0 && value && value.length > customField.maxCharacters) {
            errorMessage = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
        }
        return errorMessage;
    }

    const onCustomFieldTextChanged = (e, customField) => {
        let _customFieldValues = { ...customFieldValues };
        _customFieldValues[customField.fieldCode] = e.target.value;
        setCustomFieldValues(_customFieldValues);

        let _customFieldErrors = { ...customFieldErrors };
        _customFieldErrors[customField.fieldCode] = checkCustomFieldError(customField, e.target.value);
        setCustomFieldErrors(_customFieldErrors);

        checkAndResizeTextArea(customField);
    }

    const checkAndResizeTextArea = (customField) => {
        if (customField.fieldType === FIELD_TYPE_MULTILINE_TEXT) {
            const index = customFields.indexOf(customField);
            const inputs = document.getElementsByClassName(`custom-field-input-${index}`);
            if (!inputs) {
                return;
            }
            const textarea = inputs[0].getElementsByTagName('textarea')[0];
            if (!textarea) {
                return;
            }
            if (textarea.offsetHeight === textarea.scrollHeight) {
                return;
            }
            textarea.style.height = "1px"; // Reset height
            if (textarea.scrollHeight <= 79) {
                textarea.style.height = "" + textarea.scrollHeight + "px";
                textarea.style.overflow = "hidden";
            }
            else {
                textarea.style.height = "79px";
                textarea.style.overflow = "auto";
            }
        }
    }

    const onCustomFieldDropdownChanged = (option) => {
        let _customFieldValues = { ...customFieldValues };
        let _customFieldErrors = { ...customFieldErrors };
        if (!option.customField.fieldCode ||
            (customFieldValues[option.customField.fieldCode] &&
                customFieldValues[option.customField.fieldCode].value === option.value)
        ) {
            let deselectOption = {
                value: "",
                label: "",
                customField: option.customField
            };
            _customFieldValues[option.customField.fieldCode] = deselectOption;
        }
        else {
            let selectedOption = {
                value: option.value,
                label: option.label,
                customField: option.customField
            };
            _customFieldValues[option.customField.fieldCode] = selectedOption;
            _customFieldErrors[option.customField.fieldCode] = "";
        }
        setCustomFieldValues(_customFieldValues);
        setCustomFieldErrors(_customFieldErrors);
    }

    const onCustomFieldMultipleSelectChanged = (option) => {
        if (!option.customField.fieldCode) {
            return;
        }

        let _customFieldValues = { ...customFieldValues };

        let selectedOption = {
            value: option.value,
            label: option.label,
            array: [],
            customField: option.customField
        };
        let currentArray = _customFieldValues[option.customField.fieldCode].array;
        if (!currentArray) {
            currentArray = [];
        }
        if (currentArray.includes(option.label)) {
            // Remove selected option
            const currentIndex = currentArray.indexOf(option.label);
            currentArray.splice(currentIndex, 1);
        }
        else {
            // Add selected option
            currentArray.push(option.label);
        }
        selectedOption.array = currentArray;
        const joinedLabel = currentArray.map((e, i) => (e)).join(", ");
        selectedOption.label = joinedLabel;
        selectedOption.value = joinedLabel;
        _customFieldValues[option.customField.fieldCode] = selectedOption;
        setCustomFieldValues(_customFieldValues);

        let _customFieldErrors = { ...customFieldErrors };
        _customFieldErrors[option.customField.fieldCode] = "";
        setCustomFieldErrors(_customFieldErrors);
    }

    let togglePopupConfirmation = () => {
        if (!isLoadingSubmit) {
            setShowPopupConfirmation(!showPopupConfirmation);
        }
    }

    let toggleDiscard = () => {
        setShowDiscard(!showDiscard);
    }

    let onChangeInput = (e) => {
        let _ctc = { ...contactInfo };
        let _errorMessageData = { ...errorMessageData };

        if (e.target.id === "fullName") {
            _errorMessageData.name = "";
        }

        if (e.target.id === "phone") {
            if (HelperInput.numberOnly(e.target.value.replaceAll(" ", "").replaceAll("+", ""))) {
                _ctc[e.target.id] = HelperInput.phoneBeautify(e.target.value.replaceAll(" ", ""));
            }
        }
        else {
            _ctc[e.target.id] = e.target.value;
            let maxChars = 0;
            if (e.target.id === "fullName") {
                maxChars = 200;
            }
            else if (e.target.id === "alias") {
                maxChars = 100;
            }
            else if (e.target.id === "companyName") {
                maxChars = 200;
            }
            else if (e.target.id === "jobTitle") {
                maxChars = 50;
            }
            else if (e.target.id === "email") {
                maxChars = 250;
            }
            if (e.target.value.length > maxChars) {
                _errorMessageData[e.target.id] = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
            }
            else {
                _errorMessageData[e.target.id] = "";
            }
        }

        setErrorMessageData(_errorMessageData);
        setContactInfo(_ctc);
    }

    // let isDisabledSubmit = () => {
    // let isDis = false;

    //     if(contactInfo.fullName === "") {
    //         isDis = true;
    //     }

    //     if(contactInfo.email !== "") {
    //         if(!HelperInput.emailFormatValid(contactInfo.email)) {
    //             isDis = true;
    //         }
    //     }

    //     return isDis;
    // }

    let hidePopupContactInfo = () => {
        let _editContactInfo = { ...props.editContactInfo };
        _editContactInfo.isShow = false;
        props.setEditContactInfo(_editContactInfo);
        setCustomFieldErrors({});
    }

    let toggleDatePicker = () => {
        let _currentDateSelections = { ...currentDateSelections };
        _currentDateSelections[showingDatePickerFieldCode] = customFieldValues[showingDatePickerFieldCode];
        setShowingDatePickerFieldCode(false);
    }

    let handleDateFilterClick = (d, { disabled }) => {
        if (disabled || !showingDatePickerFieldCode) {
            return;
        }

        let _currentDateSelections = { ...currentDateSelections };
        _currentDateSelections[showingDatePickerFieldCode] = d;
        setCurrentDateSelections(_currentDateSelections);
    }

    let submitDateFilter = () => {
        if (!showingDatePickerFieldCode) {
            return;
        }

        let _customFieldValues = { ...customFieldValues };
        _customFieldValues[showingDatePickerFieldCode] = currentDateSelections[showingDatePickerFieldCode];
        setCustomFieldValues(_customFieldValues);

        let _customFieldErrors = { ...customFieldErrors };
        _customFieldErrors[showingDatePickerFieldCode] = "";
        setCustomFieldErrors(_customFieldErrors);

        toggleDatePicker(false);
    }

    let clearDatePicker = () => {
        if (!showingDatePickerFieldCode) {
            return;
        }

        let _customFieldValues = { ...customFieldValues };
        _customFieldValues[showingDatePickerFieldCode] = "";
        setCustomFieldValues(_customFieldValues);

        let _currentDateSelections = { ...currentDateSelections };
        _currentDateSelections[showingDatePickerFieldCode] = "";
        setCurrentDateSelections(_currentDateSelections);

        setShowingDatePickerFieldCode(false);
    }

    return (
        <>
            <div
                className={`
                    chat-room-contact-info-
                    ${props.editContactInfo.isShow ? "chat-room-contact-info-active" : ""}
                `}
            >
                <div className={`chat-room-contact-info-title`}>
                    <FiEdit />
                    <b>Edit Contact Details</b>
                </div>

                {showPopupConfirmation &&
                    <PopupConfirmation
                        isOpen={showPopupConfirmation}
                        toggle={togglePopupConfirmation}
                        title="Save Changes?"
                        description="There has been changes in this contact’s detail. Are you sure you want to continue?"
                        className="modal-confirmation-edit-contact"
                    >
                        {!isLoadingSubmit ?
                            <ButtonWithLoadingOrIcon
                                text="Save Changes"
                                className="main-button-48 orange-button"
                                onClickAction={saveContact}
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                text="Save Changes"
                                className="main-button-48 orange-button"
                                isLoading
                                isDisabled
                                loadingColor="gray"
                                position="left"
                            />
                        }
                    </PopupConfirmation>
                }

                {showDiscard &&
                    <PopupConfirmation
                        isOpen={showDiscard}
                        toggle={toggleDiscard}
                        title="Discard Progress?"
                        description="There has been unsaved changes in this contact’s detail. Are you sure you want to go back without saving?"
                        className="modal-confirmation-edit-contact"
                    >
                        <ButtonWithLoadingOrIcon
                            text="Discard Changes"
                            className="main-button-48 red-button"
                            onClickAction={() => {
                                toggleDiscard();
                                hidePopupContactInfo();
                            }}
                        />
                    </PopupConfirmation>
                }

                {contactInfo &&
                    <div className="chat-room-contact-info-edit-wrapper">
                        <div className="chat-room-contact-info-edit-inner">
                            <label className='margin-top-0 pos-relative'>
                                <b>Full Name</b>
                                <span className={contactInfo.fullName.length > 200 ? "red-text absolute-right-0 char-count" : "grey-font absolute-right-0 char-count"}>{contactInfo.fullName.length}/200</span>
                            </label>

                            <div className="edit-contact-info-input-wrapper">
                                <FiUser />
                                <input
                                    type="text"
                                    value={contactInfo.fullName}
                                    placeholder="Input Full Name"
                                    onChange={(e) => onChangeInput(e)}
                                    id="fullName"
                                    className={`edit-contact-input-1 ${errorMessageData.name !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.name !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.name}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Alias<span> (optional)</span></b>
                                <span className={contactInfo.alias.length > 100 ? "red-text absolute-right-0 char-count" : "grey-font absolute-right-0 char-count"}>{contactInfo.alias.length}/100</span>
                            </label>
                            <div className="edit-contact-info-input-wrapper">
                                <FiUser />
                                <input
                                    type="text"
                                    value={contactInfo.alias}
                                    placeholder="E.g John"
                                    id="alias"
                                    onChange={(e) => onChangeInput(e)}
                                    className={`edit-contact-input-2 ${errorMessageData.alias !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.alias !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.alias}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Company<span> (optional)</span></b>
                                <span className={contactInfo.companyName.length > 200 ? "red-text absolute-right-0 char-count" : "grey-font absolute-right-0 char-count"}>{contactInfo.companyName.length}/200</span>
                            </label>

                            <div className="edit-contact-info-input-wrapper">
                                <FiBriefcase />
                                <input
                                    type="text"
                                    value={contactInfo.companyName}
                                    placeholder="E.g TapTalk"
                                    id="companyName"
                                    onChange={(e) => onChangeInput(e)}
                                    className={`edit-contact-input-3 ${errorMessageData.companyName !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.companyName !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.companyName}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Job Title<span> (optional)</span></b>
                                <span className={contactInfo.jobTitle.length > 50 ? "red-text absolute-right-0 char-count" : "grey-font absolute-right-0 char-count"}>{contactInfo.jobTitle.length}/50</span>
                            </label>

                            <div className="edit-contact-info-input-wrapper">
                                <img alt="" src={iconUsers} />
                                <input
                                    type="text"
                                    value={contactInfo.jobTitle}
                                    placeholder="E.g Account Executive"
                                    id="jobTitle"
                                    onChange={(e) => onChangeInput(e)}
                                    className={`edit-contact-input-4 ${errorMessageData.jobTitle !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.jobTitle !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.jobTitle}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Email</b>
                                <span className={contactInfo.email.length > 250 ? "red-text absolute-right-0 char-count" : "grey-font absolute-right-0 char-count"}>{contactInfo.email.length}/250</span>
                            </label>

                            <div className="edit-contact-info-input-wrapper">
                                <FiAtSign />
                                <input
                                    type="text"
                                    value={contactInfo.email}
                                    placeholder="Input Email Address"
                                    id="email"
                                    onChange={(e) => onChangeInput(e)}
                                    className={`edit-contact-input-5 ${errorMessageData.email !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.email !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.email}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Phone Number</b>

                                {/* <span className={errorMessageData.phone === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{contactInfo.phone.replaceAll(" ", "").length}/15</span> */}
                            </label>

                            <div className="edit-contact-info-input-wrapper edit-contact-phone">
                                <FiPhone />
                                <input
                                    type="text"
                                    value={"+" + contactInfo.phone}
                                    placeholder="Input Phone Number"
                                    id="phone"
                                    onChange={(e) => onChangeInput(e)}
                                    disabled={contactInfoOrigin.phone === "" ? false : true}
                                    maxLength={20}
                                    className={`edit-contact-input-6 ${errorMessageData.phone !== "" ? "border-red" : ""}`}
                                />
                            </div>

                            <p className="grey-font-6">
                                <FiInfo />
                                Always start the phone number with the country code without the "+" sign e.g. "681297304400".
                            </p>

                            {
                                customFields &&
                                customFields.length > 0 &&
                                <>
                                    <div className="contact-custom-field-separator" />
                                    {
                                        customFields.map((customField, index) => {
                                            let mappedOptions = [];
                                            if (customField.options && customField.options.length > 0) {
                                                customField.options.map((option, index) => {
                                                    mappedOptions.push({
                                                        value: option,
                                                        label: option,
                                                        customField: customField,
                                                    });

                                                    return null;
                                                });
                                                if (!customFieldValues[customField.fieldCode]) {
                                                    customFieldValues[customField.fieldCode] = {
                                                        value: "",
                                                        label: "",
                                                        customField: customField
                                                    }
                                                }
                                            }

                                            return (
                                                <>
                                                    <div
                                                        id={`custom-field-input-${index}`}
                                                        className={`form-contact-custom-field custom-field-input-${index}`}
                                                        key={`custom-field-wrapper-${index}`}
                                                    >
                                                        <label className='pos-relative'>
                                                            <b>{customField.fieldName}</b>
                                                            {
                                                                !customField.isRequired &&
                                                                <span> (optional)</span>
                                                            }
                                                            {
                                                                (customField.maxCharacters && customField.maxCharacters > 0) ?
                                                                    <span className={
                                                                        (customFieldValues[customField.fieldCode] &&
                                                                            customFieldValues[customField.fieldCode].length > customField.maxCharacters) ?
                                                                            "red-text absolute-right-0 char-count"
                                                                            :
                                                                            "grey-font absolute-right-0 char-count"
                                                                    }
                                                                    >
                                                                        {
                                                                            customFieldValues[customField.fieldCode] ?
                                                                                customFieldValues[customField.fieldCode].length :
                                                                                0
                                                                        }/{customField.maxCharacters}
                                                                    </span>
                                                                    :
                                                                    <></>
                                                            }
                                                        </label>
                                                        {
                                                            (customField.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT || customField.fieldType === FIELD_TYPE_NUMBER) ?
                                                                <input
                                                                    className={`${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                                                    type="text"
                                                                    value={customFieldValues[customField.fieldCode]}
                                                                    onChange={(e) => onCustomFieldTextChanged(e, customField)}
                                                                    placeholder={
                                                                        customField.fieldType === FIELD_TYPE_SINGLE_LINE_TEXT ? "Input single line text" :
                                                                            customField.fieldType === FIELD_TYPE_NUMBER ? "Input number" :
                                                                                "Input custom field"
                                                                    }
                                                                />
                                                                :
                                                                customField.fieldType === FIELD_TYPE_MULTILINE_TEXT ?
                                                                    <textarea
                                                                        className={`${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                                                        value={customFieldValues[customField.fieldCode]}
                                                                        placeholder="Input multiline text"
                                                                        onChange={(e) => onCustomFieldTextChanged(e, customField)}
                                                                    />
                                                                    :
                                                                    (customField.fieldType === FIELD_TYPE_DROPDOWN && mappedOptions.length > 0) ?
                                                                        <CustomSelectOption
                                                                            optionListProps={mappedOptions}
                                                                            valueDropdownProps={customFieldValues[customField.fieldCode]}
                                                                            placeholderProps={"Select Option"}
                                                                            onClickDropDownListOptionProps={onCustomFieldDropdownChanged}
                                                                            specificClassNameProps={`custom-select-whatsapp-topic" ${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                                                            _className={`${errorMessageData.validation ? "border-red" : ""}`}
                                                                        />
                                                                        :
                                                                        (customField.fieldType === FIELD_TYPE_MULTIPLE_SELECT && mappedOptions.length > 0) ?
                                                                            <CustomSelectOption
                                                                                optionListProps={mappedOptions}
                                                                                valueDropdownProps={customFieldValues[customField.fieldCode]}
                                                                                placeholderProps={"Select Options"}
                                                                                onClickDropDownListOptionProps={onCustomFieldMultipleSelectChanged}
                                                                                specificClassNameProps={`custom-select-whatsapp-topic" ${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                                                                _className={`${errorMessageData.validation ? "border-red" : ""}`}
                                                                                isMultipleSelect={true}
                                                                            />
                                                                            :
                                                                            customField.fieldType === FIELD_TYPE_DATE ?
                                                                                <div
                                                                                    className={`contact-date-value-wrapper ${customFieldErrors[customField.fieldCode] ? "border-red" : ""}`}
                                                                                    onClick={() => {
                                                                                        if (customFieldValues[customField.fieldCode]) {
                                                                                            let _currentDateSelections = { ...currentDateSelections };
                                                                                            _currentDateSelections[customField.fieldCode] = customFieldValues[customField.fieldCode];
                                                                                            setCurrentDateSelections(_currentDateSelections);
                                                                                        }
                                                                                        setDatePickerDisabledDays(
                                                                                            (customField.validation === VALIDATION_TODAY_OR_LATER_DATES) ?
                                                                                                { before: new Date() }
                                                                                                :
                                                                                                (customField.validation === VALIDATION_TODAY_OR_EARLIER_DATES) ?
                                                                                                    { after: new Date() }
                                                                                                    :
                                                                                                    {}
                                                                                        );
                                                                                        setShowingDatePickerFieldCode(customField.fieldCode);
                                                                                    }}
                                                                                >
                                                                                    <FiCalendar />
                                                                                    {customFieldValues[customField.fieldCode] ?
                                                                                        HelperDate.formatToString(new Date(customFieldValues[customField.fieldCode]), "dd MMM yyyy")
                                                                                        :
                                                                                        <span className="grey-font">
                                                                                            Select date
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <></>
                                                        }
                                                    </div>
                                                    {
                                                        customFieldErrors[customField.fieldCode] &&
                                                        customFieldErrors[customField.fieldCode] !== ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT &&
                                                        <ErrorOrInfoComp
                                                            text={customFieldErrors[customField.fieldCode]}
                                                            _className={"font-red"}
                                                            icon={<FiInfo />}
                                                        />
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }
                        </div>

                        <div className="edit-contact-button">
                            <ButtonWithLoadingOrIcon
                                text="Cancel"
                                className="main-button-40 no-fill-button-grey"
                                onClickAction={() => {
                                    if (JSON.stringify(contactInfo) === JSON.stringify(contactInfoOrigin) &&
                                        JSON.stringify(customFieldValues) === JSON.stringify(customFieldValuesOrigin)
                                    ) {
                                        hidePopupContactInfo();
                                    } else {
                                        toggleDiscard();
                                    }
                                }}
                            />

                            <ButtonWithLoadingOrIcon
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                onClickAction={() => {
                                    let errorCount = 0;
                                    let errorClass = "";
                                    let _errorMessageData = { ...errorMessageData };

                                    if (contactInfo.fullName === "") {
                                        _errorMessageData.name = "This field is required";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "edit-contact-input-1" }
                                    }

                                    if (contactInfo.fullName.length > 200) {
                                        _errorMessageData.name = "Characters exceed limit";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "edit-contact-input-1" }
                                    }

                                    if (contactInfo.alias.length > 100) {
                                        _errorMessageData.alias = "Characters exceed limit";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "edit-contact-input-2" }
                                    }

                                    if (contactInfo.companyName.length > 200) {
                                        _errorMessageData.companyName = "Characters exceed limit";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "edit-contact-input-3" }
                                    }

                                    if (contactInfo.jobTitle.length > 50) {
                                        _errorMessageData.jobTitle = "Characters exceed limit";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "edit-contact-input-4" }
                                    }

                                    if (contactInfo.email.length > 250) {
                                        _errorMessageData.email = "Characters exceed limit";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "edit-contact-input-5" }
                                    }

                                    if (contactInfo.phone.replaceAll(" ", "").length > 15) {
                                        _errorMessageData.phone = "Characters exceed limit";
                                        errorCount = errorCount + 1;

                                        if (errorClass === "") { errorClass = "edit-contact-input-6" }
                                    }

                                    if (customFields && customFields.length > 0) {
                                        let _customFieldErrors = { ...customFieldErrors };
                                        customFields.map((customField, index) => {
                                            let customFieldErrorMessage = checkCustomFieldError(customField, customFieldValues[customField.fieldCode], true);
                                            _customFieldErrors[customField.fieldCode] = customFieldErrorMessage;
                                            if (customFieldErrorMessage) {
                                                errorCount = errorCount + 1;
                                                if (errorClass === "") {
                                                    errorClass = `custom-field-input-${index}`;
                                                }
                                            }

                                            return null;
                                        });
                                        setCustomFieldErrors(_customFieldErrors);
                                    }

                                    if (errorCount > 0) {
                                        setErrorMessageData(_errorMessageData);
                                        scrollToClass(`.${errorClass}`);
                                    } else {
                                        togglePopupConfirmation();
                                    }
                                }}
                            // isDisabled={isDisabledSubmit()}
                            />
                        </div>
                    </div>
                }

                <CalendarPickerSingle
                    isOpen={showingDatePickerFieldCode}
                    toggle={toggleDatePicker}
                    date={currentDateSelections[showingDatePickerFieldCode]}
                    handleDateFilterClick={handleDateFilterClick}
                    submitDateFilter={submitDateFilter}
                    clearDatePicker={clearDatePicker}
                    disabledDays={datePickerDisabledDays}
                    allowSubmitEmptyDate
                    {...props}
                />
            </div>
        </>
    )
}

let mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    editContactInfo: state.editContactInfo,
    caseDetailData: state.caseDetailData
})

let mapDispatchToProps = {
    setEditContactInfo,
    setChangeContactInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomEditContactInfo);