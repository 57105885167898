import React, { useState } from 'react';
import './ChatRoomMessageOutEmail.scss';
import { taptalk } from '@taptalk.io/web-sdk';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FiInfo } from 'react-icons/fi';
import MessageDeleteGrey from "../../../../assets/img/icon-notallowed-grey.svg";
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";
import iconAgent from '../../../../assets/img/chatroom/icon-agent.svg';
import iconChatbot from '../../../../assets/img/chatroom/icon-chatbot.svg';
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName } from '../../../../helper/HelperGeneral';
import { CHAT_TYPE } from '../../../../helper/HelperConst';
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';
import ChatRoomMessageLinkPreview from '../chatRoomMessageLinkPreview/ChatRoomMessageLinkPreview';
import ChatRoomEmailCompText from '../chatRoomMessageEmailComp/ChatRoomEmailCompText';
import ChatRoomEmailCompDocs from '../chatRoomMessageEmailComp/ChatRoomEmailCompDocs';
// import ChatRoomEmailCompHtml from '../chatRoomMessageEmailComp/ChatRoomEmailCompHtml';
import ChatRoomEmailCompInfo from '../chatRoomMessageEmailComp/ChatRoomEmailCompInfo';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageOutEmail = (props) => {
    let {
        singleChatDataProps,
        caseData
    } = props;

    let [showDropdownEmailInfo, setShowDropdownEmailInfo] = useState(false);

    let toggleEmailInfo = () => {
        setShowDropdownEmailInfo(!showDropdownEmailInfo);
    }

    let generateTo = () => {
        let to = "";

        if(singleChatDataProps.data.to && singleChatDataProps.data.to.length > 0) {
            if (singleChatDataProps.data.to[0].split(`<`).length > 1) {
                if(singleChatDataProps.data.to[0].split(`<`)[0] === "") {
                    to = singleChatDataProps.data.to[0].split(`<`)[1].replaceAll(">", "");
                }else {
                    to = singleChatDataProps.data.to[0].split(`<`)[0].replaceAll(`"`, "");
                }
            } else {
                to = singleChatDataProps.data.to[0];
            }
        }

        return to;
    }

    let myAgentAccountID = parseInt(props.myAgentData.account.id);
    let chatUserID = parseInt(singleChatDataProps.user.xcUserID.replace("agent:", ""));

    return (
        <div className={`chat-room-message-out-email-wrapper`} id={`message-${singleChatDataProps.localID}`}>
            {singleChatDataProps.isDeleted ?
                <div className="message-out-bubble deleted-bubble">
                    <React.Fragment>
                        <img src={MessageDeleteGrey} alt="" className="deleted-icon" />
                        This message was deleted.
                    </React.Fragment>
                </div>
                :

                <div className={`message-out-bubble`}>
                    <div className={`message-out-email-wrapper-top ${myAgentAccountID !== chatUserID ? "message-out-email-wrapper-top-blue" : ""}`}>
                        <div className='top-avatar-wrapper' style={{ background: taptalk.getRandomColor(printLastMessageName(singleChatDataProps, caseData, true, true)) }}>
                            {singleChatDataProps.user.imageURL.thumbnail !== "" ?
                                <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" onError={(e) => { e.target.onerror = null; e.target.src = iconReplaceAvatar; }} />
                                :
                                <b>{Helper.renderUserAvatarWord(printLastMessageName(singleChatDataProps, caseData, true, true))}</b>
                            }

                            {(singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value ||
                                singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value
                            ) &&
                                <img src={iconAgent} alt="" className="avatar-icon-badge" />
                            }

                            {(singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value) &&
                                <img src={iconChatbot} alt="" className="avatar-icon-badge" />
                            }
                        </div>

                        <div className='top-info-wrapper'>
                            <p className='top-name'>

                                <b>
                                    {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                        <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                    }

                                    {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                        <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                    }

                                    {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                        <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                    }

                                    <span className='orange-text'>{printLastMessageName(singleChatDataProps, caseData, true)}</span>
                                </b>

                            </p>

                            <p className='top-subject'>
                                {singleChatDataProps.data.subject === "" ? "(no subject)" : singleChatDataProps.data.subject}
                            </p>

                            <span className='top-info'>
                                <p className='top-info-name'>to {generateTo()}</p>

                                <Dropdown isOpen={showDropdownEmailInfo} toggle={toggleEmailInfo}>
                                    <DropdownToggle>
                                        <FiInfo className={`top-info-arrow ${showDropdownEmailInfo ? "rotate-arrow" : ""}`} />
                                    </DropdownToggle>

                                    <DropdownMenu>
                                        <DropdownItem>
                                            <ChatRoomEmailCompInfo singleChatDataProps={singleChatDataProps} />
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </span>
                        </div>

                        <p className="message-info">
                            {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}
                        </p>
                    </div>

                    <div className='message-out-email-wrapper-bottom'>
                        <ChatRoomEmailCompText
                            singleChatDataProps={singleChatDataProps}
                        />

                        {singleChatDataProps.data.attachments &&
                            <ChatRoomEmailCompDocs
                                singleChatDataProps={singleChatDataProps}
                            />
                        }

                        {/* <ChatRoomEmailCompHtml /> */}
                    </div>

                    {singleChatDataProps.type === CHAT_TYPE.TAPChatMessageTypeLink &&
                        <ChatRoomMessageLinkPreview
                            isOut={true}
                            message={singleChatDataProps}
                        />
                    }
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutEmail);
