import React, { useState, useEffect } from "react";
import "./UserContact.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { FiPlus } from "react-icons/fi";
import iconUser from '../../assets/img/user-contact/icon-user-plus.svg'
import iconNew from '../../assets/img/user-contact/icon-add-new.svg'
import iconImport from '../../assets/img/user-contact/icon-add-import.svg'
import iconUpload from '../../assets/img/user-contact/icon-upload.svg'
import iconAdd from '../../assets/img/user-contact/icon-add-user.svg'
import { FiX, FiEye, FiAlertTriangle, FiRotateCcw, FiDownload } from "react-icons/fi";
import { Modal, ModalBody } from 'reactstrap';
import Pagination from '../reuseableComponent/pagination/Pagination'
import CustomSelectOption from '../reuseableComponent/customSelectOption/CustomSelectOption'
import ServiceOrganization from '../../services/organization/ServiceOrganization'
import UserContactServices from '../../services/newServices/UserContactServices'
import { checkID, doToast, findMyRole, showHideLauncher} from '../../helper/HelperGeneral';
import HelperInput from '../../helper/HelperInput';
import HelperDate from '../../helper/HelperDate'
import PopupRemove from '../reuseableComponent/popupRemove/PopupRemove';
import iconWhatsapp from '../../assets/img/user-contact/icon-whatsapp.svg'
import iconWhatsappBa from '../../assets/img/user-contact/icon-whatsapp-ba.svg'
import iconFacebook from '../../assets/img/user-contact/icon-facebook.svg'
import iconGoogle from '../../assets/img/user-contact/icon-google.svg'
import iconGoogleProfile from '../../assets/img/user-contact/icon-google-profile.svg'
import iconTelegram from '../../assets/img/user-contact/icon-telegram.svg'
import iconLauncher from '../../assets/img/user-contact/icon-launcher.svg'
import iconBroadcast from '../../assets/img/user-contact/icon-broadcast.svg'
import iconTwitter from '../../assets/img/user-contact/icon-twitter.svg'
import iconLine from '../../assets/img/user-contact/icon-line.svg'
import iconInstagram from '../../assets/img/user-contact/icon-instagram.svg'
import iconTokopedia from '../../assets/img/user-contact/icon-tokopedia.svg'
import ButtonWithLoadingOrIcon from '../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import SearchBox from "../reuseableComponent/searchBox/SearchBox";
import ContactCustomFieldServices from "../../services/newServices/ContactCustomFieldServices";
import { connect } from 'react-redux';

let UserContact = props => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [showModalAddContact, setShowModalAddContact] = useState(false);
  let [listPage, setListPage] = useState(20)
  // let [isSearchFocus, setIsSearchFocus] = useState(false)
  let [showModalImporting, setShowModalImporting] = useState(false)
  let [isImporting, setIsImporting] = useState(false);
  // let [firstLoad, setFirstLoad] = useState(false)
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  // let [isFailedImporting, setIsFailedImporting] = useState(false);
  let [isLoadingTableUser, setIsLoadingTableUser] = useState(false)
  let [dataUserContact, setDataUserContact] = useState([])
  let [totalUsers, setTotalUsers] = useState(0)
  let [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  let [pageDisplay, setPageDisplay] = useState(1)
  let [isShowModalSaveChanges, setIsShowModalSaveChanges] = useState(false)
  // let [waitForResponseSaveChanges, setWaitForResponseSaveChanges] = useState(false)
  let [isShowModalDiscard, setIsShowModalDiscard] = useState(false)
  // let [waitForResponseDiscard, setWaitForResponseDiscard] = useState(false)
  let [query, setQuery] = useState("")
  let [submitQuery, setSubmitQuery] = useState("")
  let [dataUserImport, setDataUserImport] = useState([])
  // let [importCount, setImportCount] = useState(0)
  let [loadingExportContact, setLoadingExportContact] = useState(false)
  let [customFieldData, setCustomFieldData] = useState([]);
  let [showTopic, setShowTopic] = useState(false);

  let listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      setDataUserImport([]);
      // getUserContactList(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getUserContactList = (orgID, onload, dataContact = {}) => {
    let _retriveDataError = { ...retriveDataError };
    setIsLoadingTableUser(true);

    let data = {}
    if (Object.keys(dataContact).length === 0) {
      data.pageDisplay = pageDisplay
      data.listPage = listPage
      data.submitQuery = submitQuery
    } else {
      data.pageDisplay = dataContact.pageDisplay
      data.listPage = dataContact.listPage
      data.submitQuery = dataContact.submitQuery
    }

    UserContactServices.getContactList(orgID, data.pageDisplay, data.listPage, data.submitQuery, (response) => {
      if (response.dataResult.status === 200) {
        setShowTopic(response.dataResult.data.showAssociatedTopics);
        setDataUserContact(response.dataResult.data.users)
        setTotalUsers(response.dataResult.data.totalUsers)
        setIsLoadingTableUser(false);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingTableUser(false);
      }
    });
  };

  let downloadReport = (data) => {
    // let id = orgID.split('-');
    // let timestamp = + new Date();
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `User_contacts.csv`);
    document.body.appendChild(link);
    link.click();
  }

  let exportContactsList = () => {
    setLoadingExportContact(true);

    UserContactServices.exportContacts(orgID, { format: "csv" }, (response) => {
      setLoadingExportContact(false);

      if (response.dataResult.error) {
        doToast(response.dataResult.error.message, "fail");
      } else {
        downloadReport(response.dataResult)
      }
    })
  }

  useEffect(() => {
    let paramID = checkID(match);
    if (props.history.location.state) {
      if (props.history.location.state.previousPage) {
        let dataState = props.history.location.state.previousPage

        if (dataState.pageDisplay && dataState.listPage && dataState.submitQuery !== undefined) {
          setPageDisplay(dataState.pageDisplay)
          setListPage(dataState.listPage)
          setListPageVal({
            label: `${dataState.listPage}`,
            value: dataState.listPage
          })
          setQuery(dataState.submitQuery)
          setSubmitQuery(dataState.submitQuery)
          let dataContact = {
            pageDisplay: dataState.pageDisplay,
            listPage: dataState.listPage,
            submitQuery: dataState.submitQuery
          }
          getUserContactList(paramID, false, dataContact);
        } else {
          getUserContactList(paramID, false);
        }
      }
      window.history.pushState(null, '');
    } else {
      getUserContactList(paramID, true);
    }

  }, [props.history])

  useEffect(() => {
    doMount();
  }, []);

  let closeModalAddContact = () => {
    setShowModalAddContact(false);
  }

  let closeModalImporting = () => {
    setShowModalImporting(false);
  }

  let addNewUserHandler = () => {
    history.push({
      pathname: `/o/${orgID}/operational/user-contact/add`,
      state: customFieldData
    });
  }

  let importContact = (dataUser) => {
    setShowModalImporting(true)
    setIsImporting(true);

    UserContactServices.importContact(orgID, dataUser, (response) => {
      if (response.dataResult.status === 200) {
        closeModalImporting();
        if (!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, 'fail');
        } else {
          closeModalAddContact()
          // window.location.reload();
          let paramID = checkID(match);
          getUserContactList(paramID, true);
          doToast(response.dataResult.data.message);
        }
      } else {
        setIsImporting(false);
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  }

  // let importContactHandler = () => {
  //   // let guessDelimiters = (text, possibleDelimiters) => {
  //   //   return possibleDelimiters.filter(weedOut);

  //   //   function weedOut (delimiter) {
  //   //       var cache = -1;
  //   //       return text.split('\n').every(checkLength);

  //   //       function checkLength (line) {
  //   //           if (!line) {
  //   //               return true;
  //   //           }

  //   //           var length = line.split(delimiter).length;
  //   //           if (cache < 0) {
  //   //               cache = length;
  //   //           }
  //   //           return cache === length && length > 1;
  //   //       }
  //   //   }
  //   // }

  //   let input = document.querySelector('.input-contact-csv')
  //   input.click()

  //   input.onchange = (e) => {
  //     if (input.files.length > 0) {

  //       // var reader = new FileReader();
  //       // reader.onload = function () {
  //       //   let data = reader.result.split('\n')
  //       //   let cleanData = data.map(item => item.replace(/"/g, ""))
  //       //   let rawDataUser = cleanData.map(item => item.split(guessDelimiters(item, [";", ","])[0]))

  //       //   let dataUser = rawDataUser.slice(1)
  //       //   setDataUserImport(dataUser)

  //       //   // call api importing contact
  //       //   importContact(dataUser)
  //       // };

  //       // // start reading the file. When it is done, calls the onload event defined above.
  //       // reader.readAsText(input.files[0]);
  //       //sampe sini

  //       convertFileToCsv(e.target.files[0], {
  //         onSuccessConvertCsv: (result) => {
  //           result.splice(0, 1);

  //           setDataUserImport(result);

  //           importContact(result);
  //         }
  //       })

  //       e.target.value = null;
  //     }
  //   }
  // }

  let renderModalImporting = () => {
    return (
      <Modal isOpen={showModalImporting} className={"importing-contact-modal"}>
        <ModalBody>
          <div className="importing-contact-wrapper">
            {
              isImporting ?
                <>
                  <div className="integration-list-loading-wrapper">
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                  <p className="importing-text">
                    Importing... Please make sure you have a stable connection.
                  </p>
                </>
                :
                <>
                  <div className="warning-svg">
                    <FiAlertTriangle />
                  </div>
                  <p className="importing-text">Failed to import CSV File.</p>

                  <ButtonWithLoadingOrIcon
                    text="Cancel"
                    onClickAction={() => {
                      setShowModalImporting(false);
                    }}
                    className="no-fill-button main-button-48"
                  />

                  <ButtonWithLoadingOrIcon
                    text="Retry"
                    icon={{
                      type: "svg",
                      src: FiRotateCcw
                    }}
                    onClickAction={() => importContact(dataUserImport)}
                    className="orange-button main-button-48"
                  />
                </>
            }
          </div>
        </ModalBody>
      </Modal>
    )
  }

  let renderModal = () => {
    return (
      <Modal isOpen={showModalAddContact} toggle={closeModalAddContact} className={"add-contact-modal"}>
        <ModalBody>
          <div className="add-contact-wrapper">
            <b className="add-contact-title">Add Contact</b>
            <p className="add-contact-description">There are 2 ways to add a contact.</p>

            <FiX onClick={closeModalAddContact} />

            <div className="add-contact-option-container">
              <div className="add-contact-new-user">
                <img src={iconNew} alt="" />
                <p>
                  <b>New Contact</b>
                </p>
                <p className="add-description">Create a new contact by inputting name and phone number.</p>
                <button className="orange-button add-new-contact-btn" onClick={addNewUserHandler}>
                  <img src={iconAdd} alt="" />
                  Add New Contact
                </button>
              </div>
              <div className="add-contact-import-contact">
                <img src={iconImport} alt="" />
                <p>
                  <b>Import Contacts</b>
                </p>
                <p className="add-description">Import contacts using CSV file.</p>
                <div className="button-import-container">
                  {/* <a href={userTemplate} target="_blank" rel="noopener noreferrer" download="user-contact-template.csv">
                    <button className="no-fill-button no-border">
                      <FiDownload />
                      Download Template
                    </button>
                  </a> */}
                  <button
                    className="orange-button upload-csv-btn"
                    onClick={() => {
                      props.history.push(`/o/${props.match.params.orgID}/operational/import-contacts`)
                    }}
                  >
                    <img src={iconUpload} alt="" />
                    Go to Import
                  </button>
                  <input type="file" className="input-contact-csv" style={{ display: 'none' }} />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  let handleChangePage = (page) => {
    let data = {
      pageDisplay: page,
      listPage: listPage,
      submitQuery: submitQuery
    }
    let paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setPageDisplay(page)
  }

  // let searchFocus = () => {
  //   setIsSearchFocus(true);
  // }

  // let searchBlur = () => {
  //   setIsSearchFocus(false);
  // }

  let onSelectListPage = (val) => {
    let data = {
      pageDisplay: 1,
      listPage: val.value,
      submitQuery: submitQuery
    }
    let paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setListPageVal(val)
    setListPage(val.value)
    setPageDisplay(1)
  }

  let toggleModalSaveChanges = () => {
    setIsShowModalSaveChanges(!isShowModalSaveChanges)
  }

  let saveChanges = () => {
    // console.log('save changes')
  }

  let toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  let discardProgress = () => {
    // console.log('discard progress')
  }

  let source = (src) => {
    switch (src) {
      case 'gb_profile':
        return {
          text: 'Google Business Profile',
          icon: iconGoogleProfile
        }
      case 'google_business':
        return {
          text: 'Google Business Messages',
          icon: iconGoogle
        }
      case 'customer':
        return {
          text: 'Customer',
          icon: iconUser
        }
      case 'facebook': {
        return {
          text: 'Facebook Messenger',
          icon: iconFacebook
        }
      }
      case 'launcher': {
        return {
          text: 'Live Chat',
          icon: iconLauncher
        }
      }
      case 'line': {
        return {
          text: 'LINE',
          icon: iconLine
        }
      }
      case 'telegram': {
        return {
          text: 'Telegram',
          icon: iconTelegram
        }
      }
      case 'twitter': {
        return {
          text: 'Twitter DM',
          icon: iconTwitter
        }
      }
      case 'instagram': {
        return {
          text: 'Instagram DM',
          icon: iconInstagram
        }
      }
      case 'whatsapp': {
        return {
          text: 'WhatsApp SME',
          icon: iconWhatsapp
        }
      }
      case 'whatsappba': {
        return {
          text: 'WhatsApp Business API',
          icon: iconWhatsappBa
        }
      }
      case 'manual': {
        return {
          text: 'User Added',
          icon: iconUser
        }
      }
      case 'manual_import': {
        return {
          text: 'Manual Import',
          icon: iconUser
        }
      }
      case 'broadcast': {
        return {
          text: 'Broadcast',
          icon: iconBroadcast
        }
      }
      case 'integration_api': {
        return {
          text: 'Integration API',
          icon: iconUser
        }
      }
      case 'integrationAPI': {
        return {
          text: 'Integration API',
          icon: iconUser
        }
      }
      case 'inbox_new_chat': {
        return {
          text: 'Inbox - New Chat',
          icon: iconUser
        }
      }
      case 'tokopedia': {
        return {
          text: 'Tokopedia',
          icon: iconTokopedia
        }
      }
      default: {
        return {
          text: 'User Added',
          icon: iconUser
        }
      }
    }
  }

  let onChangeSearch = (e) => {
    setQuery(e)
  }

  let onSubmitSearch = (e) => {
    let data = {
      pageDisplay: 1,
      listPage: listPage,
      submitQuery: query
    }
    let paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setPageDisplay(1)
    setSubmitQuery(query)
  }

  let goToDetailContact = (id) => {
    if (!loadingExportContact) {
      history.push({
        pathname: `/o/${orgID}/operational/user-contact/${id}`,
        state: {
          pageDisplay,
          listPage,
          submitQuery
        }
      })
    }
  }

  let onClearSearch = () => {
    let data = {
      pageDisplay: 1,
      listPage: listPage,
      submitQuery: ""
    }
    let paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setPageDisplay(1)
    setSubmitQuery("");
  }

  //
  // let toggleMiiTel = () => {
  //   if (window.miitelWidget.openState) {
  //     closeMiiTel();
  //   } else {
  //     openMiiTel();
  //   }
  // }

  let openMiiTel = () => {
    let currentStyle = document.querySelector("#miitelPhoneIFrameOuter").style.cssText;

    document.querySelector("#miitelPhoneIFrameOuter").style.cssText = currentStyle + "display: block ! important";
    setTimeout(() => {
      window.miitelWidget.openFrame();
    }, 1);
  }

  let closeMiiTel = () => {
    window.miitelWidget.closeFrame();
    setTimeout(() => {
      let currentStyle = document.querySelector("#miitelPhoneIFrameOuter").style.cssText;

      document.querySelector("#miitelPhoneIFrameOuter").style.cssText = currentStyle + "display: none ! important";
    }, 100);
  }
  //

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="sectionWrap add-contact-main-wrapper">
        {renderModal()}
        {renderModalImporting()}

        <>
          <div className="topContent add-contact-header">
            <div className="title">Contacts <span className="grey-font">({totalUsers})</span></div>

            {(!isLoadingTableUser && !retriveDataError.code) &&
              <div className="topContent-button-wrapper">
                {!loadingExportContact ?
                  <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40"
                    text="Download All"
                    position="left"
                    onClickAction={exportContactsList}
                    icon={{
                      type: "svg",
                      src: FiDownload
                    }}
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40"
                    text="Download All"
                    isLoading
                    isDisabled
                    loadingColor={"gray"}
                    position="left"
                  />
                }

                <ButtonWithLoadingOrIcon
                  className="orange-button main-button-40"
                  text="Add Contact"
                  position="left"
                  onClickAction={() => {
                    if (((props.match.params.orgID.split("-")[0] === "5369" || props.match.params.orgID.split("-")[0] === "6919") && findMyRole(props.membershipData.memberships, props.match.params.orgID) !== "owner")) {
                      history.push({
                        pathname: `/o/${orgID}/operational/user-contact/add`,
                        state: customFieldData
                      });
                    } else {
                      setShowModalAddContact(true);

                      ContactCustomFieldServices.getContactCustomFieldList(orgID, (response) => {
                        let dataResult = response.dataResult;
                        if (dataResult.error.message === '') {
                          setCustomFieldData(dataResult.data.customFields);
                        }
                      });
                    }

                  }}
                  icon={{
                    type: "svg",
                    src: FiPlus
                  }}
                />
              </div>
            }
          </div>

          {
            (isLoadingTableUser || retriveDataError.code) ?
              <RetrieveDataLoading
                isLoading={isLoadingTableUser}
                errorMessage={retriveDataError.message}
              />
              :
              <React.Fragment>
                {/* <form className={`add-contact-search ${isSearchFocus ? 'focus-search' : ''} ${loadingExportContact ? "grey-box" : ""}`} onFocus={searchFocus} onBlur={searchBlur} onSubmit={onSubmitSearch}>
                  <input type="text" disabled={loadingExportContact} placeholder="Search for a contact" value={query} onChange={onChangeSearch} />
                  <span onClick={onSubmitSearch}>
                    <FiSearch />
                  </span>
                </form> */}
                <SearchBox
                  onSubmitSearch={onSubmitSearch}
                  onChangeInputSearch={onChangeSearch}
                  searchValue={query}
                  placeholder="Search for a contact"
                  isWaitResponse={isLoadingTableUser}
                  onClearSearch={onClearSearch}
                />

                <div className="add-contact-list-table-wrapper">
                  <div className="custom-table">
                    <table className="custom-table-content table-user-contact-list">
                      <thead>
                        <tr>
                          <td className="col_name">Full Name</td>
                          <td className="col_alias">Alias</td>
                          <td className="col_email">Email</td>
                          <td className="col_phone">Phone Number</td>
                          <td className="col_origin">Origin</td>
                          <td className="col_created">Created At</td>

                          {showTopic &&
                            <td className="col_topic">Associated Topics</td>
                          }
                          <td className="col_view">
                            <CustomSelectOption
                              optionListProps={listPageOption}
                              valueDropdownProps={listPageVal}
                              onClickDropDownListOptionProps={onSelectListPage}
                              isDisabled={loadingExportContact}
                            />
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          dataUserContact.length > 0 ?

                            dataUserContact.map(item => {
                              return (
                                <tr key={`user-contact-${item.userID}`}>
                                  <td className="col_name">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user" > */}
                                    <p className="">{item.fullName}</p>
                                    {/* <p className="col_name_alias">{item.alias ? item.alias : <span className="unavailable">-</span>}</p> */}
                                    {/* </NavLink> */}
                                  </td>
                                  <td className="col_alias">
                                    <p className="">{item.alias === "" ? "-" : item.alias}</p>
                                  </td>
                                  <td className="col_email">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user"  > */}
                                    <p>{item.email ? item.email : <span className="unavailable">-</span>}</p>
                                    {/* </NavLink> */}
                                  </td>
                                  <td className="col_phone">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user"  > */}
                                    {item.maskedPhone ?
                                      (item.maskedPhone ? "+" + HelperInput.phoneBeautify(item.maskedPhone) : <span className="unavailable">-</span>)
                                      :
                                      (item.phone ? "+" + HelperInput.phoneBeautify(item.phone) : <span className="unavailable">-</span>)
                                    }
                                    {/* </NavLink> */}
                                  </td>
                                  <td className="col-origin">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user col-origin"  > */}

                                    <p>
                                      <img src={source(item.source).icon} alt="" />
                                      {source(item.source).text}
                                    </p>
                                    {/* </NavLink> */}
                                  </td>
                                  {/* <td className="">{getCreatedTime(item.createdTime)}</td> */}
                                  <td className="col_created">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user"> */}
                                    {HelperDate.formatToString(new Date(item.createdTime), 'd MMM yyyy')}
                                    {/* </NavLink> */}
                                  </td>
                                  {showTopic &&
                                    <td className="col_topic">
                                      {item.associatedTopicNames.length === 0 ?
                                        "-"
                                        :
                                        (item.associatedTopicNames.map((v, i) => {
                                          return (
                                            <div className="topic-list-content" key={`ass-topic-${i}`}>
                                              <b>{v}</b>
                                            </div>
                                          )
                                        }))
                                      }
                                    </td>
                                  }
                                  <td className="col_view">
                                    <div className="view-contact" onClick={() => goToDetailContact(item.userID)}>
                                      <FiEye />
                                      <b>View</b>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                            :
                            <tr>
                              <td colSpan={showTopic ? "8" : "7"} className="no-data-column"><b className="no-result-found">No Contacts found. Start by clicking “+Add Contact”.</b></td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-wrapper">
                    {
                      dataUserContact.length > 0 &&
                      <Pagination
                        total={totalUsers}
                        page={pageDisplay}
                        listPerPage={listPage}
                        step={1}
                        onChangePage={handleChangePage}
                      ></Pagination>
                    }
                  </div>
                </div>
              </React.Fragment>
          }
        </>

        <PopupRemove
          removeTitle={`This Contact Has Open Cases from WhatsApp`}
          removeDescription={`To save changes to this contact’s phone number, you must resolve all ongoing cases from WhatsApp.`}
          onClickCancel={toggleModalSaveChanges}
          onClickRemove={saveChanges}
          // waitForResponse={waitForResponseSaveChanges}
          showModalProps={isShowModalSaveChanges}
          submitText="Resolve and Save Changes"
        // colorButton="red-button"
        />
        <PopupRemove
          removeTitle={`Discard Progress?`}
          removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
          onClickCancel={toggleModalDiscard}
          onClickRemove={discardProgress}
          // waitForResponse={waitForResponseDiscard}
          showModalProps={isShowModalDiscard}
          submitText="Resolve and Save Changes"
        // colorButton="red-button"
        />
      </div>
    </SectionWrap >
  );
};

const mapStateToProps = state => ({
  membershipData: state.membershipData
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(UserContact)
