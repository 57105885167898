import React from 'react';
import './ClockTutorial.scss';
import { NavLink } from "react-router-dom";
// import ClockTutorialImg from '../../../assets/img/chatroom/clock-tutor.png';
// import OnetalkLogo from '../../../assets/img/onetalk-by-taptalkio.svg';
import { FiExternalLink, FiArrowRight } from "react-icons/fi";
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

var ClockTutorial = ({match, agentDetailProps, currentOrganizationProps, startClockInActionProps}) => {   
    return (
        <div className="clock-tutorial-container">
            {/* <img src={OnetalkLogo} alt="" className="main-logo" /> */}
            <br />

            <div className="clock-tutorial-agent-company">
                <p className="clock-tutorial-agent">
                    <b>Hello, {agentDetailProps.accountName}</b>
                </p>

                <p className="clock-tutorial-company"><b>{currentOrganizationProps.name}</b></p>
            </div>
            
            <div className="clock-tutorial-step">
                <p className="clock-tutorial-you-offline">
                    <b>You are Offline</b>
                </p>

                <p><b>You won’t be able to view your inbox and respond to your customers.</b></p>
                <br />
                
                {/* <ul>
                    <li>
                        <div className="clock-tutorial-step-badge">
                            A
                        </div>
                        Click the button "Start" if you wish to serve your customers right away.
                    </li>

                    <li>
                        <div className="clock-tutorial-step-badge">
                            B
                        </div>

                        Click the dropdown icon next to the button if you wish to "Start as Away".
                    </li>
                </ul> */}
            </div>

            <div className="button-clock-tutorial-wrapper">
                <div className="main-button-clock-wrapper">
                    <ButtonWithLoadingOrIcon 
                        // isLoading={loadingStartAsAway}
                        // loadingWhite
                        position="left"
                        className={`button-start-away main-button-40 main-reuseable-button`}
                        text="Start as Away"
                        onClickAction={() => startClockInActionProps(true)}
                        // isDisabled={loadingGoOnline || loadingStartAsAway}
                    />

                    {/* {!loadingGoOnline ? */}
                        <ButtonWithLoadingOrIcon 
                            position="right"
                            icon={{
                                type: "svg",
                                src: FiArrowRight
                            }}
                            className={`button-go-online main-button-40 main-reuseable-button hover-dark-layer`}
                            text="Go Online"
                            onClickAction={() => startClockInActionProps(false)}
                        />
                        {/* :
                        <ButtonWithLoadingOrIcon 
                            position="left"
                            isLoading
                            loadingWhite
                            className={`button-go-online main-button-40 main-reuseable-button`}
                            text="Go Online"
                            isDisabled={true}
                        />
                    } */}
                </div>

                <ul>
                    <li>
                        <div className="clock-status online-status"></div>
                        {/* <div className="tutorial-start tutorial-button-wrapper">
                            <MdFiberManualRecord />
                            <b>START</b>
                        </div> */}
                        Click the button “Online” if you wish to serve your customers right away.
                    </li>

                    <li>
                        <div className="clock-status away-status"></div>
                        {/* <div className="tutorial-start-as-away tutorial-button-wrapper">
                            <MdFiberManualRecord />
                            <b>START AS AWAY</b>
                        </div> */}
                        Click the button “Start As Away” if you wish to view your inbox but not respond to customers right away.
                    </li>
                </ul>

                <br />

                <div className="clock-separator">
                    <p><b>OR</b></p>
                </div>

                <br />

                <NavLink to={`/o/${match.params.orgID}/home`} target="_blank">
                    <FiExternalLink />
                    <b>Open Dashboard</b>
                </NavLink>
            </div>

        </div>
    );
}

export default ClockTutorial;
