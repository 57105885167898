import React, { useState, useEffect } from "react";
import { Redirect, Route, NavLink } from "react-router-dom";
import HelperAuth from "../../../helper/HelperAuth";
import Sidemenu from "../sidemenu/Sidemenu";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import BlockingPageRole from "../../reuseableComponent/blockingPageRole/BlockingPageRole";
import TopBarBlocking from "../../reuseableComponent/topBarBlocking/TopBarBlocking";
import { setActiveOrgID } from "../../../redux/actions/reduxActionActiveOrgID";
import { setBillingWarning } from "../../../redux/actions/reduxActionBillingWarning";
import { setAllOrganizationDetail } from "../../../redux/actions/reduxActionAllOrganizationDetail";
import { setMediumData } from "../../../redux/actions/reduxActionMedium";
import { setAuthActive } from "../../../redux/actions/reduxActionAuth";
import { setBalanceData } from "../../../redux/actions/reduxActionBalance";
import { connect } from 'react-redux';
import ChatRed from '../../../assets/img/roomlist/icon-fail-circle.svg';
import { MdRefresh } from 'react-icons/md';
import { FiAlertCircle } from 'react-icons/fi';
import TierBlockingScreen from '../tierBlockingScreen/TierBlockingScreen';
import { Modal, ModalBody } from 'reactstrap';
import ServiceProfile from "../../../services/profile/ServiceProfile";
import ChatService from '../../../services/chat/caseServices';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { taptalk } from "@taptalk.io/web-sdk";

const ROLE_CODE = {
    owner: 'owner',
    member: 'member',
    admin: 'admin',
    all: 'all'
}

const BILLING_WARNING = {
    issueLastPayment : "issuePayment",
    freeTrialEnd : "freeTrialEnd",
    overdueInvoices : "overdueInvoices",
    nextPaymentIsDue : "nextPaymentIsDue"
}

const MustLogin = ({ component: Component, withoutTier, ...rest }) => {
    let [isFinishFethcingCurrentTierDetail, setIsFinishFethcingCurrentTierDetail] = useState(false);
    let [currentTierDetail, setCurrentTierDetail] = useState({
        code: "",
        id: 0,
        isActive: false,
        isFreeTrial: false,
        name: "",
        statusText: ""
    });
    let [currentMembership, setCurrentMembership] = useState(null);
    let [showModalSettingWorkspace, setShowModalSettingWorkspace] = useState(true);
    let [errorMessageTier, setErrorMessageTier] = useState(null);
    let [organizationError, setOrganizationError] = useState({
        code: 0,
        message: ""
    });
    let [billingWarningBlock, setBillingWarningBlock] = useState(false);
    let [isOrganizationNotFound, setIsOrganizationNotFound] = useState(false);
    let history = useHistory();

    useEffect(() => {
        if(rest.mediumLastRun) {
            runGetMedium();
        }
    }, [rest.mediumLastRun])

    useEffect(() => {
        if(billingWarningBlock) {
            rest.setBillingWarning(true);
        }else {
            rest.setBillingWarning(false);
        }
    }, [billingWarningBlock])
    
    useEffect(() => {
        if(isFinishFethcingCurrentTierDetail) {
            setShowModalSettingWorkspace(false);
        }
    }, [isFinishFethcingCurrentTierDetail]);

    useEffect(() => {
        if(organizationError.code !== 0) {
            setIsFinishFethcingCurrentTierDetail(true);
        }
    }, [organizationError])

    useEffect(() => {
        if(currentMembership !== null) {
            setIsFinishFethcingCurrentTierDetail(true);
        }
    }, [currentMembership])

    useEffect(() => {
        if(HelperAuth.userWasLoggedIn() && rest.computedMatch.params.orgID) {
            runProjectConfig(() => {
                let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                // runGetProfile();
    
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                    mixpanel.init(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY);
                    mixpanel.identify(myAgentData.account.id);
                    mixpanel.people.set({
                        email: myAgentData.account.email,
                        name : myAgentData.account.fullName,
                        userID : myAgentData.account.id,
                        organizationID : rest.computedMatch.params.orgID.split("-")[0]
                    });
                }
            });

            rest.setActiveOrgID(rest.computedMatch.params.orgID);
            runGetOrganizationDetail(rest.computedMatch.params.orgID);
        }else {
            // runGetOganization();
            runGetProfile();
        }
    }, [rest.computedMatch.params.orgID]);

    let runProjectConfig = (callback) => {
        ChatService.postProjectConfig(rest.computedMatch.params.orgID, (response) => {
          let data = response.dataResult.data.tapTalk;
    
          callback();
          
          if (response.dataResult.error.code === "") {
            taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
          } else {
            if(response.dataResult.error.code === "49203") {
                setIsOrganizationNotFound(true);
            }
            console.log(response.dataResult.error.message);
          }
        });
    }

    let findMemberRole = () => {
        let organizationID =  rest.computedMatch.params.orgID.split("-")[0];
        let _user = {...HelperCustomEncryptor.doDecrypt(rest.user.data)};
        
        let findOrganization = () => {
            let _findIndex = _user.memberships.findIndex(value => value.organization.id === parseInt(organizationID));
        
            return _findIndex;
        }
    
        if(_user.memberships.length === 0) {
            return ROLE_CODE.all;
        }else {
            return findOrganization() !== -1 ? _user.memberships[findOrganization()].roleCode : ROLE_CODE.all
        }
    }

    let runGetProfile = () => {
        ServiceProfile.postGetProfile((response) => {
          if (response.dataResult.error.message === "") {
            let resultData = response.dataResult.data;
            
            let _user = {...HelperCustomEncryptor.doDecrypt(rest.user.data)};
            _user.account = resultData.account;
            _user.memberships = resultData.memberships;
            _user.canCreateOrganization = resultData.canCreateOrganization;

            runGetOganization(_user);
    
            rest.setAuthActive({
              data: HelperCustomEncryptor.doEncrypt(_user)
            });
          } else {
            console.log(response.dataResult.error.message);
          }
        });
    }

    let runGetOrganizationDetail = (orgID) => {
        ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {  
          if(response.dataResult.status === 200) {
            setTimeout(() => {
                rest.setAllOrganizationDetail(response.dataResult.data);
                setCurrentMembership(response.dataResult.data.membership);
                setCurrentTierDetail(response.dataResult.data.selectedTier);
            }, 2000)
          }else {
            let data = {
                code: response.dataResult.error.code,
                message: response.dataResult.error.message
            }

            setTimeout(() => {
                setOrganizationError(data);
            }, 2000);
          }
        })
    }

    let runGetOganization = (profile) => {
        ServiceOrganization.postGetOrganization((response) => {
            if(response.dataResult.error.message === "") {
                let responseData = response.dataResult.data;

                if(responseData.memberships.length < 1) {
                    window.location.href = "/organization/new";
                }else {
                    let getMemberShip = (id) => {
                        let _findIndex = profile.memberships.findIndex((v) => v.organization.id === id);
                        return _findIndex === -1 ? false : profile.memberships[_findIndex];
                    }

                    if(profile.account.defaultOrganizationID === 0) {
                        history.push(`/o/${responseData.memberships[0].organization.id}-${responseData.memberships[0].organization.createdTime}/${responseData.memberships[0].roleCode === "member" ? "inbox" : "home"}`);
                    }else {
                        if(!isOrganizationNotFound) {
                            let _data = getMemberShip(profile.account.defaultOrganizationID);
                            history.push(`/o/${_data.organization.id}-${_data.organization.createdTime}/${_data.organization.roleCode === "member" ? "inbox" : "home"}`);
                        }
                    }
                }
                    
            }else {
                setErrorMessageTier(response.dataResult.error.message);
            }
        })
    }

    let runGetMedium = () => {
        ServiceOrganization.postGetMedium(rest.computedMatch.params.orgID, (response) => {
            if(response.dataResult.error.message === "") {
                let responseData = response.dataResult.data;
                rest.setMediumData(responseData)
                
            }else {
                console.log(setErrorMessageTier(response.dataResult.error.message));
            }
        })
    }

    let runGetBalance = () => {
        ServiceOrganization.postGetBalance(rest.computedMatch.params.orgID, (response) => {
            if(response.dataResult.error.message === "") {
                let responseData = response.dataResult.data.balances;
                rest.setBalanceData(responseData)
                
            }else {
                setErrorMessageTier(response.dataResult.error.message);
            }
        })
    }

    let renderSetupWorkspace = () => {
        let setupOnProgressModal = () => {
          return (
              <div className="setup-main-content">
                  <div className="setup-image-content">
                      <div className="lds-ring">
                          <div />
                          <div />
                          <div />
                          <div />
                      </div>
      
                      {/* <img src={ChatGrey} alt="" /> */}
                  </div>
                  <br />
                  <b>
                      Setting up your workspace
                  </b>
                  {/* <p>
                      Make sure you have a stable connection
                  </p> */}
              </div>
          )
        }
    
        let setupFailModal = () => {
          let tryAgain = () => {
            window.location.href = "/";
          }

          return (
              <div className="setup-main-content">
                  <div className="setup-image-content setup-fail">
                      <img src={ChatRed} alt="" />
                  </div>
                  <br />
                  <b className="setup-fail">
                    {errorMessageTier}
                  </b>
                  <p className="retry-setup" onClick={tryAgain}>
                    <b><MdRefresh />Try Again</b>
                  </p>
              </div>
          )
        }
    
        return (
          <Modal isOpen={showModalSettingWorkspace} className="setup-room-list-modal">
            <ModalBody>
                {errorMessageTier !== null ?
                  setupFailModal()
                  :
                  setupOnProgressModal()
                }
            </ModalBody>
          </Modal>
        )
    }

    let issueLastPayment = () => {
        return (
            <div>
                <FiAlertCircle />
                There was an issue with your last payment. <NavLink to={`/o/${rest.computedMatch.params.orgID}/organization/billing/unpaid-invoices`}><b> Go to Unpaid Invoices</b></NavLink>
            </div>
        )
    }

    let freeTrialEnd = () => {
        let freeTrialDay = Math.ceil((rest.allOrganizationDetail.selectedTier.endTime - new Date().valueOf()) / 86400000);

        if(freeTrialDay < 0) {
            freeTrialDay = 0;
        }

        return (
            <div>
                <FiAlertCircle />
                Your free trial ends in {freeTrialDay} day{freeTrialDay > 1 ? "s" : ""}
            </div>
        )
    }

    let overdueInvoices = () => {
        let overdue = rest.allOrganizationDetail.unpaidInvoices.countOverdue;

        return (
            <div>
                <FiAlertCircle />
                You have {overdue} overdue invoice{overdue > 1 ? "s" : ""}. <NavLink to={`/o/${rest.computedMatch.params.orgID}/organization/billing/unpaid-invoices`}><b>Go to Unpaid Invoices</b></NavLink>
            </div>
        )
    }

    let nextPaymentIsDue = () => {
        let nextPaymentDay = Math.ceil((rest.allOrganizationDetail.unpaidInvoices.minDueTime - new Date().valueOf()) / 86400000);

        if(nextPaymentDay < 0) {
            nextPaymentDay = 0;
        }

        return (
            <div>
                <FiAlertCircle />
                Your next payment is due in {nextPaymentDay} day{nextPaymentDay > 1 ? "s" : ""}. <NavLink to={`/o/${rest.computedMatch.params.orgID}/organization/billing/unpaid-invoices`}><b> Go to Unpaid Invoice{nextPaymentDay > 1 ? "s" : ""}</b></NavLink>
            </div>
        )
    }

    useEffect(() => {
        if(rest.allOrganizationDetail) {
            handleAllOrganizationDetail();
            runGetMedium();
            runGetBalance();
        }
    }, [rest.allOrganizationDetail])

    let handleAllOrganizationDetail = () => {
        // let _myAccount = HelperCustomEncryptor.doDecrypt(rest.user.data);
        let orgDetail = {...rest.allOrganizationDetail};
        let _billingWarning = false;

        // let findOrganization = () => {
        //     let _findIndex = _myAccount.memberships.findIndex(value => value.organization.id === parseInt(rest.computedMatch.params.orgID.split("-")[0]));
      
        //     return _findIndex;
        // }

        // let isAgent = () => {
        //     return _myAccount.memberships[findOrganization()].roleCode === "member";
        // }

        // if(orgDetail.selectedTier.isActive) {
            if(orgDetail.paymentSettings.isAutomaticPayment) {
                if(orgDetail.unpaidInvoices.countFailed > 0) { // Has Unpaid Invoice or Failed Payment?
                    _billingWarning = BILLING_WARNING.issueLastPayment;
                }
            }else { // orgDetail.paymentSetting.isAutomaticPayment -> manual
                if(orgDetail.unpaidInvoices.countAll > 0) { // Has Unpaid Invoice or Failed Payment?
                    if(orgDetail.unpaidInvoices.countOverdue > 0) { //Is Overdue Invoice?
                        _billingWarning = BILLING_WARNING.overdueInvoices;
                    }else {
                        _billingWarning = BILLING_WARNING.nextPaymentIsDue;
                    }
                }else {
                    if(orgDetail.selectedTier.isFreeTrial) { //check freetrial
                        _billingWarning = BILLING_WARNING.freeTrialEnd;
                    }
                }
            }
        // }
        setBillingWarningBlock(_billingWarning ? _billingWarning : false);
    }

    return (
        <Route 
            {...rest}
            render={props => 
                !HelperAuth.userWasLoggedIn() ?
                    (
                        <Redirect to="/" />               
                    ) 
                    : 
                    (   
                        !isFinishFethcingCurrentTierDetail && organizationError.code === 0 ?
                            renderSetupWorkspace()
                            :
                            <React.Fragment>
                                {isFinishFethcingCurrentTierDetail &&
                                    <React.Fragment>
                                        {(rest.billingWarning && process.env.REACT_APP_HIDE_BILLING !== "true") &&
                                            <TopBarBlocking>
                                                {/* {overdueInvoices()} */}
                                                {billingWarningBlock === BILLING_WARNING.freeTrialEnd &&
                                                    freeTrialEnd()
                                                }

                                                {billingWarningBlock === BILLING_WARNING.issueLastPayment &&
                                                    issueLastPayment()
                                                }

                                                {billingWarningBlock === BILLING_WARNING.nextPaymentIsDue &&
                                                    nextPaymentIsDue()
                                                }

                                                {billingWarningBlock === BILLING_WARNING.overdueInvoices &&
                                                    overdueInvoices()
                                                }
                                            </TopBarBlocking>
                                        }

                                        <Sidemenu {...props} currentTierDetailProps={currentTierDetail} />
                                                
                                        {organizationError.code !== 0 ?
                                            <TierBlockingScreen 
                                                {...props}
                                                currentTierDetailProps={currentTierDetail}
                                                currentMembershipProps={currentMembership} 
                                                organizationErrorProps={organizationError}
                                            />
                                            :
                                            (
                                                rest.allOrganizationDetail.selectedTier.id === 0 ||
                                                rest.allOrganizationDetail.selectedTier.isSuspended ||
                                                !rest.allOrganizationDetail.selectedTier.isActive
                                            ) && !withoutTier ?
                                                <TierBlockingScreen 
                                                    {...props} 
                                                    currentTierDetailProps={currentTierDetail}
                                                    currentMembershipProps={currentMembership} 
                                                    organizationErrorProps={organizationError}
                                                />
                                                : 
                                                rest.restrictedForAgent ?
                                                    findMemberRole() === ROLE_CODE.member ?
                                                        <BlockingPageRole {...props} />
                                                        :
                                                        <Component 
                                                            {...props} 
                                                            currentTierDetailProps={currentTierDetail} 
                                                            memberRole={findMemberRole()}
                                                        />
                                                    :
                                                    <Component 
                                                        {...props} 
                                                        currentTierDetailProps={currentTierDetail}
                                                        memberRole={findMemberRole()} 
                                                    />
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                    )
            }
        />
    )
};

const mapStateToProps = state => ({
    user: state.user,
    billingWarning: state.billingWarning,
    allOrganizationDetail: state.allOrganizationDetail,
    mediumData: state.mediumData,
    mediumLastRun: state.mediumLastRun
});

const mapDispatchToProps = {
    setActiveOrgID,
    setBillingWarning,
    setAllOrganizationDetail,
    setMediumData,
    setAuthActive,
    setBalanceData
}
  
export default connect(mapStateToProps, mapDispatchToProps)(MustLogin);