import React from "react";
import "./DragIcon.scss";

let DragIcon = (props) => {
    return (
        <div className={`main-drag-icon ${props._className}`}>
        </div>
    )
}

export default DragIcon;